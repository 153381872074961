import React from "react";
import Recapture from "./Recapture";
import Icons from "../../Assets/img/arrow-down.png";

function ContactUs() {
	return (
		<>
			<section className="contact">
				<div className="container">
					<div className="row">
						{/* <div className="col-md-1"></div> */}
						<div className="col-md-12">
							<div className="Contact_us_head">
								<h4>CONTACT US</h4>
							</div>
							{/* contact us form */}
							<form>
								<div className="form-group">
									{/* I want to buy */}
									<div className="select_box11">
										<select
											className="form-control first_select"
											id="exampleFormControlSelect1"
										>
											<option className="first_select">I want To Buy</option>
											<option className="first_select">Option 1</option>
											<option className="first_select">Option 2</option>
											<option className="first_select">Option 3</option>
											<option className="first_select">Option 4</option>
										</select>
									</div>

									<div className="first_contant_contact">
										<input
											type="text"
											className="form-control  first_select"
											id="exampleInputEmail1"
											aria-describedby="emailHelp"
											placeholder="First Name"
										/>

										<input
											type="text"
											className="form-control  first_select"
											id="exampleInputEmail1"
											aria-describedby="emailHelp"
											placeholder="Last Name"
										/>
									</div>
									<div className="second_contant_contact">
										<input
											type="text"
											className="form-control  first_select"
											id="exampleInputEmail1"
											aria-describedby="emailHelp"
											placeholder="Email"
										/>

										<input
											type="text"
											className="form-control  first_select"
											id="exampleInputEmail1"
											aria-describedby="emailHelp"
											placeholder="Phone"
										/>
									</div>
									{/* How We Can We Help You */}
									{/* textArea */}
									<div className="textArea_contact">
										<textarea
											className="form-control  first_select"
											id="exampleFormControlTextarea1"
											rows="3"
											placeholder="How We Can We Help You"
										></textarea>
									</div>

									{/* I'm Not A Robot */}
									<div className="not_robot_contact">
										<Recapture />
									</div>
									<button className="w-100 btn mt-3">Submit</button>
								</div>
							</form>
						</div>
						{/* <div className="col-md-1"></div> */}
					</div>
				</div>
			</section>
		</>
	);
}

export default ContactUs;
