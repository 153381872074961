import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
	CardElement,
	Elements,
	useStripe,
	useElements,
} from "@stripe/react-stripe-js";
import { useNavigate } from "react-router-dom";

const stripePromise = loadStripe(
	"pk_test_51LGMGbHy66FgldI6FGUpwfMM9JNtpJF5UY5c59PyyA6UwUylL0V1utTfVeX4RopkrIrjDS4FxgZDis42pgSShsL0002KpasdW5",
);

const CheckoutForm = () => {
	const navigate = useNavigate();
	const stripe = useStripe();
	const elements = useElements();
	const [paybutton, setPayButton] = useState(true);

	const handleSubmit = async (e) => {
		e.preventDefault();
		navigate("/thankyou");
	};

	return (
		<>
			<form>
				<CardElement
					onChange={(e) => {
						if (e.complete) {
							setPayButton(false);
						} else {
							setPayButton(true);
						}
					}}
				/>
				<br />

				<ul className="list-inline button-group">
					<li className="button-group">
						<button
							type="submit"
							style={{
								width: "100%",
								padding: "12px 80px",
								cursor: "pointer",
								background: "#43f22c",
								color: "#fff",
								fontSize: "18px",
								textTransform: "uppercase",
								fontWeight: "600",
								border: 0,
								transition: "0.6s",
								border: "2px solid #fff",
							}}
							className="btn form-control"
							disabled={!stripe || !elements || paybutton}
							onClick={handleSubmit}
						>
							Pay Now
						</button>
					</li>
				</ul>
			</form>
		</>
	);
};

const StripeForm = () => {
	return (
		<Elements stripe={stripePromise}>
			<CheckoutForm />
		</Elements>
	);
};

export default StripeForm;
