import React from "react";
import banner from "../../Assets/img/banner.png";
import about1 from "../../Assets/img/about1.png";
import car1 from "../../Assets/img/car1.png";
import car2 from "../../Assets/img/car2.png";
import icon from "../../Assets/img/icon.png";
import service from "../../Assets/img/services1.png";
import services2 from "../../Assets/img/services2.png";
import services3 from "../../Assets/img/services3.png";
import services4 from "../../Assets/img/services4.png";
import feature1 from "../../Assets/img/feature1.png";
import meter from "../../Assets/img/meter.svg";
import meter1 from "../../Assets/img/meter1.svg";
import meter2 from "../../Assets/img/meter2.png";
import meter3 from "../../Assets/img/meter3.png";
import add from "../../Assets/img/add.png";
import feature2 from "../../Assets/img/feature2.png";
import feature3 from "../../Assets/img/feature3.png";
import feature4 from "../../Assets/img/feature4.png";
import feature5 from "../../Assets/img/feature5.png";
import feature6 from "../../Assets/img/feature6.png";
import TopSlider from "./TopSlider";
import { Form } from "react-bootstrap";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { Link, useNavigate } from "react-router-dom";
import HowItWorks from "../Home/HowItWorks";
import ContactUs from "../../Components/Contactus/ContactUs";
import { buyer1, buyer2, buyer3 } from "../../constant";

// Banner section

const Home = () => {
	const navigate = useNavigate();
	const HandlePageChange = () => {
		navigate("/cardetailpage");
	};
	const HandleSeeAll = () => {
		navigate("/listing");
	};
	const Handlesellyourcar = () => {
		navigate("/PostVehicleAds");
	};
	return (
		<>
			<Header />
			<TopSlider />

			{/* Car section  */}
			<section className="car-sec ">
				<div className="container">
					<div className="car-head">
						<h3>Trending</h3>
					</div>
					<div className="row">
						<div className="col-md-3">
							<div className="car-dv for-car1 wow animate__animated animate__flipInY">
								<img src={car1}></img>
							</div>
							<div className="tesla-model">
								<h5>Tesla Model 3</h5>
							</div>
						</div>
						<div className="col-md-3">
							<div className="car-dv for-car2 wow animate__animated animate__flipInY">
								<img src={car2}></img>
							</div>
							<div className="tesla-model">
								<h5>Tesla Model x</h5>
							</div>
						</div>
						<div className="col-md-3">
							<div className="car-dv for-car3 wow animate__animated animate__flipInY">
								<img src={car2}></img>
							</div>
							<div className="tesla-model">
								<h5>Tesla Model 3</h5>
							</div>
						</div>
						<div className="col-md-3">
							<div className="car-dv for-car4 wow animate__animated animate__flipInY">
								<img src={car1}></img>
							</div>
							<div className="tesla-model">
								<h5>Tesla Model 3</h5>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* End of Car section  */}

			

			{/* Featured section  */}
			<section className="feature-sec  ">
				<div className="container">
					<div className="car-head">
						<h3>Featured</h3>
					</div>
					<div className="row wow animate__animated animate__slideInLeft">
						<div className="col-md-3">
							<div className="featured-all">
								<div className="f-img">
									<img src={feature1}></img>
									{/* <div className="actions">
										<a href="#">
											<i class="fa fa-heart" aria-hidden="true"></i>
										</a>
										<a href="#">
											<i class="fa fa-eye-slash" aria-hidden="true"></i>
										</a>
									</div> */}
								</div>
								<div className="feature-head">
									<h2>
										<span>$</span>21,898
									</h2>
									{/* <p>
										<i class="fa fa-car" aria-hidden="true"></i> For price |
										$566 below market
									</p> */}
								</div>

								<div className="feature-normal-head">
									<h3>2017 CHEVROLET VOLT LT</h3>
								</div>

								<div className="feature-icon-head">
									<span>
										<img src={meter1}></img> 18,234 miles
									</span>
									<span>
										<img src={meter2}></img>267 Mi max range
									</span>
								</div>
								<div className="feature-iiimmgg">
									<span>
										<img src={meter3}></img> Longview, TX
									</span>
								</div>

								<div className="checkup">
									<button onClick={HandlePageChange} className="btn ">
										View more
									</button>
								</div>

								<div className="new-listing">
									<h5>New listing</h5>
								</div>
							</div>
						</div>

						<div className="col-md-3">
							<div className="featured-all">
								<div className="f-img">
									<img src={feature2}></img>
									{/* <div className="actions">
										<a href="#">
											<i class="fa fa-heart" aria-hidden="true"></i>
										</a>
										<a href="#">
											<i class="fa fa-eye-slash" aria-hidden="true"></i>
										</a>
									</div> */}
								</div>
								<div className="feature-head">
									<h2>
										<span>$</span>21,898
									</h2>
									{/* <p>
										<i class="fa fa-car" aria-hidden="true"></i> For price |
										$566 below market
									</p> */}
								</div>

								<div className="feature-normal-head">
									<h3>2017 CHEVROLET VOLT LT</h3>
								</div>

								<div className="feature-icon-head">
									<span>
										<img src={meter1}></img> 18,234 miles
									</span>
									<span>
										<img src={meter2}></img>267 Mi max range
									</span>
								</div>
								<div className="feature-iiimmgg">
									<span>
										<img src={meter3}></img> Longview, TX
									</span>
								</div>

								<div className="checkup">
									<button className="btn " onClick={HandlePageChange}>
										View more
									</button>
								</div>

								<div className="new-listing">
									<h5>New listing</h5>
								</div>
							</div>
						</div>

						<div className="col-md-3">
							<div className="featured-all">
								<div className="f-img">
									<img src={feature3}></img>
									{/* <div className="actions">
										<a href="#">
											<i class="fa fa-heart" aria-hidden="true"></i>
										</a>
										<a href="#">
											<i class="fa fa-eye-slash" aria-hidden="true"></i>
										</a>
									</div> */}
								</div>
								<div className="feature-head">
									<h2>
										<span>$</span>21,898
									</h2>
									{/* <p>
										<i class="fa fa-car" aria-hidden="true"></i> For price |
										$566 below market
									</p> */}
								</div>

								<div className="feature-normal-head">
									<h3>2017 CHEVROLET VOLT LT</h3>
								</div>

								<div className="feature-icon-head">
									<span>
										<img src={meter1}></img> 18,234 miles
									</span>
									<span>
										<img src={meter2}></img>267 Mi max range
									</span>
								</div>
								<div className="feature-iiimmgg">
									<span>
										<img src={meter3}></img> Longview, TX
									</span>
								</div>

								<div className="checkup">
									<button className="btn " onClick={HandlePageChange}>
										View more
									</button>
								</div>

								<div className="new-listing">
									<h5>New listing</h5>
								</div>
							</div>
						</div>

						<div className="col-md-3">
							<div className="featured-all">
								<div className="f-img">
									<img src={feature1}></img>
									{/* <div className="actions">
										<a href="#">
											<i class="fa fa-heart" aria-hidden="true"></i>
										</a>
										<a href="#">
											<i class="fa fa-eye-slash" aria-hidden="true"></i>
										</a>
									</div> */}
								</div>
								<div className="feature-head">
									<h2>
										<span>$</span>21,898
									</h2>
									{/* <p>
										<i class="fa fa-car" aria-hidden="true"></i> For price |
										$566 below market
									</p> */}
								</div>

								<div className="feature-normal-head">
									<h3>2017 CHEVROLET VOLT LT</h3>
								</div>

								<div className="feature-icon-head">
									<span>
										<img src={meter1}></img> 18,234 miles
									</span>
									<span>
										<img src={meter2}></img> 267 Mi max range
									</span>
								</div>
								<div className="feature-iiimmgg">
									<span>
										<img src={meter3}></img> Longview, TX
									</span>
								</div>

								<div className="checkup">
									<button className="btn " onClick={HandlePageChange}>
										View more
									</button>
								</div>

								<div className="new-listing">
									<h5>New listing</h5>
								</div>
							</div>
						</div>
					</div>

					{/* Second row  */}

					<div className="row upper-padding wow animate__animated animate__slideInLeft">
						<div className="col-md-3">
							<div className="featured-all">
								<div className="f-img">
									<img src={feature1}></img>
									{/* <div className="actions">
										<a href="#">
											<i class="fa fa-heart" aria-hidden="true"></i>
										</a>
										<a href="#">
											<i class="fa fa-eye-slash" aria-hidden="true"></i>
										</a>
									</div> */}
								</div>
								<div className="feature-head">
									<h2>
										<span>$</span>21,898
									</h2>
									{/* <p>
										<i class="fa fa-car" aria-hidden="true"></i> For price |
										$566 below market
									</p> */}
								</div>

								<div className="feature-normal-head">
									<h3>2017 CHEVROLET VOLT LT</h3>
								</div>

								<div className="feature-icon-head">
									<span>
										<img src={meter1}></img> 18,234 miles
									</span>
									<span>
										<img src={meter2}></img>267 Mi max range
									</span>
								</div>
								<div className="feature-iiimmgg">
									<span>
										<img src={meter3}></img> Longview, TX
									</span>
								</div>

								<div className="checkup">
									<button className="btn " onClick={HandlePageChange}>
										View more
									</button>
								</div>

								<div className="new-listing">
									<h5>New listing</h5>
								</div>
							</div>
						</div>

						<div className="col-md-3">
							<div className="featured-all">
								<div className="f-img">
									<img src={feature5}></img>
									{/* <div className="actions">
										<a href="#">
											<i class="fa fa-heart" aria-hidden="true"></i>
										</a>
										<a href="#">
											<i class="fa fa-eye-slash" aria-hidden="true"></i>
										</a>
									</div> */}
								</div>
								<div className="feature-head">
									<h2>
										<span>$</span>21,898
									</h2>
									{/* <p>
										<i class="fa fa-car" aria-hidden="true"></i> For price |
										$566 below market
									</p> */}
								</div>

								<div className="feature-normal-head">
									<h3>2017 CHEVROLET VOLT LT</h3>
								</div>

								<div className="feature-icon-head">
									<span>
										<img src={meter1}></img> 18,234 miles
									</span>
									<span>
										<img src={meter2}></img> 267 Mi max range
									</span>
								</div>
								<div className="feature-iiimmgg">
									<span>
										<img src={meter3}></img> Longview, TX
									</span>
								</div>

								<div className="checkup">
									<button className="btn " onClick={HandlePageChange}>
										View more
									</button>
								</div>

								<div className="new-listing">
									<h5>New listing</h5>
								</div>
							</div>
						</div>

						{/* <div className="featured-all">
                <div className="f-img">
                  <img src={feature4}></img>
                </div>
                <div className="feature-head">
                <h2><span>$</span>21,898</h2>
                  <p>
                    <i class="fa fa-car" aria-hidden="true"></i> For price |
                    $566 below market
                  </p>
                </div>

                <div className="feature-normal-head">
                  <h3>2017 CHEVROLET VOLT LT</h3>
                </div>

                <div className="feature-icon-head">
                  <span>
                    <img src={meter1}></img> 18,234 miles
                  </span>
                  <span>
                    <img src={meter2}></img> No accident
                  </span>
                  
                </div>
                <div className="feature-iiimmgg">
                <span>
                    <img src={meter3}></img> Delivery available
                  </span>
                </div>

                <div className="checkup">
                  <button onClick={HandlePageChange}>View more</button>
                </div>

                <div className="new-listing">
                  <h5>New listing</h5>
                </div>
              </div> */}

						{/* <div className="feature-add-img">
								<img src={add}></img>
							</div> */}
						<div className="col-md-3">
							<div className="featured-all">
								<div className="f-img">
									<img src={feature6}></img>
									{/* <div className="actions">
										<a href="#">
											<i class="fa fa-heart" aria-hidden="true"></i>
										</a>
										<a href="#">
											<i class="fa fa-eye-slash" aria-hidden="true"></i>
										</a>
									</div> */}
								</div>
								<div className="feature-head">
									<h2>
										<span>$</span>21,898
									</h2>
									{/* <p>
										<i class="fa fa-car" aria-hidden="true"></i> For price |
										$566 below market
									</p> */}
								</div>

								<div className="feature-normal-head">
									<h3>2017 CHEVROLET VOLT LT</h3>
								</div>

								<div className="feature-icon-head">
									<span>
										<img src={meter1}></img> 18,234 miles
									</span>
									<span>
										<img src={meter2}></img>267 Mi max range
									</span>
								</div>
								<div className="feature-iiimmgg">
									<span>
										<img src={meter3}></img> Longview, TX
									</span>
								</div>

								<div className="checkup">
									<button className="btn " onClick={HandlePageChange}>
										View more
									</button>
								</div>

								<div className="new-listing">
									<h5>New listing</h5>
								</div>
							</div>
						</div>

						<div className="col-md-3">
							<div className="featured-all">
								<div className="f-img">
									<img src={feature6}></img>
									{/* <div className="actions">
										<a href="#">
											<i class="fa fa-heart" aria-hidden="true"></i>
										</a>
										<a href="#">
											<i class="fa fa-eye-slash" aria-hidden="true"></i>
										</a>
									</div> */}
								</div>
								<div className="feature-head">
									<h2>
										<span>$</span>21,898
									</h2>
									{/* <p>
										<i class="fa fa-car" aria-hidden="true"></i> For price |
										$566 below market
									</p> */}
								</div>

								<div className="feature-normal-head">
									<h3>2017 CHEVROLET VOLT LT</h3>
								</div>

								<div className="feature-icon-head">
									<span>
										<img src={meter1}></img> 18,234 miles
									</span>
									<span>
										<img src={meter2}></img>267 Mi max range
									</span>
								</div>
								<div className="feature-iiimmgg">
									<span>
										<img src={meter3}></img> Longview, TX
									</span>
								</div>

								<div className="checkup">
									<button className="btn " onClick={HandlePageChange}>
										View more
									</button>
								</div>

								<div className="new-listing">
									<h5>New listing</h5>
								</div>
							</div>
						</div>
					</div>

					<div className="see-all ">
						<button className="subBtn" onClick={HandleSeeAll}>
							See All <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
						</button>
					</div>
				</div>
			</section>
			{/* End of Featured section  */}


			{/* Buyer's Tools starts here */}
			<section className="BuyerTools">
				<div className="container">
					<div className="row">
						<div className="col-lg-12 pb-4">
							<div className="content-wrapper">
								<h4>Buyer's Tools</h4>
							</div>
						</div>
						<div className="col-lg-4">
							<Link to="/buyerdetail">
								<div className="buyer-card-wrapper">
									<div className="buyer-img-wrapper">
										<figure>
											<img src={buyer1} className="img-fluid" alt="" />
										</figure>
									</div>
									<div className="buyer-content-wrapper">
										<h5>Customize Your Ride</h5>
									</div>
								</div>
							</Link>
						</div>
						<div className="col-lg-4">
							<Link to="/buyerdetail">
								<div className="buyer-card-wrapper">
									<div className="buyer-img-wrapper">
										<figure>
											<img src={buyer2} className="img-fluid" alt="" />
										</figure>
									</div>
									<div className="buyer-content-wrapper">
										<h5>Shipping Quotes</h5>
									</div>
								</div>
							</Link>
						</div>
						<div className="col-lg-4">
							<Link to="/buyerdetail">
								<div className="buyer-card-wrapper">
									<div className="buyer-img-wrapper">
										<figure>
											<img src={buyer3} className="img-fluid" alt="" />
										</figure>
									</div>
									<div className="buyer-content-wrapper">
										<h5>Financing Options</h5>
									</div>
								</div>
							</Link>
						</div>
					</div>
				</div>
			</section>
			{/* Buyer's Tools ends here */}

			{/* Sell Now section  */}
			<section className="sell-sec ">
				<div className="container">
					<div className="row">
						<div className="col-md-6">
							<div className="sell-head wow animate__animated animate__lightSpeedInRight">
								<h3>Sell Your Car</h3>
								<h2>Now</h2>
								<p>
									Lorem ipsum dolor sit amet, consectetur <br /> adipiscing
									elit, sed do eiusmod tempor{" "}
								</p>

								<div className="checkup">
									<button className="btn sellbtn" onClick={Handlesellyourcar}>
										Sell Now
									</button>
								</div>
							</div>
						</div>
						<div className="col-md-6"></div>
					</div>
				</div>
			</section>
			{/* End of Sell Now section   */}

			<HowItWorks />
			<ContactUs />

			<Footer />
		</>
	);
};

export default Home;

// End of Banner section
