import React from "react";

import currency from "currency-formatter";
import feature2 from "../../Assets/img/sapairpart2.webp";

const OrderSummary = () => {
	return (
		<>
			<div className="second-top-bar">
				<div className="title pb-4">
					<h3>Order Summary</h3>
				</div>
				<label htmlFor="Enter Voucher Code : ">Enter Voucher Code :</label>
				<div className="form-group">
					<input type="text" className="form-control" value="dsdsds" />
					<button type="button">
						SUBMIT <img src="img/right-arrow.svg" alt="" />
					</button>
				</div>
			</div>
			<div className="cart-product-detail">
				<div className="title">
					<h3>Products Details</h3>
				</div>
				<div className="checkout-products">
					<div className="products br-right">
						<div className="abt-product">
							<div className="img-box">
								<figure>
									<img src={feature2} alt="123" />
								</figure>
							</div>
							<div className="pro-dtl">
								<h5 className="name">COUNTRYMAN</h5>
								<ul className="variations">
									<li>Items (1)</li>
								</ul>
							</div>
						</div>
						<div className="product-price">
							<h4 className="price">
								{
									currency
										.format(12, {
											code: "GBP",
										})
										.split(".00")[0]
								}
							</h4>
						</div>
					</div>
				</div>
				<div className="pricing-details">
					<div className="item-total">
						<h4 className="property">Item Total :</h4>
						<h4 className="value">
							{
								currency
									.format(12, {
										code: "GBP",
									})
									.split(".00")[0]
							}
						</h4>
					</div>
					<div className="item-total">
						<h4 className="property">Delivery charges</h4>
						<h4 className="value">
							{
								currency
									.format(3, {
										code: "GBP",
									})
									.split(".00")[0]
							}
						</h4>
					</div>

					<div className="item-total">
						<h4 className="property">Promo Code Applied :</h4>
						<h4 className="value">
							{
								currency
									.format(10, {
										code: "GBP",
									})
									.split(".00")[0]
							}
						</h4>
					</div>
				</div>

				<div className="order-total">
					<h3 className="property">Order Total</h3>
					<h3 className="value">
						{
							currency
								.format(12, {
									code: "GBP",
								})
								.split(".00")[0]
						}
					</h3>
				</div>
			</div>
		</>
	);
};

export default OrderSummary;
