import React from "react";
import Header from "../../Components/Header";
import Icons from "../../Assets/img/Iconsearch.png";
import "../../Assets/Css/Blogs.css";
import Footer from "../../Components/Footer";
import CardImage1 from "../../Assets/img/CardImage1.png";
import CardImage2 from "../../Assets/img/CardImage2.png";
import CardImage3 from "../../Assets/img/CardImage3.png";
import Slider from "react-slick";
import searchIcon from "../../Assets/img/search-btn.png";

function Blogs() {
	const BlogCarousel = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 1,
	};
	let Images = [
		CardImage1,
		CardImage2,
		CardImage3,
		CardImage1,
		CardImage2,
		CardImage3,
		CardImage1,
		CardImage2,
		CardImage3,
	];
	return (
		<>
			<Header />
			<section className="blog-banner blog-inner">
				<div className="container">
					<div className="row">
						<div className="col-sm-12">
							<div className="blog_banner_title">
								<h1>Blog And News</h1>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="pt-5 blog_sec">
				<div className="container">
					<div className="firstSection">
						<h2 className="headingH4">Our Blog</h2>
						<div className="search">
							<input type="text" placeholder="Search by date" />
							<button className="searchIcon">
								<img src={searchIcon} alt="icons" />
							</button>
						</div>
					</div>

					<div className="row">
						<Slider {...BlogCarousel}>
							{[1, 2, 3, 4, 5, 6, 7, 8, 9].map((item, index) => {
								return (
									<>
										<div className="card blog_carousel">
											<div className="img_box">
												<img
													src={Images[index]}
													className="card-img-top"
													alt="..."
												/>
												<div className="date">
													<p>July 15</p>
												</div>
											</div>
											<div className="card-body">
												<h5 className="card-title">
													Do millennials care about
												</h5>
												<p className="card-text">
													Some quick example text to build on the card title and
													make up the bulk of the card's content.
												</p>
												<a href="/Blog-details" className="btn btn-primary">
													Read More
												</a>
											</div>
										</div>
										{/* <div className="col-md-4">
											
										</div> */}
									</>
								);
							})}
						</Slider>
					</div>

					<div className="firstSection">
						<h2 className="headingH4">News</h2>
					</div>
					<div className="row">
						<Slider {...BlogCarousel}>
							{[1, 2, 3, 4, 5, 6, 7, 8, 9].map((item, index) => {
								return (
									<>
										<div className="card blog_carousel">
											<div className="img_box">
												<img
													src={Images[index]}
													className="card-img-top"
													alt="..."
												/>
												<div className="date">
													<p>July 15</p>
												</div>
											</div>
											<div className="card-body">
												<h5 className="card-title">
													Do millennials care about
												</h5>
												<p className="card-text">
													Some quick example text to build on the card title and
													make up the bulk of the card's content.
												</p>
												<a href="/newsdetail" className="btn btn-primary">
													Read More
												</a>
											</div>
										</div>
										{/* <div className="col-md-4">
											
										</div> */}
									</>
								);
							})}
						</Slider>
					</div>
					<div className="pagination">
						<div className="pageCount">
							<button className="btn3">1</button>
							<button className="btn1">2</button>
							<button className="btn1">3</button>
							<button className="btn1">4</button>
							<button className="btn1">5</button>
							<button className="btn1">10</button>
							<button className="btn2">
								Next <i class="fa fa-angle-right" aria-hidden="true"></i>
							</button>
							<i class="bi bi-chevron-right"></i>
						</div>
					</div>
				</div>
				<Footer />
			</section>
		</>
	);
}

export default Blogs;
