import React from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import States from "../../Assets/img/map.png";
import "../../Assets/Css/all-dealers.css";
import dealer1 from "../../Assets/img/dealer1.png";
import dealer2 from "../../Assets/img/dealer2.png";
import dealer3 from "../../Assets/img/dealer3.png";
import { Link } from "react-router-dom";

const AllDealer = () => {
	return (
		<>
			<Header />

			{/* Dealer Sec Start Here */}
			<section className="dealer_sec">
				<div className="container">
					<div className="heading">
						<h3>Dealer Listing</h3>
					</div>
					<div className="filterDv">
						<figure>
							<img src={States} className="img-fluid" />
						</figure>
						<div className="form-group">
							<select className="form-control">
								<option>Filter by States</option>
								<option>California</option>
								<option>Texas</option>
								<option>Florida</option>
							</select>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-4">
							<div className="dealer-box">
								<div className="img-box">
									<figure>
										<img className="img-fluid" src={dealer1} />
									</figure>
								</div>
								<div className="content">
									<h4>Shawn Micheal</h4>
									<p>
										10280 NW 9th Street Cir #APT 203 Miami, Florida(FL), 33172
									</p>
									<div className="button-group">
										<Link to="/showimg-list">Inventory</Link>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-4">
							<div className="dealer-box">
								<div className="img-box">
									<figure>
										<img className="img-fluid" src={dealer2} />
									</figure>
								</div>
								<div className="content">
									<h4>Johnny Lewis</h4>
									<p>
										10280 NW 9th Street Cir #APT 203 Miami, Florida(FL), 33172
									</p>
									<div className="button-group">
										<Link to="/showimg-list">Inventory</Link>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-4">
							<div className="dealer-box">
								<div className="img-box">
									<figure>
										<img className="img-fluid" src={dealer3} />
									</figure>
								</div>
								<div className="content">
									<h4>Laureen Wood</h4>
									<p>
										10280 NW 9th Street Cir #APT 203 Miami, Florida(FL), 33172
									</p>
									<div className="button-group">
										<Link to="/showimg-list">Inventory</Link>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-4">
							<div className="dealer-box">
								<div className="img-box">
									<figure>
										<img className="img-fluid" src={dealer3} />
									</figure>
								</div>
								<div className="content">
									<h4>Laureen Wood</h4>
									<p>
										10280 NW 9th Street Cir #APT 203 Miami, Florida(FL), 33172
									</p>
									<div className="button-group">
										<Link to="/showimg-list">Inventory</Link>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-4">
							<div className="dealer-box">
								<div className="img-box">
									<figure>
										<img className="img-fluid" src={dealer1} />
									</figure>
								</div>
								<div className="content">
									<h4>Shawn Micheal</h4>
									<p>
										10280 NW 9th Street Cir #APT 203 Miami, Florida(FL), 33172
									</p>
									<div className="button-group">
										<Link to="/showimg-list">Inventory</Link>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-4">
							<div className="dealer-box">
								<div className="img-box">
									<figure>
										<img className="img-fluid" src={dealer2} />
									</figure>
								</div>
								<div className="content">
									<h4>Johnny Lewis</h4>
									<p>
										10280 NW 9th Street Cir #APT 203 Miami, Florida(FL), 33172
									</p>
									<div className="button-group">
										<Link to="/showimg-list">Inventory</Link>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-4">
							<div className="dealer-box">
								<div className="img-box">
									<figure>
										<img className="img-fluid" src={dealer1} />
									</figure>
								</div>
								<div className="content">
									<h4>Shawn Micheal</h4>
									<p>
										10280 NW 9th Street Cir #APT 203 Miami, Florida(FL), 33172
									</p>
									<div className="button-group">
										<Link to="/showimg-list">Inventory</Link>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-4">
							<div className="dealer-box">
								<div className="img-box">
									<figure>
										<img className="img-fluid" src={dealer2} />
									</figure>
								</div>
								<div className="content">
									<h4>Johnny Lewis</h4>
									<p>
										10280 NW 9th Street Cir #APT 203 Miami, Florida(FL), 33172
									</p>
									<div className="button-group">
										<Link to="/showimg-list">Inventory</Link>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-4">
							<div className="dealer-box">
								<div className="img-box">
									<figure>
										<img className="img-fluid" src={dealer3} />
									</figure>
								</div>
								<div className="content">
									<h4>Laureen Wood</h4>
									<p>
										10280 NW 9th Street Cir #APT 203 Miami, Florida(FL), 33172
									</p>
									<div className="button-group">
										<Link to="/showimg-list">Inventory</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Dealer Sec End Here */}

			<Footer />
		</>
	);
};

export default AllDealer;
