import React from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { Chat1, Chat2, Chat3, Chat4, Chat5, tabsImg } from "../../constant";
import add1 from "../../Assets/img/feature5.png";
import meter from "../../Assets/img/meter.svg";
import meter1 from "../../Assets/img/meter1.svg";
import meter2 from "../../Assets/img/meter2.svg";
import meter3 from "../../Assets/img/meter3.svg";
import add2 from "../../Assets/img/feature6.png";
import add3 from "../../Assets/img/feature2.png";
import Sidebar from "./Sidebar";
import { Link, useNavigate } from "react-router-dom";

const MyAdd = () => {
  const navigate = useNavigate();
  return (
    <>
      <Header />

      <section className="profile_Sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <Sidebar />
            </div>
            <div className="col-lg-9">
              <div className="right-side">
                <div className="title">
                  <h3>My Ads</h3>
                  <div className="snap-chat">
                    <div class="dropdown">
                      <button
                        class="btn btn-secondary dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Dropdown button
                      </button>
                      <div
                        class="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <a class="dropdown-item" href="#">
                          All
                        </a>
                      </div>
                    </div>
                    <Link to="/PostSnap">Post Snapshots</Link>
                  </div>
                </div>
                <div className="profile-detail"></div>
              </div>
              <div className="row">
                <div className="col-lg-4 mb-3">
                  <button
                    className="postAdd"
                    type="button"
                    onClick={() => navigate("/PostVehicleAds")}
                  >
                    <div className="post-add">
                      <div className="inner-text">
                        <h4>
                          <i class="fa fa-plus" aria-hidden="true"></i> Post ad
                        </h4>
                        <p>Lorem Ipsum Dolor Sit Amet</p>
                      </div>
                    </div>
                  </button>
                </div>
                <div className="col-lg-4 mb-3">
                  <div className="featured-all">
                    <div className="f-img">
                      <img src={add1}></img>
                    </div>

                    <div className="feature-head">
                      <h2>
                        <span>$</span>21,898
                      </h2>
                      <div className="tag">
                        <button
                          type="button"
                          class="btn btn-primary"
                          data-bs-toggle="modal"
                          data-bs-target="#postAdd"
                        >
                          <span>Go Featured</span>
                        </button>

                        <img src={tabsImg} className="img-fluid" />
                      </div>
                    </div>

                    <div className="feature-normal-head">
                      <h3>2017 CHEVROLET VOLT LT</h3>
                    </div>

                    <div className="feature-icon-head">
                      <span>
                        <img src={meter1} />
                        18,234 miles
                      </span>
                      <span>
                        <img src={meter2} />
                        267 Mi max
                      </span>
                    </div>
                    <div className="feature-iiimmgg">
                      <span>
                        <img src={meter3} />
                        Longview, TX
                      </span>
                    </div>
                    <div className="new-listing">
                      <h5>New listing</h5>
                    </div>
                    <div className="dropdownon">
                      <div class="dropdown">
                        <button
                          class="btn btn-secondary dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                        </button>
                        <div
                          class="dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <a class="dropdown-item" href="#">
                            Edit
                          </a>
                          <a class="dropdown-item" href="#">
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 mb-3">
                  <div className="featured-all">
                    <div className="f-img">
                      <img src={add2}></img>
                    </div>
                    <div className="feature-head">
                      <h2>
                        <span>$</span>21,898
                      </h2>
                      <div className="tag">
                        <button
                          type="button"
                          class="btn btn-primary"
                          data-bs-toggle="modal"
                          data-bs-target="#postAdd"
                        >
                          <span>Go Featured</span>
                        </button>
                        <img src={tabsImg} className="img-fluid" />
                      </div>
                    </div>

                    <div className="feature-normal-head">
                      <h3>2017 CHEVROLET VOLT LT</h3>
                    </div>

                    <div className="feature-icon-head">
                      <span>
                        <img src={meter1} />
                        18,234 miles
                      </span>
                      <span>
                        <img src={meter2} />
                        267 Mi max
                      </span>
                    </div>
                    <div className="feature-iiimmgg">
                      <span>
                        <img src={meter3} />
                        Longview, TX
                      </span>
                    </div>
                    <div className="new-listing">
                      <h5>New listing</h5>
                    </div>
                    <div className="dropdownon">
                      <div class="dropdown">
                        <button
                          class="btn btn-secondary dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                        </button>
                        <div
                          class="dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <a class="dropdown-item" href="#">
                            Edit
                          </a>
                          <a class="dropdown-item" href="#">
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 mb-3">
                  <div className="featured-all">
                    <div className="f-img">
                      <img src={add3}></img>
                    </div>
                    <div className="feature-head">
                      <h2>
                        <span>$</span>21,898
                      </h2>
                      <div className="tag">
                        <button
                          type="button"
                          class="btn btn-primary"
                          data-bs-toggle="modal"
                          data-bs-target="#postAdd"
                        >
                          <span>Go Featured</span>
                        </button>
                        <img src={tabsImg} className="img-fluid" />
                      </div>
                    </div>

                    <div className="feature-normal-head">
                      <h3>2017 CHEVROLET VOLT LT</h3>
                    </div>

                    <div className="feature-icon-head">
                      <span>
                        <img src={meter1} />
                        18,234 miles
                      </span>
                      <span>
                        <img src={meter2} />
                        267 Mi max
                      </span>
                    </div>
                    <div className="feature-iiimmgg">
                      <span>
                        <img src={meter3} />
                        Longview, TX
                      </span>
                    </div>
                    <div className="new-listing">
                      <h5>New listing</h5>
                    </div>
                    <div className="dropdownon">
                      <div class="dropdown">
                        <button
                          class="btn btn-secondary dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                        </button>
                        <div
                          class="dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <a class="dropdown-item" href="#">
                            Edit
                          </a>
                          <a class="dropdown-item" href="#">
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="post-add-modal">
        <div
          class="modal fade"
          id="postAdd"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  <img src={tabsImg} className="img-fluid" alt="" />
                  <span>Go Feature Sale Faster</span>
                  <img src={tabsImg} className="img-fluid" alt="" />
                </h5>
              </div>
              <div class="modal-body">
                <ul>
                  <li>
                    <span>
                      <label class="price_container">
                        <input
                          type="radio"
                          hidden
                          checked="checked"
                          name="Addradio"
                        />
                        <span class="checkmark">
                          <span className="dot"></span>
                          <ruby>Go Feature 5 Days</ruby>
                        </span>
                      </label>
                    </span>
                    <span className="price">$20.00</span>
                  </li>
                  <li>
                    <span>
                      <label class="price_container">
                        <input type="radio" hidden name="Addradio" />
                        <span class="checkmark">
                          <span className="dot"></span>
                          <ruby>Go Feature 10 Days</ruby>
                        </span>
                      </label>
                    </span>
                    <span className="price">$30.00</span>
                  </li>
                  <li>
                    <span>
                      <label class="price_container">
                        <input type="radio" hidden name="Addradio" />
                        <span class="checkmark">
                          <span className="dot"></span>
                          <ruby>Go Feature 15 Days</ruby>
                        </span>
                      </label>
                    </span>
                    <span className="price">$40.00</span>
                  </li>
                </ul>
                <div className="button-group">
                  <button className="btn">Go Featured</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default MyAdd;
