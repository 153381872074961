import React from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import searchBtn from "../../Assets/img/search-btn.png";
import "../../Assets/Css/faqs.css";
const Faqs = () => {
	return (
		<>
			<Header />

			{/* Inner Banner Sec Start Here */}
			<section className="bannerSec">
				<div className="container">
					<div className="content_wrapper">
						<h2>
							Learn more about
							<br /> allEVehicles
						</h2>
						{/* <div className="searchBox">
							<input
								className="form-control"
								type="text"
								placeholder="Search terms"
							/>
							<button className="search-icon2 btn" type="submit">
								<img src={searchBtn} className="img-fluid" />
							</button>
						</div> */}
					</div>
				</div>
			</section>
			{/* Inner Banner Sec Start Here */}

			{/* Faqs Sec Start Here */}
			<section className="faqs">
				<div className="container">
					<div class="accordion" id="accordionExample">
						<div class="accordion-item">
							<h2 class="accordion-header" id="headingOne">
								<button
									class="accordion-button"
									type="button"
									data-bs-toggle="collapse"
									data-bs-target="#collapseOne"
									aria-expanded="true"
									aria-controls="collapseOne"
								>
									Where can I subscribe to your newsletter?
								</button>
							</h2>
							<div
								id="collapseOne"
								class="accordion-collapse collapse show"
								aria-labelledby="headingOne"
								data-bs-parent="#accordionExample"
							>
								<div class="accordion-body">
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor incididunt ut labore et dolore magna aliqua.
									Ut enim ad
								</div>
							</div>
						</div>
						<div class="accordion-item">
							<h2 class="accordion-header" id="headingTwo">
								<button
									class="accordion-button collapsed"
									type="button"
									data-bs-toggle="collapse"
									data-bs-target="#collapseTwo"
									aria-expanded="false"
									aria-controls="collapseTwo"
								>
									Where can in edit my address?
								</button>
							</h2>
							<div
								id="collapseTwo"
								class="accordion-collapse collapse"
								aria-labelledby="headingTwo"
								data-bs-parent="#accordionExample"
							>
								<div class="accordion-body">
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor incididunt ut labore et dolore magna aliqua.
									Ut enim ad
								</div>
							</div>
						</div>
						<div class="accordion-item">
							<h2 class="accordion-header" id="headingThree">
								<button
									class="accordion-button collapsed"
									type="button"
									data-bs-toggle="collapse"
									data-bs-target="#collapseThree"
									aria-expanded="false"
									aria-controls="collapseThree"
								>
									Do you accept orders via Phone or E-mail?
								</button>
							</h2>
							<div
								id="collapseThree"
								class="accordion-collapse collapse"
								aria-labelledby="headingThree"
								data-bs-parent="#accordionExample"
							>
								<div class="accordion-body">
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor incididunt ut labore et dolore magna aliqua.
									Ut enim ad
								</div>
							</div>
						</div>
						<div class="accordion-item">
							<h2 class="accordion-header" id="headingFour">
								<button
									class="accordion-button collapsed"
									type="button"
									data-bs-toggle="collapse"
									data-bs-target="#collapseFour"
									aria-expanded="false"
									aria-controls="collapseFour"
								>
									Do I need to create an account to buy Membership?
								</button>
							</h2>
							<div
								id="collapseFour"
								class="accordion-collapse collapse"
								aria-labelledby="headingFour"
								data-bs-parent="#accordionExample"
							>
								<div class="accordion-body">
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor incididunt ut labore et dolore magna aliqua.
									Ut enim ad
								</div>
							</div>
						</div>
						<div class="accordion-item">
							<h2 class="accordion-header" id="headingFive">
								<button
									class="accordion-button collapsed"
									type="button"
									data-bs-toggle="collapse"
									data-bs-target="#collapseFive"
									aria-expanded="false"
									aria-controls="collapseFive"
								>
									What are your opening hours?
								</button>
							</h2>
							<div
								id="collapseFive"
								class="accordion-collapse collapse"
								aria-labelledby="headingFive"
								data-bs-parent="#accordionExample"
							>
								<div class="accordion-body">
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor incididunt ut labore et dolore magna aliqua.
									Ut enim ad
								</div>
							</div>
						</div>
						<div class="accordion-item">
							<h2 class="accordion-header" id="headingSix">
								<button
									class="accordion-button collapsed"
									type="button"
									data-bs-toggle="collapse"
									data-bs-target="#collapseSix"
									aria-expanded="false"
									aria-controls="collapseSix"
								>
									Are you on Twitter, Facebook and other social media platforms?
								</button>
							</h2>
							<div
								id="collapseSix"
								class="accordion-collapse collapse"
								aria-labelledby="headingSix"
								data-bs-parent="#accordionExample"
							>
								<div class="accordion-body">
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor incididunt ut labore et dolore magna aliqua.
									Ut enim ad
								</div>
							</div>
						</div>
						<div class="accordion-item">
							<h2 class="accordion-header" id="headingSeven">
								<button
									class="accordion-button collapsed"
									type="button"
									data-bs-toggle="collapse"
									data-bs-target="#collapseSeven"
									aria-expanded="false"
									aria-controls="collapseSeven"
								>
									Why do the prices sometimes change?
								</button>
							</h2>
							<div
								id="collapseSeven"
								class="accordion-collapse collapse"
								aria-labelledby="headingSeven"
								data-bs-parent="#accordionExample"
							>
								<div class="accordion-body">
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor incididunt ut labore et dolore magna aliqua.
									Ut enim ad
								</div>
							</div>
						</div>
						<div class="accordion-item">
							<h2 class="accordion-header" id="headingEight">
								<button
									class="accordion-button collapsed"
									type="button"
									data-bs-toggle="collapse"
									data-bs-target="#collapseEight"
									aria-expanded="false"
									aria-controls="collapseEight"
								>
									Is there any subscription ?
								</button>
							</h2>
							<div
								id="collapseEight"
								class="accordion-collapse collapse"
								aria-labelledby="headingEight"
								data-bs-parent="#accordionExample"
							>
								<div class="accordion-body">
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor incididunt ut labore et dolore magna aliqua.
									Ut enim ad
								</div>
							</div>
						</div>
						<div class="accordion-item">
							<h2 class="accordion-header" id="headingNine">
								<button
									class="accordion-button collapsed"
									type="button"
									data-bs-toggle="collapse"
									data-bs-target="#collapseNine"
									aria-expanded="false"
									aria-controls="collapseNine"
								>
									How can Iogin if I forgot my password?
								</button>
							</h2>
							<div
								id="collapseNine"
								class="accordion-collapse collapse"
								aria-labelledby="headingNine"
								data-bs-parent="#accordionExample"
							>
								<div class="accordion-body">
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor incididunt ut labore et dolore magna aliqua.
									Ut enim ad
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Faqs Sec End Here */}

			<Footer />
		</>
	);
};

export default Faqs;
