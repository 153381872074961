import React from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { DelarImg } from "../../constant";
import Sidebar from "./Sidebar";
import "../../Assets/Css/Profile.css";
import { Link, useNavigate } from "react-router-dom";

const Bidding = () => {
	const navigate = useNavigate();
	return (
		<>
			<Header />

			<section className="profile_Sec">
				<div className="container">
					<div className="row">
						<div className="col-lg-3">
							<Sidebar />
						</div>
						<div className="col-lg-9">
							<div className="right-side">
								<div className="title mb-3">
									<h3>Bidding List</h3>
								</div>
								<div className="profile-detail">
									<div className="bidding-list">
										<div className="table-responsive">
											<table className="table">
												<tr>
													<th>BID ID</th>
													<th>BID AMOUNT</th>
													<th>BID STATUS</th>
													<th>ACTION</th>
												</tr>
												<tbody>
													<tr>
														<td>#8634832</td>
														<td>$41,320</td>
														<td>Pending...</td>
														<td>
															<button className="btn">Withdraw</button>
														</td>
													</tr>
													<tr>
														<td>#8634832</td>
														<td>$41,320</td>
														<td>Pending...</td>
														<td>
															<button className="btn">Withdraw</button>
														</td>
													</tr>
													<tr>
														<td>#8634832</td>
														<td>$41,320</td>
														<td>Pending...</td>
														<td>
															<button className="btn">Withdraw</button>
														</td>
													</tr>
													<tr>
														<td>#8634832</td>
														<td>$41,320</td>
														<td>Withdraw</td>
														<td>
															<button disabled className="btn">
																Withdraw
															</button>
														</td>
													</tr>
													<tr>
														<td>#8634832</td>
														<td>$41,320</td>
														<td>Accepted</td>
														<td>
															<button disabled className="btn">
																Withdraw
															</button>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
										<div className="button-group">
											<button
												onClick={() => navigate("/seller-bidding")}
												className="lnk"
											>
												View as a seller
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<Footer />
		</>
	);
};

export default Bidding;
