import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";

const SaveAddress = () => {
	const addClear = {
		position: "absolute",
		right: "22px",
		bottom: "20px",
		color: "red",
		zIndex: "111",
	};

	return (
		<>
			<label className="container">
				<input type="radio" name="radio" />
				<span className="checkmark">
					<div className="select-address">
						<div className="selection">
							<i className="fa fa-check" aria-hidden="true"></i>
						</div>
						<div className="radio-btn">
							<span></span>
						</div>
						<div className="address-detail">
							<h5>
								<span className="property">Country:</span>
								<span className="value">USA</span>
								<span className="property pl-4">State:</span>
								<span className="value">50044</span>
							</h5>
							<h5>
								<span className="property">Address:</span>
								<span className="value">
									6 Merrill Street, Bussey,ia, 50044 United States
								</span>
							</h5>
							<h5>
								<span className="property">Phone:</span>
								<span className="value">+121456656</span>
							</h5>
						</div>
					</div>
				</span>
			</label>
			<label className="container" style={{ position: "relative" }}>
				<input type="radio" name="radio" />
				<DeleteIcon style={addClear} />
				<span className="checkmark">
					<div className="select-address">
						<div className="selection">
							<i className="fa fa-check" aria-hidden="true"></i>
						</div>
						<div className="radio-btn">
							<span></span>
						</div>
						<div className="address-detail">
							<h5>
								<span className="property">Country:</span>
								<span className="value">USA</span>
								<span className="property pl-4">State:</span>
								<span className="value">50044</span>
							</h5>
							<h5>
								<span className="property">Address:</span>
								<span className="value">
									6 Merrill Street, Bussey,ia, 50044 United States
								</span>
							</h5>
							<h5>
								<span className="property">Phone:</span>
								<span className="value">+121456656</span>
							</h5>
						</div>
					</div>
				</span>
			</label>
		</>
	);
};

export default SaveAddress;
