import React from "react";
import Slider from "react-slick";
import work from "../../Assets/img/work.png";
import work2 from "../../Assets/img/work2.png";
import work3 from "../../Assets/img/work3.png";
import slider from "../../Assets/img/slider.png";
import snap from "../../Assets/img/seller-snapshot.webp";
import coma from "../../Assets/img/coma.png";
import coma2 from "../../Assets/img/coma2.png";
import joh from "../../Assets/img/joh.png";

import "../../Assets/Css/style.css";
const HowItWorks = () => {
	const settings = {
		className: "center",
		centerMode: true,
		infinite: true,
		centerPadding: "00px",
		slidesToShow: 1,
		slidesToScroll: 1,
		speed: 500,
	};

	return (
		<div className="HowItWorks">
			<div className="container-fluid for-background">
				<div>
					<Slider {...settings}>
						<div className="slides for1">
							<div className="testi">
								<p className="TestimonialBtn">Seller Snapshot</p>
							</div>
							<div className="row for-flexing">
								<div className="col-md-8 p-0">
									<div className="slidz-img">
										<img className="img-fluid" src={coma}></img>
									</div>
									<div className="slider-para">
										<p>
											"Lorem ipsum dolor sit amet, consectetur adipiscing elit,
											sed do eiusmod tempor incididunt ut labore et dolore magna
											aliqua. Ut enim ad minim veniam, quis nostrud exercitation
											ullamco laboris.
											<div className="john-doe">
												<img src={joh}></img>
												<div className="john-head">
													<h4>John Doe</h4>
													<h5>Business man</h5>
												</div>
											</div>
											<div className="slidz-img2">
												<img className="img-fluid" src={coma2}></img>
											</div>
										</p>
									</div>
								</div>

								<div className="col-md-4 px-0">
									<div className="sliderx-img">
										<img src={snap} />
									</div>
								</div>
							</div>
						</div>

						<div className="slides for1">
							<div className="testi">
								<p className="TestimonialBtn">Seller Snapshot</p>
							</div>
							<div className="row for-flexing">
								<div className="col-md-8 p-0">
									<div className="slidz-img">
										<img className="img-fluid" src={coma}></img>
									</div>
									<div className="slider-para">
										<p>
											"Lorem ipsum dolor sit amet, consectetur adipiscing elit,
											sed do eiusmod tempor incididunt ut labore et dolore magna
											aliqua. Ut enim ad minim veniam, quis nostrud exercitation
											ullamco laboris.
											<div className="john-doe">
												<img src={joh}></img>
												<div className="john-head">
													<h4>John Doe</h4>
													<h5>Business man</h5>
												</div>
											</div>
											<div className="slidz-img2">
												<img className="img-fluid" src={coma2}></img>
											</div>
										</p>
									</div>
								</div>

								<div className="col-md-4 px-0">
									<div className="sliderx-img">
										<img src={snap} />
									</div>
								</div>
							</div>
						</div>

						<div className="slides for1">
							<div className="testi">
								<p className="TestimonialBtn">Seller Snapshot</p>
							</div>
							<div className="row for-flexing">
								<div className="col-md-8 p-0">
									<div className="slidz-img">
										<img className="img-fluid" src={coma}></img>
									</div>
									<div className="slider-para">
										<p>
											"Lorem ipsum dolor sit amet, consectetur adipiscing elit,
											sed do eiusmod tempor incididunt ut labore et dolore magna
											aliqua. Ut enim ad minim veniam, quis nostrud exercitation
											ullamco laboris.
											<div className="john-doe">
												<img src={joh}></img>
												<div className="john-head">
													<h4>John Doe</h4>
													<h5>Business man</h5>
												</div>
											</div>
											<div className="slidz-img2">
												<img className="img-fluid" src={coma2}></img>
											</div>
										</p>
									</div>
								</div>

								<div className="col-md-4 px-0">
									<div className="sliderx-img">
										<img src={snap} />
									</div>
								</div>
							</div>
						</div>
						{/* <div className='slides'>
          <img src={work2}></img>
          </div>
          <div className='slides for1'>
          <img src={work3}></img>
          </div>
          <div className='slides for1'>
          <img src={work}></img>
          </div>
          <div className='slides'>
          <img src={work2}></img>
          </div>
          <div className='slides for1'>
          <img src={work3}></img>
          </div> */}
					</Slider>
				</div>
			</div>
		</div>
	);
};

export default HowItWorks;
