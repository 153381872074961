import React from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { profile_img } from "../../constant";
import Slider from "react-slick";
import "../../Assets/Css/showingList.css";
import banner from "../../Assets/img/banner.png";
import about1 from "../../Assets/img/about1.png";
import car1 from "../../Assets/img/car1.png";
import car2 from "../../Assets/img/car2.png";
import icon from "../../Assets/img/icon.png";
import service from "../../Assets/img/services1.png";
import services2 from "../../Assets/img/services2.png";
import services3 from "../../Assets/img/services3.png";
import services4 from "../../Assets/img/services4.png";
import feature1 from "../../Assets/img/feature1.png";
import meter from "../../Assets/img/meter.svg";
import meter1 from "../../Assets/img/meter1.svg";
import meter2 from "../../Assets/img/meter2.svg";
import meter3 from "../../Assets/img/meter3.svg";
import add from "../../Assets/img/add.png";
import feature2 from "../../Assets/img/feature2.png";
import feature3 from "../../Assets/img/feature3.png";
import feature4 from "../../Assets/img/feature4.png";
import feature5 from "../../Assets/img/feature5.png";
import feature6 from "../../Assets/img/feature6.png";
import { Link, useNavigate } from "react-router-dom";

const ShowingList = () => {
	const navigate = useNavigate();
	const HandlePageChange = () => {
		navigate("/cardetailpage");
	};
	const listingImg = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
	};
	return (
		<>
			<Header />
			{/* profile sec starts here */}
			<section className="profile-messsage-sec">
				<div className="container">
					<div className="row">
						<div className="col-lg-3">
							<div className="profile-img-wrapper">
								<figure>
									<img src={profile_img} className="img-fluid" />
								</figure>
							</div>
						</div>
						<div className="col-lg-9">
							<div className="name-and-btn-wrapper">
								<div className="name-wrapper">
									<h4>Shawn Micheal</h4>
									<h5>
										10280 NW 9th Street Cir #APT 203 Miami, Florida(FL), 33172
									</h5>
								</div>
								<div className="btn-wrapper">
									<button className="btn">Message</button>
								</div>
							</div>
							<div className="des-wrapper">
								<p>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor incididunt ut labore et dolore magna aliqua.
									Ut enim ad minim veniam, quis nostrud exercitation ullamco
									laboris nisi ut aliquip ex ea commodo consequat.
								</p>
								<h6>
									Website: <a href="#">https://www.tesla.com/</a>
								</h6>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* profile sec ends here */}
			{/* Featured section  */}
			<section className="feature-sec  ">
				<div className="container">
					<div className="car-head">
						<h3>Shawn Listing</h3>
					</div>
					<div className="row wow animate__animated animate__slideInLeft">
						<div className="col-md-3">
							<div className="featured-all">
								<div className="f-img">
									<figure>
										<Slider {...listingImg}>
											<img src={feature1}></img>
											<img src={feature1}></img>
											<img src={feature1}></img>
										</Slider>
									</figure>
									{/* <div className="actions">
										<a href="#">
											<i class="fa fa-heart" aria-hidden="true"></i>
										</a>
										<a href="#">
											<i class="fa fa-eye-slash" aria-hidden="true"></i>
										</a>
									</div> */}
								</div>
								<div className="feature-head">
									<h2>
										<span>$</span>21,898
									</h2>
								</div>

								<div className="feature-normal-head">
									<h3>2017 CHEVROLET VOLT LT</h3>
								</div>

								<div className="feature-icon-head">
									<span>
										<img src={meter1}></img> 18,234 miles
									</span>
									<span>
										<img src={meter2}></img> 267 Mi max range
									</span>
								</div>
								<div className="feature-iiimmgg">
									<span>
										<img src={meter3}></img> Longview, TX
									</span>
								</div>

								<div className="checkup">
									<button className="btn" onClick={HandlePageChange}>
										View more
									</button>
								</div>

								<div className="new-listing">
									<h5>New listing</h5>
								</div>
							</div>
						</div>
						<div className="col-md-3">
							<div className="featured-all">
								<div className="f-img">
									<figure>
										<Slider {...listingImg}>
											<img src={feature5}></img>
											<img src={feature5}></img>
											<img src={feature5}></img>
										</Slider>
									</figure>
									{/* <div className="actions">
										<a href="#">
											<i class="fa fa-heart" aria-hidden="true"></i>
										</a>
										<a href="#">
											<i class="fa fa-eye-slash" aria-hidden="true"></i>
										</a>
									</div> */}
								</div>
								<div className="feature-head">
									<h2>
										<span>$</span>21,898
									</h2>
									{/* <p>
                            <i class="fa fa-car" aria-hidden="true"></i> For
                            price | $566 below market
                          </p> */}
								</div>

								<div className="feature-normal-head">
									<h3>2017 CHEVROLET VOLT LT</h3>
								</div>

								<div className="feature-icon-head">
									<span>
										<img src={meter1}></img> 18,234 miles
									</span>
									<span>
										<img src={meter2}></img> 267 Mi max range
									</span>
								</div>
								<div className="feature-iiimmgg">
									<span>
										<img src={meter3}></img> Longview, TX
									</span>
								</div>

								<div className="checkup">
									<button className="btn" onClick={HandlePageChange}>
										View more
									</button>
								</div>

								<div className="new-listing">
									<h5>New listing</h5>
								</div>
							</div>
						</div>
						<div className="col-md-3">
							<div className="featured-all">
								<div className="f-img">
									<figure>
										<Slider {...listingImg}>
											<img src={feature6}></img>
											<img src={feature6}></img>
											<img src={feature6}></img>
										</Slider>
									</figure>
									{/* <div className="actions">
										<a href="#">
											<i class="fa fa-heart" aria-hidden="true"></i>
										</a>
										<a href="#">
											<i class="fa fa-eye-slash" aria-hidden="true"></i>
										</a>
									</div> */}
								</div>
								<div className="feature-head">
									<h2>
										<span>$</span>21,898
									</h2>
									{/* <p>
                            <i class="fa fa-car" aria-hidden="true"></i> For
                            price | $566 below market
                          </p> */}
								</div>

								<div className="feature-normal-head">
									<h3>2017 CHEVROLET VOLT LT</h3>
								</div>

								<div className="feature-icon-head">
									<span>
										<img src={meter1}></img> 18,234 miles
									</span>
									<span>
										<img src={meter2}></img> 267 Mi max range
									</span>
								</div>
								<div className="feature-iiimmgg">
									<span>
										<img src={meter3}></img> Longview, TX
									</span>
								</div>

								<div className="checkup">
									<button className="btn" onClick={HandlePageChange}>
										View more
									</button>
								</div>

								<div className="new-listing">
									<h5>New listing</h5>
								</div>
							</div>
						</div>
						<div className="col-md-3">
							<div className="featured-all">
								<div className="f-img">
									<figure>
										<Slider {...listingImg}>
											<img src={feature1}></img>
											<img src={feature1}></img>
											<img src={feature1}></img>
										</Slider>
									</figure>
									{/* <div className="actions">
										<a href="#">
											<i class="fa fa-heart" aria-hidden="true"></i>
										</a>
										<a href="#">
											<i class="fa fa-eye-slash" aria-hidden="true"></i>
										</a>
									</div> */}
								</div>
								<div className="feature-head">
									<h2>
										<span>$</span>21,898
									</h2>
								</div>

								<div className="feature-normal-head">
									<h3>2017 CHEVROLET VOLT LT</h3>
								</div>

								<div className="feature-icon-head">
									<span>
										<img src={meter1}></img> 18,234 miles
									</span>
									<span>
										<img src={meter2}></img> 267 Mi max range
									</span>
								</div>
								<div className="feature-iiimmgg">
									<span>
										<img src={meter3}></img> Longview, TX
									</span>
								</div>

								<div className="checkup">
									<button className="btn" onClick={HandlePageChange}>
										View more
									</button>
								</div>

								<div className="new-listing">
									<h5>New listing</h5>
								</div>
							</div>
						</div>
					</div>
					<div className="row wow animate__animated animate__slideInLeft pt-5">
						<div className="col-md-3">
							<div className="featured-all">
								<div className="f-img">
									<figure>
										<Slider {...listingImg}>
											<img src={feature1}></img>
											<img src={feature1}></img>
											<img src={feature1}></img>
										</Slider>
									</figure>
									{/* <div className="actions">
										<a href="#">
											<i class="fa fa-heart" aria-hidden="true"></i>
										</a>
										<a href="#">
											<i class="fa fa-eye-slash" aria-hidden="true"></i>
										</a>
									</div> */}
								</div>
								<div className="feature-head">
									<h2>
										<span>$</span>21,898
									</h2>
								</div>

								<div className="feature-normal-head">
									<h3>2017 CHEVROLET VOLT LT</h3>
								</div>

								<div className="feature-icon-head">
									<span>
										<img src={meter1}></img> 18,234 miles
									</span>
									<span>
										<img src={meter2}></img> 267 Mi max range
									</span>
								</div>
								<div className="feature-iiimmgg">
									<span>
										<img src={meter3}></img> Longview, TX
									</span>
								</div>

								<div className="checkup">
									<button className="btn" onClick={HandlePageChange}>
										View more
									</button>
								</div>

								<div className="new-listing">
									<h5>New listing</h5>
								</div>
							</div>
						</div>
						<div className="col-md-3">
							<div className="featured-all">
								<div className="f-img">
									<figure>
										<Slider {...listingImg}>
											<img src={feature5}></img>
											<img src={feature5}></img>
											<img src={feature5}></img>
										</Slider>
									</figure>
									{/* <div className="actions">
										<a href="#">
											<i class="fa fa-heart" aria-hidden="true"></i>
										</a>
										<a href="#">
											<i class="fa fa-eye-slash" aria-hidden="true"></i>
										</a>
									</div> */}
								</div>
								<div className="feature-head">
									<h2>
										<span>$</span>21,898
									</h2>
									{/* <p>
                            <i class="fa fa-car" aria-hidden="true"></i> For
                            price | $566 below market
                          </p> */}
								</div>

								<div className="feature-normal-head">
									<h3>2017 CHEVROLET VOLT LT</h3>
								</div>

								<div className="feature-icon-head">
									<span>
										<img src={meter1}></img> 18,234 miles
									</span>
									<span>
										<img src={meter2}></img> 267 Mi max range
									</span>
								</div>
								<div className="feature-iiimmgg">
									<span>
										<img src={meter3}></img> Longview, TX
									</span>
								</div>

								<div className="checkup">
									<button className="btn" onClick={HandlePageChange}>
										View more
									</button>
								</div>

								<div className="new-listing">
									<h5>New listing</h5>
								</div>
							</div>
						</div>
						<div className="col-md-3">
							<div className="featured-all">
								<div className="f-img">
									<figure>
										<Slider {...listingImg}>
											<img src={feature6}></img>
											<img src={feature6}></img>
											<img src={feature6}></img>
										</Slider>
									</figure>
									{/* <div className="actions">
										<a href="#">
											<i class="fa fa-heart" aria-hidden="true"></i>
										</a>
										<a href="#">
											<i class="fa fa-eye-slash" aria-hidden="true"></i>
										</a>
									</div> */}
								</div>
								<div className="feature-head">
									<h2>
										<span>$</span>21,898
									</h2>
									{/* <p>
                            <i class="fa fa-car" aria-hidden="true"></i> For
                            price | $566 below market
                          </p> */}
								</div>

								<div className="feature-normal-head">
									<h3>2017 CHEVROLET VOLT LT</h3>
								</div>

								<div className="feature-icon-head">
									<span>
										<img src={meter1}></img> 18,234 miles
									</span>
									<span>
										<img src={meter2}></img> 267 Mi max range
									</span>
								</div>
								<div className="feature-iiimmgg">
									<span>
										<img src={meter3}></img> Longview, TX
									</span>
								</div>

								<div className="checkup">
									<button className="btn" onClick={HandlePageChange}>
										View more
									</button>
								</div>

								<div className="new-listing">
									<h5>New listing</h5>
								</div>
							</div>
						</div>
						<div className="col-md-3">
							<div className="featured-all">
								<div className="f-img">
									<figure>
										<Slider {...listingImg}>
											<img src={feature1}></img>
											<img src={feature1}></img>
											<img src={feature1}></img>
										</Slider>
									</figure>
									{/* <div className="actions">
										<a href="#">
											<i class="fa fa-heart" aria-hidden="true"></i>
										</a>
										<a href="#">
											<i class="fa fa-eye-slash" aria-hidden="true"></i>
										</a>
									</div> */}
								</div>
								<div className="feature-head">
									<h2>
										<span>$</span>21,898
									</h2>
								</div>

								<div className="feature-normal-head">
									<h3>2017 CHEVROLET VOLT LT</h3>
								</div>

								<div className="feature-icon-head">
									<span>
										<img src={meter1}></img> 18,234 miles
									</span>
									<span>
										<img src={meter2}></img> 267 Mi max range
									</span>
								</div>
								<div className="feature-iiimmgg">
									<span>
										<img src={meter3}></img> Longview, TX
									</span>
								</div>

								<div className="checkup">
									<button className="btn" onClick={HandlePageChange}>
										View more
									</button>
								</div>

								<div className="new-listing">
									<h5>New listing</h5>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* End of Featured section  */}
			<Footer />
		</>
	);
};

export default ShowingList;
