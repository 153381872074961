import React, { useRef } from "react";
import Slider from "react-slick";
import "../../Assets/Css/carpreview.css";
import {
  carpreview2,
  carpreview3,
  carpreview4,
  carpreview5,
  carpreview6,
  carpreview7,
  carpreview8,
  preview,
} from "../../constant/index";
import $ from "jquery";

const CarPreview = () => {
  const slider1 = useRef(null);
  const slider2 = useRef(null);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
  };
  return (
    <>
      {/* car preview starts here */}
      <section className="carpreview">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="car-nav-links">
                <ul>
                  <li>
                    <a href="#">Exterior</a>
                  </li>
                  <li>
                    <a href="#">Interior</a>
                  </li>
                  <li>
                    <a href="#">Mechanical</a>
                  </li>
                  <li>
                    <a href="#">Other</a>
                  </li>
                </ul>
              </div>
              <div className="view-img-wrapper">
                <figure>
                  <Slider asNavFor={slider1.current} arrows={false} ref={slider2}>
                    <img src={carpreview2} alt="" />
                    <img src={carpreview3} alt="" />
                    <img src={carpreview4} alt="" />
                    <img src={carpreview5} alt="" />
                    <img src={carpreview6} alt="" />
                    <img src={carpreview7} alt="" />
                    <img src={carpreview8} alt="" />
                  </Slider>
                </figure>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid mt-4">
          <div className="row">
            <div className="col-lg-12">
              <div className="img-list-wrapper">
                <Slider asNavFor={slider2.current} arrows={true} focusOnSelect={true} slidesToShow={5} ref={slider1}>
                  <img src={carpreview2} alt="" />
                  <img src={carpreview3} alt="" />
                  <img src={carpreview4} alt="" />
                  <img src={carpreview5} alt="" />
                  <img src={carpreview6} alt="" />
                  <img src={carpreview7} alt="" />
                  <img src={carpreview8} alt="" />
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* car preview starts here */}
    </>
  );
};

export default CarPreview;
