import React, { useRef } from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { bidImg1, DelarImg } from "../../constant";
import Sidebar from "./Sidebar";
import "../../Assets/Css/Profile.css";
import { Link, useNavigate } from "react-router-dom";

const SellerBidding = () => {
  const navigate = useNavigate();
  return (
    <>
      <Header />

      <section className="profile_Sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <Sidebar />
            </div>
            <div className="col-lg-9">
              <div className="right-side">
                <div className="title mb-3">
                  <h3>Bidding List</h3>
                </div>
                <div className="profile-detail">
                  <div className="bidding-list">
                    <div className="table-responsive sellerbidding">
                      <table className="table">
                        <tr>
                          <th>BIDS ON</th>
                          <th>EV Title</th>
                          <th>AUCTION</th>
                          <th>BIDS</th>
                          <th></th>
                        </tr>
                        <tbody>
                          <tr>
                            <td>#8634832</td>
                            <td>
                              <div className="ev-div">
                                <figure>
                                  <img src={bidImg1} className="img-fluid" />
                                </figure>
                                <p>2017 CHEVROLET VOLT</p>
                              </div>
                            </td>
                            <td>Ongoing ...</td>
                            <td>350 Bids</td>
                            <td>
                              <button
                                onClick={() => navigate("/auction")}
                                className="btn"
                              >
                                View Bids
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>#8634832</td>
                            <td>
                              <div className="ev-div">
                                <figure>
                                  <img src={bidImg1} className="img-fluid" />
                                </figure>
                                <p>2017 CHEVROLET VOLT</p>
                              </div>
                            </td>
                            <td>Ongoing ...</td>
                            <td>350 Bids</td>
                            <td>
                              <button
                                onClick={() => navigate("/auction")}
                                className="btn"
                              >
                                View Bids
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>#8634832</td>
                            <td>
                              <div className="ev-div">
                                <figure>
                                  <img src={bidImg1} className="img-fluid" />
                                </figure>
                                <p>2017 CHEVROLET VOLT</p>
                              </div>
                            </td>
                            <td>Ongoing ...</td>
                            <td>350 Bids</td>
                            <td>
                              <button
                                onClick={() => navigate("/auction")}
                                className="btn"
                              >
                                View Bids
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>#8634832</td>
                            <td>
                              <div className="ev-div">
                                <figure>
                                  <img src={bidImg1} className="img-fluid" />
                                </figure>
                                <p>2017 CHEVROLET VOLT</p>
                              </div>
                            </td>
                            <td>Ongoing ...</td>
                            <td>350 Bids</td>
                            <td>
                              <button disabled className="btn">
                                View Bids
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>#8634832</td>
                            <td>
                              <div className="ev-div">
                                <figure>
                                  <img src={bidImg1} className="img-fluid" />
                                </figure>
                                <p>2017 CHEVROLET VOLT</p>
                              </div>
                            </td>
                            <td>Ongoing ...</td>
                            <td>350 Bids</td>
                            <td>
                              <button disabled className="btn">
                                View Bids
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default SellerBidding;
