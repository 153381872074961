import React from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import "../../Assets/Css/about.css";
import { dots_img, mission_img, mission_line } from "../../constant";
import ReCAPTCHA from "react-google-recaptcha";
import ContactUs from "../../Components/Contactus/ContactUs";

const About = () => {
	function onChange(value) {
		console.log("Captcha value:", value);
	}
	return (
		<>
			<Header />
			{/* About banner starts here */}
			<section className="about-banner">
				<div className="conteiner">
					<div className="row">
						<div className="col-lg-12">
							<div className="about-banner-heading">
								<h1>About Us</h1>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* About banner ends here */}
			{/* about content sec starts here */}
			<section className="about-content-sec">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="content-wrapper content-wrapper2s">
								<p>
									{/* allEVehicles.com is a digital marketplace dedicated to the
									buying and selling of All Electric Vehicles. */}
									{/* allEVehicles.com is a user-friendly digital marketplace
									dedicated to the buying and selling of All Electric Vehicles.
									We make EV buying simple, safe and fun. Shoppers can click
									through live inventory and see detailed information of the
									electric vehicles they’re interested in; and can anonymously
									contact the dealers or private sellers. Each listing gives a
									detailed description, features, Seller Snapshot and CARFAX
									report request allowing shoppers to make educated decisions
									about their new EV purchase. */}
									allEVehicles.com is a user-friendly digital marketplace dedicated to the buying and selling of All Electric Vehicles. We make EV buying simple, safe and fun. Shoppers can click through live inventory and see detailed information of the electric vehicles they’re interested in; and can anonymously contact the dealers or private sellers. 
<br/>
Each listing gives a detailed description, features, Seller Snapshot and CARFAX report request allowing shoppers to make educated decisions about their new EV purchase.  

								</p>
								{/* <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </p> */}
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* about content sec ends here */}
			{/* vision sec starts here */}
			<section className="vision-sec">
				<div className="container">
					<div className="col-lg-7">
						<div className="vision-card-wrapper">
							<h2>OUR STORY</h2>
							<p>
								{/* allEVehicles was founded in 2020, in Jacksonville, Florida by
								Dana “That EV Girl” Sinno, a US Air Force Veteran, with a
								passion for learning, teaching and inspiring others about the
								benefits of owning Electric Vehicles. While searching for EV’s
								she found that dealer sites rarely even had an electric option
								in their search. Most of the sites lacked the ability to provide
								the information needed to make a decision like: battery size,
								battery range, number of electric motors, etc. So, she saw a
								need and then went on to create a better car-shopping experience
								using technology and data analytics that could give someone a
								level of confidence with their research and decision to “go
								electric” */}
								allEVehicles was founded in 2020, in Jacksonville, Florida by Dana “That EV Girl” Sinno, a US Air Force Veteran, with a passion for learning, teaching and inspiring others about the benefits of owning Electric Vehicles. While searching for Used EVs, she found that dealer sites rarely even had an electric option in their search.  Most of the sites lacked the ability to provide the information needed to make a decision like: battery size, battery range, number of electric motors, etc. So, she saw a need and then went on to create a better car-shopping experience using technology and data analytics that could give someone a level of confidence with their research and decision to “go electric”.
							</p>
							{/* <p>
                Duis Aute Irure Dolor In Reprehenderit In Voluptate Velit Esse
                Cillum Dolore Eu Fugiat Null Duis Aute Irure Dolor In
                Reprehenderit In Oluptate Duis Aute Irure Dolor In Reprehenderit
                In Voluptate Velit Esse Cillum Dolore Eu Fugiat Null Duis Aute
                Irure Dolor In Reprehenderit In Oluptate{" "}
              </p> */}
						</div>
					</div>
					<div className="col-lg-6"></div>
				</div>
			</section>
			{/* vision sec ends here */}
			{/* mission starts here */}
			<section className="mission">
				<div className="container">
					<div className="row">
						<div className="col-lg-6">
							<div className="img-wrappper">
								<figure>
									<img src={mission_img} className="img-fluid" />
									<img src={dots_img} className="dots" />
								</figure>
							</div>
						</div>
						<div className="col-lg-6 align-self-center">
							<div className="content-wrapper">
								<h2>Our Mission</h2>
								<p>
									{/* We believe that the emerging EV sector needed a dedicated site
									where consumers could learn about and better understand the
									positive effects of EV Ownership. We connect buyers/sellers in
									a trusted and secure way. */}
									We believe that the emerging EV sector needed a dedicated site where consumers could learn about and better understand the positive effects of EV Ownership. We connect buyers/sellers in a trusted and secure way. 
								</p>
								{/* <p>
                  Duis aute irure dolor in reprehenderit in voluptate velit esse
                  cillum dolore eu fugiat null Duis aute irure dolor in
                  reprehenderit in oluptate Duis aute irure dolor in
                  reprehenderit in voluptate velit esse cillum dolore eu fugiat
                  null Duis aute irure dolor in reprehenderit in oluptate
                </p> */}
								<button className="btn">Contact Us</button>
							</div>
						</div>
					</div>
				</div>
				<div className="mission-line">
					<figure>
						<img src={mission_line} />
					</figure>
				</div>
			</section>
			{/* mission ends here */}
			{/* vision section start here */}
			<section className="mission ourvision_sec">
				<div className="container">
					<div className="row">
						<div className="col-lg-6 align-self-center">
							<div className="content-wrapper">
								<h2>OUR VISION</h2>
								<p>
									allEVehicles.com is a digital All Electric Vehicle marketplace
									that makes car buying simple, safe and fun. Shoppers can click
									through live inventory and see custom-tailored photos of the
									cars they’re interested in; and can contact dealers with a few
									clicks. Each listing gives a detailed description, features,
									and CARFAX report, allowing shoppers to make educated
									decisions about their EV purchase.
								</p>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="img-wrappper">
								<figure>
									<img src={mission_img} className="img-fluid" />
									<img src={dots_img} className="dots" />
								</figure>
							</div>
						</div>
					</div>
				</div>
				<div className="mission-line">
					<figure>
						<img src={mission_line} />
					</figure>
				</div>
			</section>
			{/* vision section start here */}

			{/* contact us starts here */}
			{/* <section className="contact-us">
        <div className="container">
          <div className="row">
            <div className="col-lg-3"></div>
            <div className="col-lg-6">
              <div className="contact-form-wrapper">
                <div className="contact-heading-wrapper">
                  <h2>CONTACT US</h2>
                </div>
                <div className="form-wrapper">
                  <form>
                    <div className="form-group">
                      <select className="form-control">
                        <option value="I Want To Buy" disabled selected>
                          I Want To Buy
                        </option>
                      </select>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-6">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="First Name"
                        />
                      </div>
                      <div className="col-lg-6">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Last Name"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-6">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Email"
                        />
                      </div>
                      <div className="col-lg-6">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Phone"
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <textarea
                        className="form-control"
                        rows="5"
                        cols="10"
                        placeholder="How We Can We Help You"
                      ></textarea>
                    </div>
                    <div className="form-group">
                      <ReCAPTCHA
                        sitekey="6LeCaCohAAAAAKRZFr_EaPQdGBsGuCCIhYTn2uwe"
                        onChange={onChange}
                      />
                      ,
                    </div>
                    <div className="form-group">
                      <button className="btn form-control">Submit</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-lg-3"></div>
          </div>
        </div>
      </section> */}
			{/* contact us ends here */}
			<ContactUs />
			<Footer />
		</>
	);
};

export default About;
