import React from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { DelarImg } from "../../constant";
import Sidebar from "./Sidebar";
import "../../Assets/Css/Profile.css";
import { Link } from "react-router-dom";

const ChangePassword = () => {
  return (
    <>
      <Header />

      <section className="profile_Sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <Sidebar />
            </div>
            <div className="col-lg-9">
              <div className="right-side">
                <div className="title">
                  <h3>Change Password</h3>
                </div>
                <div className="editprofile_sec">
                  <div className="row">
                    <div className="col-md-6">
                      <div class="mb-3">
                        <label for="inputcupassword" class="form-label">
                          Current Password
                        </label>
                        <input
                          type="password"
                          class="form-control"
                          id="inputcupassword"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div class="mb-3">
                        <label for="inputnpassword" class="form-label">
                          New Password
                        </label>
                        <input
                          type="password"
                          class="form-control"
                          id="inputnpassword"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div class="mb-3">
                        <label for="inputcpassword" class="form-label">
                          Confirm Password
                        </label>
                        <input
                          type="password"
                          class="form-control"
                          id="inputcpassword"
                        />
                      </div>
                    </div>
                    <div className="col-md-6 align-self-center mt-2">
                      <div className="button-group">
                        <Link to="/profile" className="btn ">
                          Save Changes
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default ChangePassword;
