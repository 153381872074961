import React from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import TopSlider from "../Home/TopSlider";
import Grid from "../../Assets/img/grid.png";
import "../../Assets/Css/listing.css";
import "../../Assets/Css/car-detail.css";
import Slider from "react-slick";

// categories Images
import Cat1 from "../../Assets/img/cat1.png";
import Cat2 from "../../Assets/img/cat2.png";
import Cat3 from "../../Assets/img/cat3.png";
import Cat4 from "../../Assets/img/cat4.png";
import Cat5 from "../../Assets/img/cat5.png";
import Cat6 from "../../Assets/img/cat6.png";
import Cat7 from "../../Assets/img/cat7.png";
import Cat8 from "../../Assets/img/cat9.png";
import Cat9 from "../../Assets/img/cat10.png";
import Cat10 from "../../Assets/img/cat11.png";
import Cat11 from "../../Assets/img/cat12.png";
import Cat12 from "../../Assets/img/cat13.png";
import Cat13 from "../../Assets/img/cat14.png";
import banner from "../../Assets/img/banner.png";
import about1 from "../../Assets/img/about1.png";
import car1 from "../../Assets/img/car1.png";
import car2 from "../../Assets/img/car2.png";
import icon from "../../Assets/img/icon.png";
import service from "../../Assets/img/services1.png";
import services2 from "../../Assets/img/services2.png";
import services3 from "../../Assets/img/services3.png";
import services4 from "../../Assets/img/services4.png";
import feature1 from "../../Assets/img/feature1.png";
import meter from "../../Assets/img/meter.svg";
import meter1 from "../../Assets/img/meter1.svg";
import meter2 from "../../Assets/img/meter2.svg";
import meter3 from "../../Assets/img/meter3.svg";
import add from "../../Assets/img/add.png";
import feature2 from "../../Assets/img/feature2.png";
import feature3 from "../../Assets/img/feature3.png";
import feature4 from "../../Assets/img/feature4.png";
import feature5 from "../../Assets/img/feature5.png";
import feature6 from "../../Assets/img/feature6.png";
import { Link, useNavigate } from "react-router-dom";

const Listing = () => {
  const navigate = useNavigate();
  const HandlePageChange = () => {
    navigate("/cardetailpage");
  };
  const categories = [
    {
      catImg: Cat1,
      catName: "New",
    },
    {
      catImg: Cat2,
      catName: "Used",
    },
    {
      catImg: Cat3,
      catName: "Electric",
    },
    {
      catImg: Cat4,
      catName: "Hybrid",
    },
    {
      catImg: Cat5,
      catName: "Price",
    },
    {
      catImg: Cat6,
      catName: "Year",
    },
    {
      catImg: Cat7,
      catName: "Range",
    },
    {
      catImg: Cat8,
      catName: "Mileage",
    },
    {
      catImg: Cat9,
      catName: "Battery",
    },
    {
      catImg: Cat10,
      catName: "color",
    },
    {
      catImg: Cat11,
      catName: "Speed",
    },
    {
      catImg: Cat12,
      catName: "Motor",
    },
    {
      catImg: Cat13,
      catName: "Drivetrain",
    },
  ];
  const listingImg = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };
  return (
    <>
      <Header />

      <TopSlider />

      {/* Listing Sec Start Here */}
      <section className="lisiting">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <div className="filter">
                <div className="heading">
                  <h4>FILTER BY:</h4>
                  {/* <p>
                    <i class="fa fa-map-marker" aria-hidden="true"></i> Your
                    Location
                  </p> */}
                </div>
                <ul className="categories">
                  {/* {categories?.map((cat, index) => {
										return (
											<li key={index}>
												<span>{cat?.catName}</span>
												<img src={cat?.catImg} alt="" className="img-fluid" />
											</li>
										);
									})} */}
                  <li>
                    {/* <span>Your Location</span> */}
                    <div class="dropdown">
                      <button
                        class="btn btn-secondary dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Your Location
                      </button>
                      <div
                        class="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <a class="dropdown-item" href="#">
                          Lorem
                        </a>
                        <a class="dropdown-item" href="#">
                          Lorem
                        </a>
                        <a class="dropdown-item" href="#">
                          Lorem
                        </a>
                      </div>
                    </div>
					<i class="fa fa-map-marker" aria-hidden="true"></i>
                  </li>
                  <li>
                    <div>
                      <span>EV Type</span>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="exampleRadios"
                          id="inputall"
                          value="option1"
                          checked
                        />
                        <label class="form-check-label" for="inputall">
                          All
                        </label>
                      </div>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="exampleRadios"
                          id="inputev"
                          value="option1"
                        />
                        <label class="form-check-label" for="inputev">
                          EV
                        </label>
                      </div>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="exampleRadios"
                          id="inputhybrid"
                          value="option1"
                        />
                        <label class="form-check-label" for="inputhybrid">
                          Hybrid
                        </label>
                      </div>
                    </div>
                    <img src={Cat1} alt="" className="img-fluid" />
                  </li>
                  <li>
                    <div>
                      <span>EV Seller</span>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="exampleRadios"
                          id="inputall2"
                          value="option1"
                          checked
                        />
                        <label class="form-check-label" for="inputall2">
                          All
                        </label>
                      </div>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="exampleRadios"
                          id="inputdealer"
                          value="option1"
                        />
                        <label class="form-check-label" for="inputdealer">
                          Dealer
                        </label>
                      </div>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="exampleRadios"
                          id="inputprisell"
                          value="option1"
                        />
                        <label class="form-check-label" for="inputprisell">
                          Private Seller
                        </label>
                      </div>
                    </div>
                    <img src={Cat1} alt="" className="img-fluid" />
                  </li>
                  <li>
                    <span>New</span>
                    <img src={Cat1} alt="" className="img-fluid" />
                  </li>
                  <li>
                    <span>Used</span>
                    <img src={Cat2} alt="" className="img-fluid" />
                  </li>
                  <li>
                    <span>Electric</span>
                    <img src={Cat3} alt="" className="img-fluid" />
                  </li>
                  <li>
                    <span>Hybrid</span>
                    <img src={Cat4} alt="" className="img-fluid" />
                  </li>
                  <li>
                    {/* <span>Make</span> */}
                    <div class="dropdown">
                      <button
                        class="btn btn-secondary dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Make
                      </button>
                      <div
                        class="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <a class="dropdown-item" href="#">
                          Lorem
                        </a>
                        <a class="dropdown-item" href="#">
                          Lorem
                        </a>
                        <a class="dropdown-item" href="#">
                          Lorem
                        </a>
                      </div>
                    </div>
                    {/* <img src={Cat4} alt="" className="img-fluid" /> */}
                  </li>
                  <li>
                    {/* <span>Make</span> */}
                    <div class="dropdown">
                      <button
                        class="btn btn-secondary dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Model
                      </button>
                      <div
                        class="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <a class="dropdown-item" href="#">
                          All
                        </a>
                        <a class="dropdown-item" href="#">
                          Lorem
                        </a>
                        <a class="dropdown-item" href="#">
                          Lorem
                        </a>
                      </div>
                    </div>
                    {/* <img src={Cat4} alt="" className="img-fluid" /> */}
                  </li>
                  <li>
                    <div>
                      <span>Price</span>
                      <div class="form-group">
                        <input
                          type="email"
                          class="form-control"
                          id="inputprice1"
                          aria-describedby="emailHelp"
                          placeholder="Lowest "
                        />
                        <input
                          type="email"
                          class="form-control"
                          id="inputprice1"
                          aria-describedby="emailHelp"
                          placeholder="Highest "
                        />
                      </div>
                    </div>
                    <img src={Cat5} alt="" className="img-fluid" />
                  </li>
                  <li>
                    <div>
                      <span>Year</span>
                      <div class="form-group">
                        <input
                          type="email"
                          class="form-control"
                          id="inputyear1"
                          aria-describedby="emailHelp"
                          placeholder="From"
                        />
                        <input
                          type="email"
                          class="form-control"
                          id="inputyear2"
                          aria-describedby="emailHelp"
                          placeholder="To"
                        />
                      </div>
                    </div>
                    <img src={Cat6} alt="" className="img-fluid" />
                  </li>
                  <li>
                    <div>
                      <span>Range</span>
                      <div class="form-group">
                        <input
                          type="email"
                          class="form-control"
                          id="inputrange1"
                          aria-describedby="emailHelp"
                          placeholder="Minimum  "
                        />
                        <input
                          type="email"
                          class="form-control"
                          id="inputrange2"
                          aria-describedby="emailHelp"
                          placeholder="Maximum  "
                        />
                      </div>
                    </div>
                    <img src={Cat7} alt="" className="img-fluid" />
                  </li>
                  <li>
                    <div>
                      <span>Mileage</span>
                      <div class="form-group">
                        <input
                          type="email"
                          class="form-control"
                          id="inputmileage1"
                          aria-describedby="emailHelp"
                          placeholder="Lowest"
                        />
                        <input
                          type="email"
                          class="form-control"
                          id="inputmileage2"
                          aria-describedby="emailHelp"
                          placeholder="Highest"
                        />
                      </div>
                    </div>
                    <img src={Cat8} alt="" className="img-fluid" />
                  </li>
                  <li>
                    <div>
                      <span>Battery Power</span>
                      <div class="form-group">
                        <input
                          type="email"
                          class="form-control"
                          id="inputbatpower1"
                          aria-describedby="emailHelp"
                          placeholder="Min"
                        />
                        <input
                          type="email"
                          class="form-control"
                          id="inputbatpower2"
                          aria-describedby="emailHelp"
                          placeholder="Max"
                        />
                      </div>
                    </div>
                    <img src={Cat9} alt="" className="img-fluid" />
                  </li>
                  <li>
                    {/* <span>color</span> */}
                    <div class="dropdown">
                      <button
                        class="btn btn-secondary dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Exterior color
                      </button>
                      <div
                        class="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <a class="dropdown-item" href="#">
                          Red
                        </a>
                        <a class="dropdown-item" href="#">
                          Black
                        </a>
                        <a class="dropdown-item" href="#">
                          White
                        </a>
                      </div>
                    </div>
                    <img src={Cat10} alt="" className="img-fluid" />
                  </li>
                  <li>
                    <span>Speed</span>
                    <img src={Cat11} alt="" className="img-fluid" />
                  </li>
                  <li>
                    <span>Motor</span>
                    <img src={Cat12} alt="" className="img-fluid" />
                  </li>
                  <li>
                    <div>
                      <span>Drivetrain</span>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="exampleRadios"
                          id="inputall3"
                          value="option1"
                          checked
                        />
                        <label class="form-check-label" for="inputall3">
                          All
                        </label>
                      </div>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="exampleRadios"
                          id="inputrwd"
                          value="option1"
                        />
                        <label class="form-check-label" for="inputrwd">
                          RWD
                        </label>
                      </div>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="exampleRadios"
                          id="inputfwd"
                          value="option1"
                        />
                        <label class="form-check-label" for="inputfwd">
                          FWD
                        </label>
                      </div>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="exampleRadios"
                          id="inputawd"
                          value="option1"
                        />
                        <label class="form-check-label" for="inputawd">
                          AWD
                        </label>
                      </div>
                    </div>
                    <img src={Cat13} alt="" className="img-fluid" />
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-9">
              <div className="view">
                <div className="form-group">
                  <label>List view</label>
                  <img src={Grid} className="img-fluid" />
                </div>
              </div>
              {/* Featured section  */}

              <section className="feature-sec">
                <div className="container">
                  <div className="row wow animate__animated animate__slideInLeft">
                    <div className="col-md-4">
                      <div className="featured-all">
                        <div className="f-img">
                          <Link to="/cardetailpage">
                            <figure>
                              <Slider {...listingImg}>
                                <img src={feature1}></img>
                                <img src={feature1}></img>
                                <img src={feature1}></img>
                              </Slider>
                            </figure>
                          </Link>
                          {/* <div className="actions">
                            <a href="#">
                              <i class="fa fa-heart" aria-hidden="true"></i>
                            </a>
                            <a href="#">
                              <i class="fa fa-eye-slash" aria-hidden="true"></i>
                            </a>
                          </div> */}
                        </div>
                        <div className="feature-head">
                          <h2>
                            <span>$</span>21,898
                          </h2>
                        </div>

                        <div className="feature-normal-head">
                          <h3>2017 CHEVROLET VOLT LT</h3>
                        </div>

                        <div className="feature-icon-head">
                          <span>
                            <img src={meter1}></img> 18,234 miles
                          </span>
                          <span>
                            <img src={meter2}></img> 267 Mi max range
                          </span>
                        </div>
                        <div className="feature-iiimmgg">
                          <span>
                            <img src={meter3}></img> Longview, TX
                          </span>
                        </div>

                        <div className="checkup">
                          <button className="btn" onClick={HandlePageChange}>
                            View more
                          </button>
                        </div>

                        <div className="new-listing">
                          <h5>New listing</h5>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="featured-all">
                        <div className="f-img">
                          <Link to="/cardetailpage">
                            <figure>
                              <Slider {...listingImg}>
                                <img src={feature5}></img>
                                <img src={feature5}></img>
                                <img src={feature5}></img>
                              </Slider>
                            </figure>
                          </Link>
                          {/* <div className="actions">
                            <a href="#">
                              <i class="fa fa-heart" aria-hidden="true"></i>
                            </a>
                            <a href="#">
                              <i class="fa fa-eye-slash" aria-hidden="true"></i>
                            </a>
                          </div> */}
                        </div>
                        <div className="feature-head">
                          <h2>
                            <span>$</span>21,898
                          </h2>
                          {/* <p>
                            <i class="fa fa-car" aria-hidden="true"></i> For
                            price | $566 below market
                          </p> */}
                        </div>

                        <div className="feature-normal-head">
                          <h3>2017 CHEVROLET VOLT LT</h3>
                        </div>

                        <div className="feature-icon-head">
                          <span>
                            <img src={meter1}></img> 18,234 miles
                          </span>
                          <span>
                            <img src={meter2}></img> 267 Mi max range
                          </span>
                        </div>
                        <div className="feature-iiimmgg">
                          <span>
                            <img src={meter3}></img> Longview, TX
                          </span>
                        </div>

                        <div className="checkup">
                          <button className="btn" onClick={HandlePageChange}>
                            View more
                          </button>
                        </div>

                        <div className="new-listing">
                          <h5>New listing</h5>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="featured-all">
                        <div className="f-img">
                          <Link to="/cardetailpage">
                            <figure>
                              <Slider {...listingImg}>
                                <img src={feature6}></img>
                                <img src={feature6}></img>
                                <img src={feature6}></img>
                              </Slider>
                            </figure>
                          </Link>
                          {/* <div className="actions">
                            <a href="#">
                              <i class="fa fa-heart" aria-hidden="true"></i>
                            </a>
                            <a href="#">
                              <i class="fa fa-eye-slash" aria-hidden="true"></i>
                            </a>
                          </div> */}
                        </div>
                        <div className="feature-head">
                          <h2>
                            <span>$</span>21,898
                          </h2>
                          {/* <p>
                            <i class="fa fa-car" aria-hidden="true"></i> For
                            price | $566 below market
                          </p> */}
                        </div>

                        <div className="feature-normal-head">
                          <h3>2017 CHEVROLET VOLT LT</h3>
                        </div>

                        <div className="feature-icon-head">
                          <span>
                            <img src={meter1}></img> 18,234 miles
                          </span>
                          <span>
                            <img src={meter2}></img> 267 Mi max range
                          </span>
                        </div>
                        <div className="feature-iiimmgg">
                          <span>
                            <img src={meter3}></img> Longview, TX
                          </span>
                        </div>

                        <div className="checkup">
                          <button className="btn" onClick={HandlePageChange}>
                            View more
                          </button>
                        </div>

                        <div className="new-listing">
                          <h5>New listing</h5>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="featured-all">
                        <div className="f-img">
                          <Link to="/cardetailpage">
                            <figure>
                              <Slider {...listingImg}>
                                <img src={feature2}></img>
                                <img src={feature2}></img>
                                <img src={feature2}></img>
                              </Slider>
                            </figure>
                          </Link>
                          {/* <div className="actions">
                            <a href="#">
                              <i class="fa fa-heart" aria-hidden="true"></i>
                            </a>
                            <a href="#">
                              <i class="fa fa-eye-slash" aria-hidden="true"></i>
                            </a>
                          </div> */}
                        </div>
                        <div className="feature-head">
                          <h2>
                            <span>$</span>21,898
                          </h2>
                          {/* <p>
                            <i class="fa fa-car" aria-hidden="true"></i> For
                            price | $566 below market
                          </p> */}
                        </div>

                        <div className="feature-normal-head">
                          <h3>2017 CHEVROLET VOLT LT</h3>
                        </div>

                        <div className="feature-icon-head">
                          <span>
                            <img src={meter1}></img> 18,234 miles
                          </span>
                          <span>
                            <img src={meter2}></img> 267 Mi max range
                          </span>
                        </div>
                        <div className="feature-iiimmgg">
                          <span>
                            <img src={meter3}></img> Longview, TX
                          </span>
                        </div>

                        <div className="checkup">
                          <button className="btn" onClick={HandlePageChange}>
                            View more
                          </button>
                        </div>

                        <div className="new-listing">
                          <h5>New listing</h5>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="featured-all">
                        <div className="f-img">
                          <Link to="/cardetailpage">
                            <figure>
                              <Slider {...listingImg}>
                                <img src={feature3}></img>
                                <img src={feature3}></img>
                                <img src={feature3}></img>
                              </Slider>
                            </figure>
                          </Link>
                          {/* <div className="actions">
                            <a href="#">
                              <i class="fa fa-heart" aria-hidden="true"></i>
                            </a>
                            <a href="#">
                              <i class="fa fa-eye-slash" aria-hidden="true"></i>
                            </a>
                          </div> */}
                        </div>
                        <div className="feature-head">
                          <h2>
                            <span>$</span>21,898
                          </h2>
                          {/* <p>
                            <i class="fa fa-car" aria-hidden="true"></i> For
                            price | $566 below market
                          </p> */}
                        </div>

                        <div className="feature-normal-head">
                          <h3>2017 CHEVROLET VOLT LT</h3>
                        </div>

                        <div className="feature-icon-head">
                          <span>
                            <img src={meter1}></img> 18,234 miles
                          </span>
                          <span>
                            <img src={meter2}></img> 267 Mi max range
                          </span>
                        </div>
                        <div className="feature-iiimmgg">
                          <span>
                            <img src={meter3}></img> Longview, TX
                          </span>
                        </div>

                        <div className="checkup">
                          <button className="btn" onClick={HandlePageChange}>
                            View more
                          </button>
                        </div>

                        <div className="new-listing">
                          <h5>New listing</h5>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="featured-all">
                        <div className="f-img">
                          <Link to="/cardetailpage">
                            <figure>
                              <Slider {...listingImg}>
                                <img src={feature1}></img>
                                <img src={feature1}></img>
                                <img src={feature1}></img>
                              </Slider>
                            </figure>
                          </Link>
                          {/* <div className="actions">
                            <a href="#">
                              <i class="fa fa-heart" aria-hidden="true"></i>
                            </a>
                            <a href="#">
                              <i class="fa fa-eye-slash" aria-hidden="true"></i>
                            </a>
                          </div> */}
                        </div>
                        <div className="feature-head">
                          <h2>
                            <span>$</span>21,898
                          </h2>
                          {/* <p>
                            <i class="fa fa-car" aria-hidden="true"></i> For
                            price | $566 below market
                          </p> */}
                        </div>

                        <div className="feature-normal-head">
                          <h3>2017 CHEVROLET VOLT LT</h3>
                        </div>

                        <div className="feature-icon-head">
                          <span>
                            <img src={meter1}></img> 18,234 miles
                          </span>
                          <span>
                            <img src={meter2}></img> 267 Mi max range
                          </span>
                        </div>
                        <div className="feature-iiimmgg">
                          <span>
                            <img src={meter3}></img> Longview, TX
                          </span>
                        </div>

                        <div className="checkup">
                          <button className="btn" onClick={HandlePageChange}>
                            View more
                          </button>
                        </div>

                        <div className="new-listing">
                          <h5>New listing</h5>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="featured-all">
                        <div className="f-img">
                          <Link to="/cardetailpage">
                            <figure>
                              <Slider {...listingImg}>
                                <img src={feature4}></img>
                                <img src={feature4}></img>
                                <img src={feature4}></img>
                              </Slider>
                            </figure>
                          </Link>
                          {/* <div className="actions">
                            <a href="#">
                              <i class="fa fa-heart" aria-hidden="true"></i>
                            </a>
                            <a href="#">
                              <i class="fa fa-eye-slash" aria-hidden="true"></i>
                            </a>
                          </div> */}
                        </div>
                        <div className="feature-head">
                          <h2>
                            <span>$</span>21,898
                          </h2>
                          {/* <p>
                            <i class="fa fa-car" aria-hidden="true"></i> For
                            price | $566 below market
                          </p> */}
                        </div>

                        <div className="feature-normal-head">
                          <h3>2017 CHEVROLET VOLT LT</h3>
                        </div>

                        <div className="feature-icon-head">
                          <span>
                            <img src={meter1}></img> 18,234 miles
                          </span>
                          <span>
                            <img src={meter2}></img> 267 Mi max range
                          </span>
                        </div>
                        <div className="feature-iiimmgg">
                          <span>
                            <img src={meter3}></img> Longview, TX
                          </span>
                        </div>

                        <div className="checkup">
                          <button className="btn" onClick={HandlePageChange}>
                            View more
                          </button>
                        </div>

                        <div className="new-listing">
                          <h5>New listing</h5>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="featured-all">
                        <div className="f-img">
                          <Link to="/cardetailpage">
                            <figure>
                              <Slider {...listingImg}>
                                <img src={feature5}></img>
                                <img src={feature5}></img>
                                <img src={feature5}></img>
                              </Slider>
                            </figure>
                          </Link>
                          {/* <div className="actions">
                            <a href="#">
                              <i class="fa fa-heart" aria-hidden="true"></i>
                            </a>
                            <a href="#">
                              <i class="fa fa-eye-slash" aria-hidden="true"></i>
                            </a>
                          </div> */}
                        </div>
                        <div className="feature-head">
                          <h2>
                            <span>$</span>21,898
                          </h2>
                          {/* <p>
                            <i class="fa fa-car" aria-hidden="true"></i> For
                            price | $566 below market
                          </p> */}
                        </div>

                        <div className="feature-normal-head">
                          <h3>2017 CHEVROLET VOLT LT</h3>
                        </div>

                        <div className="feature-icon-head">
                          <span>
                            <img src={meter1}></img> 18,234 miles
                          </span>
                          <span>
                            <img src={meter2}></img> 267 Mi max range
                          </span>
                        </div>
                        <div className="feature-iiimmgg">
                          <span>
                            <img src={meter3}></img> Longview, TX
                          </span>
                        </div>

                        <div className="checkup">
                          <button className="btn" onClick={HandlePageChange}>
                            View more
                          </button>
                        </div>

                        <div className="new-listing">
                          <h5>New listing</h5>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="featured-all">
                        <div className="f-img">
                          <Link to="/cardetailpage">
                            <figure>
                              <Slider {...listingImg}>
                                <img src={feature6}></img>
                                <img src={feature6}></img>
                                <img src={feature6}></img>
                              </Slider>
                            </figure>
                          </Link>
                          {/* <div className="actions">
                            <a href="#">
                              <i class="fa fa-heart" aria-hidden="true"></i>
                            </a>
                            <a href="#">
                              <i class="fa fa-eye-slash" aria-hidden="true"></i>
                            </a>
                          </div> */}
                        </div>
                        <div className="feature-head">
                          <h2>
                            <span>$</span>21,898
                          </h2>
                          {/* <p>
                            <i class="fa fa-car" aria-hidden="true"></i> For
                            price | $566 below market
                          </p> */}
                        </div>

                        <div className="feature-normal-head">
                          <h3>2017 CHEVROLET VOLT LT</h3>
                        </div>

                        <div className="feature-icon-head">
                          <span>
                            <img src={meter1}></img> 18,234 miles
                          </span>
                          <span>
                            <img src={meter2}></img> 267 Mi max range
                          </span>
                        </div>
                        <div className="feature-iiimmgg">
                          <span>
                            <img src={meter3}></img> Longview, TX
                          </span>
                        </div>

                        <div className="checkup">
                          <button className="btn" onClick={HandlePageChange}>
                            View more
                          </button>
                        </div>

                        <div className="new-listing">
                          <h5>New listing</h5>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="featured-all">
                        <div className="f-img">
                          <Link to="/cardetailpage">
                            <figure>
                              <Slider {...listingImg}>
                                <img src={feature2}></img>
                                <img src={feature2}></img>
                                <img src={feature2}></img>
                              </Slider>
                            </figure>
                          </Link>
                          {/* <div className="actions">
                            <a href="#">
                              <i class="fa fa-heart" aria-hidden="true"></i>
                            </a>
                            <a href="#">
                              <i class="fa fa-eye-slash" aria-hidden="true"></i>
                            </a>
                          </div> */}
                        </div>
                        <div className="feature-head">
                          <h2>
                            <span>$</span>21,898
                          </h2>
                          {/* <p>
                            <i class="fa fa-car" aria-hidden="true"></i> For
                            price | $566 below market
                          </p> */}
                        </div>

                        <div className="feature-normal-head">
                          <h3>2017 CHEVROLET VOLT LT</h3>
                        </div>

                        <div className="feature-icon-head">
                          <span>
                            <img src={meter1}></img> 18,234 miles
                          </span>
                          <span>
                            <img src={meter2}></img> 267 Mi max range
                          </span>
                        </div>
                        <div className="feature-iiimmgg">
                          <span>
                            <img src={meter3}></img> Longview, TX
                          </span>
                        </div>

                        <div className="checkup">
                          <button className="btn" onClick={HandlePageChange}>
                            View more
                          </button>
                        </div>

                        <div className="new-listing">
                          <h5>New listing</h5>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="featured-all">
                        <div className="f-img">
                          <Link to="/cardetailpage">
                            <figure>
                              <Slider {...listingImg}>
                                <img src={feature3}></img>
                                <img src={feature3}></img>
                                <img src={feature3}></img>
                              </Slider>
                            </figure>
                          </Link>
                          {/* <div className="actions">
                            <a href="#">
                              <i class="fa fa-heart" aria-hidden="true"></i>
                            </a>
                            <a href="#">
                              <i class="fa fa-eye-slash" aria-hidden="true"></i>
                            </a>
                          </div> */}
                        </div>
                        <div className="feature-head">
                          <h2>
                            <span>$</span>21,898
                          </h2>
                          {/* <p>
                            <i class="fa fa-car" aria-hidden="true"></i> For
                            price | $566 below market
                          </p> */}
                        </div>

                        <div className="feature-normal-head">
                          <h3>2017 CHEVROLET VOLT LT</h3>
                        </div>

                        <div className="feature-icon-head">
                          <span>
                            <img src={meter1}></img> 18,234 miles
                          </span>
                          <span>
                            <img src={meter2}></img> 267 Mi max range
                          </span>
                        </div>
                        <div className="feature-iiimmgg">
                          <span>
                            <img src={meter3}></img> Longview, TX
                          </span>
                        </div>

                        <div className="checkup">
                          <button className="btn" onClick={HandlePageChange}>
                            View more
                          </button>
                        </div>

                        <div className="new-listing">
                          <h5>New listing</h5>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="featured-all">
                        <div className="f-img">
                          <Link to="/cardetailpage">
                            <figure>
                              <Slider {...listingImg}>
                                <img src={feature1}></img>
                                <img src={feature1}></img>
                                <img src={feature1}></img>
                              </Slider>
                            </figure>
                          </Link>
                          {/* <div className="actions">
                            <a href="#">
                              <i class="fa fa-heart" aria-hidden="true"></i>
                            </a>
                            <a href="#">
                              <i class="fa fa-eye-slash" aria-hidden="true"></i>
                            </a>
                          </div> */}
                        </div>
                        <div className="feature-head">
                          <h2>
                            <span>$</span>21,898
                          </h2>
                          {/* <p>
                            <i class="fa fa-car" aria-hidden="true"></i> For
                            price | $566 below market
                          </p> */}
                        </div>

                        <div className="feature-normal-head">
                          <h3>2017 CHEVROLET VOLT LT</h3>
                        </div>

                        <div className="feature-icon-head">
                          <span>
                            <img src={meter1}></img> 18,234 miles
                          </span>
                          <span>
                            <img src={meter2}></img> 267 Mi max range
                          </span>
                        </div>
                        <div className="feature-iiimmgg">
                          <span>
                            <img src={meter3}></img> Longview, TX
                          </span>
                        </div>

                        <div className="checkup">
                          <button className="btn" onClick={HandlePageChange}>
                            View more
                          </button>
                        </div>

                        <div className="new-listing">
                          <h5>New listing</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <div className="pagination">
                <div className="pageCount">
                  <button className="btn3">1</button>
                  <button className="btn1">2</button>
                  <button className="btn1">3</button>
                  <button className="btn1">4</button>
                  <button className="btn1">5</button>
                  <button className="btn1">10</button>
                  <button className="btn2">
                    Next <i class="fa fa-angle-right" aria-hidden="true"></i>
                  </button>
                  <i class="bi bi-chevron-right"></i>
                </div>
              </div>
              {/* End of Featured section  */}
            </div>
          </div>
        </div>
      </section>
      {/* Listing Sec End Here */}

      <Footer />
    </>
  );
};

export default Listing;
