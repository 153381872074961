import React from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import SaveAddress from "./SaveAddress";
import Addetails from "./Adddetails";
import OrderSummary from "./OrderSummary";
import { useNavigate } from "react-router-dom";

const Checkout = () => {
	return (
		<>
			{/* Header */}
			<Header />
			{/* Header */}
			{/* Checkout Page */}
			<section className="checkout">
				<div className="container">
					<form action="thank-you.php">
						<div className="row">
							<div className="col-lg-6">
								<div className="first-top-bar">
									<h3>Shipment Details</h3>
								</div>

								<div className="saved-address">
									<div className="title">
										<h5>Use Saved Addresses</h5>
									</div>

									<SaveAddress />
								</div>
								<Addetails />
							</div>
							<div className="col-lg-1"></div>
							<div className="col-lg-5">
								<OrderSummary />
							</div>
						</div>
					</form>
				</div>
			</section>

			<Footer />
		</>
	);
};

export default Checkout;
