import React from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import "../../Assets/Css/privacy.css";

const CopyPolicy = () => {
	return (
		<>
			<Header />
			<section className="privacy-banner">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="content-wrapper">
								<h2>Copyright Policy</h2>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="privacy-content-wrapper">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="content-wrapper mb-0">
								<h4 className="mb-0">ALLEVEHICLES COPYRIGHT POLICY</h4>
							</div>
							<div className="content-wrapper mt-1">
								<p>
									Reporting Claims of Copyright Infringement
									<br /> We take claims of copyright infringement seriously. We
									will respond to notices of alleged copyright infringement that
									comply with applicable law. If you believe any materials
									accessible on or from this site (the "Website") infringe your
									copyright, you may request removal of those materials (or
									access to them) from the Website by submitting written
									notification to our copyright agent designated below. In
									accordance with the Online Copyright Infringement Liability
									Limitation Act of the Digital Millennium Copyright Act (17
									U.S.C. § 512) ("DMCA"), the written notice (the "DMCA Notice")
									must include substantially the following:
									<br /> Your physical or electronic signature. <br />
									Identification of the copyrighted work you believe to have
									been infringed or, if the claim involves multiple works on the
									Website, a representative list of such works.
									<br />
									Identification of the material you believe to be infringing in
									a sufficiently precise manner to allow us to locate that
									material.
									<br />
									Adequate information by which we can contact you (including
									your name, postal address, telephone number, and, if
									available, email address).
									<br />
									A statement that you have a good faith belief that use of the
									copyrighted material is not authorized by the copyright owner,
									its agent, or the law.
									<br />
									A statement that the information in the written notice is
									accurate.
									<br />
									A statement, under penalty of perjury, that you are authorized
									to act on behalf of the copyright owner.
									<br />
									Our designated copyright agent to receive DMCA Notices is:
									<br />
									Micheal Svoboda
									<br />
									allEVehicles, LLC d/b/a allEVehicles.com
									<br />
									112 Bracken Ct., St Johns, FL 32259
									<br />
									319-213-4191
									<br />
									msvoboda@allEVehicles.com
									<br />
									If you fail to comply with all of the requirements of Section
									512(c)(3) of the DMCA, your DMCA Notice may not be effective.
									<br />
									Please be aware that if you knowingly materially misrepresent
									that material or activity on the Website is infringing your
									copyright, you may be held liable for damages (including costs
									and attorneys' fees) under Section 512(f) of the DMCA.
								</p>
								<h4 className="my-4">Counter-Notification Procedures</h4>
								<p>
									If you believe that material you posted on the Website was
									removed or access to it was disabled by mistake or
									misidentification, you may file a counter-notification with us
									(a "Counter-Notice") by submitting written notification to our
									copyright agent designated above/below. Pursuant to the DMCA,
									the Counter-Notice must include substantially the following:
									<br />
									Your physical or electronic signature.
									<br />
									An identification of the material that has been removed or to
									which access has been disabled and the location at which the
									material appeared before it was removed or access disabled.
									<br />
									Adequate information by which we can contact you (including
									your name, postal address, telephone number, and, if
									available, email address).
									<br />
									A statement under penalty of perjury by you that you have a
									good faith belief that the material identified above was
									removed or disabled as a result of a mistake or
									misidentification of the material to be removed or disabled.
									<br />
									A statement that you will consent to the jurisdiction of the
									Federal District Court for the judicial district in which your
									address is located (or if you reside outside the United States
									for any judicial district in which the Website may be found)
									and that you will accept service from the person (or an agent
									of that person) who provided the Website with the complaint at
									issue.
									<br />
									The DMCA allows us to restore the removed content if the party
									filing the original DMCA Notice does not file a court action
									against you within ten (10) business days of receiving the
									copy of your Counter-Notice.
									<br />
									Please be aware that if you knowingly materially misrepresent
									that material or activity on the Website was removed or
									disabled by mistake or misidentification, you may be held
									liable for damages (including costs and attorneys' fees) under
									Section 512(f) of the DMCA.
								</p>
								<h4 className="my-4">Repeat Infringers</h4>
								<p>
									It is our policy in appropriate circumstances to disable
									and/or terminate the accounts of users who are repeat
									infringers.
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</>
	);
};

export default CopyPolicy;
