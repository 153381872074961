import React from "react";
// import "../../assets/css/thankyou.css";
import { useNavigate } from "react-router-dom";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import "../../Assets/Css/Thankyou.css";
import currency from "currency-formatter";

const Thankyou = () => {
	const navigate = useNavigate();
	const handleClick = () => {
		navigate("/");
	};

	return (
		<>
			<Header />
			<section className="thankyou">
				<div className="container">
					<div className="happyShopping">
						<h3 className="font-30 fw-600 pb-4">Thank you for Shopping</h3>

						<div>
							<p>Order Id: 1212214848</p>
							<p>
								Amount:{" "}
								{
									currency
										.format(
											12,

											{
												code: "GBP",
											},
										)
										.split(".00")[0]
								}{" "}
							</p>

							<p>Payment Method: Cash</p>
							<p>Status: "paid"</p>
						</div>

						<div className="button-group">
							<button onClick={handleClick} className="btn setbtnj">
								Continue Shopping
							</button>
						</div>
					</div>
				</div>
			</section>

			<Footer />
		</>
	);
};

export default Thankyou;
