import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "../../Pages/Home/Home";
import ChangePass from "../Auth/ChangePass";
import Login from "../Auth/Login";
import Register from "../Auth/Register";
import Verification from "../Auth/Verification";
import AllDealer from "../Dealer/AllDealer";
import DealerForm from "../Dealer/DealerForm";
import Faqs from "../Faqs/Faqs";
import Listing from "../Listing/Listing";
import About from "../About/About";
import Blog_detail from "../Blog/Blog_detail";
import DealerPage from "../Blog/DealerPage";
import Privacy_policies from "../Policies_pages/Privacy_policies";
import Terms from "../Policies_pages/Terms";
import Sell from "../Sell/Sell";
import Auction from "../Auction/Auction";
import Blogs from "../Blogs/blogs";
import ShowingList from "../ShowingList/ShowingList";
import BasicInfo from "../Profile/BasicInfo";
import Chat from "../Profile/Chat";
import MyAdd from "../Profile/MyAdd";
import Bidding from "../Profile/Bidding";
import SellerBidding from "../Profile/SellerBiding";
import Cardetailpage from "../cardetailpage/cardetailpage";
import CarPreview from "../cardetailpage/CarPreview";
import PostVehicleAds from "../PostVehicleAds/PostVehicleAds";
import PostSnap from "../PostVehicleAds/PostSnap";
import ContactUs from "../../Components/Contactus/ContactUs";
import CopyPolicy from "../Policies_pages/CopyPolicy";
import EnterEmail from "../Auth/EnterEmail";
import OurResearch from "../ourresearch/OurResearch";
import BuyersDetail from "../buyertoolsdetail/BuyersDetail";
import NewsDetail from "../Blog/NewsDetail";
import Aftermarket from "../Aftermarket/Aftermarket";
import Booking from "../Booking/Booking";
import Companies from "../Companies/Companies";
import Aftermarketdetail from "../Aftermarket/Aftermarketdetail";
import Cart from "../Cart/Cart";
import Checkout from "../Checkout/Checkout";
import Thankyou from "../Checkout/Thankyou";
import BookingDetails from "../Booking/BookingDetails";
import BannerAd from "../Profile/BannerAd";
import AdvertiseYourBanner from "../Profile/AdvertiseYourBanner";
import BannerAdPayment from "../Profile/BannerAdPayment";
import MyProducts from "../Profile/MyProducts";
import EditProfile from "../Profile/EditProfile";
import ChangePassword from "../Profile/ChangePassword";
import AddProduct from "../Profile/AddProduct";

const AllRoutes = () => {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/sell" element={<Sell />} />
				<Route path="/faqs" element={<Faqs />} />
				<Route path="/dealer" element={<DealerForm />} />
				<Route path="/all-dealer" element={<AllDealer />} />
				<Route path="/listing" element={<Listing />} />
				<Route path="/About" element={<About />} />
				<Route path="/privacy" element={<Privacy_policies />} />
				<Route path="/copy-policy" element={<CopyPolicy />} />
				<Route path="/Terms" element={<Terms />} />
				<Route path="/blog" element={<Blogs />} />
				<Route path="/auction" element={<Auction />} />
				<Route path="/Blog-details" element={<Blog_detail />} />
				<Route path="/DealerPage" element={<DealerPage />} />
				<Route path="/showimg-list" element={<ShowingList />} />
				<Route path="/contact" element={<ContactUs />} />
				<Route path="/Ourresearch" element={<OurResearch />} />
				<Route path="/buyerdetail" element={<BuyersDetail />} />
				<Route path="/newsdetail" element={<NewsDetail />} />
				<Route path="/aftermarket" element={<Aftermarket />} />
				<Route path="/aftermarketdetail" element={<Aftermarketdetail />} />
				<Route path="/booking" element={<Booking />} />
				<Route path="/bookingdetails" element={<BookingDetails />} />
				<Route path="/companies" element={<Companies />} />
				<Route path="/cart" element={<Cart />} />
				<Route path="/checkout" element={<Checkout />} />
				<Route path="/thankyou" element={<Thankyou />} />

				{/* Profile */}
				<Route path="/profile" element={<BasicInfo />} />
				<Route path="/profile-message" element={<Chat />} />
				<Route path="/my-add" element={<MyAdd />} />
				<Route path="/bidding" element={<Bidding />} />
				<Route path="/seller-bidding" element={<SellerBidding />} />
				<Route path="/bannerads" element={<BannerAd />} />
				<Route path="/advertiseyourbanner" element={<AdvertiseYourBanner />} />
				<Route path="/banneradpayment" element={<BannerAdPayment />} />
				<Route path="/myproducts" element={<MyProducts />} />
				<Route path="/editprofile" element={<EditProfile />} />
				<Route path="/changepassword" element={<ChangePassword />} />
				<Route path="/addproduct" element={<AddProduct />} />

				{/* Auth */}
				<Route path="/login" element={<Login />} />
				<Route path="/register" element={<Register />} />
				<Route path="/enter-email" element={<EnterEmail />} />
				<Route path="/verification" element={<Verification />} />
				<Route path="/change-pass" element={<ChangePass />} />
				<Route path="/cardetailpage" element={<Cardetailpage />} />
				<Route path="/carView" element={<CarPreview />} />
				<Route path="/PostVehicleAds" element={<PostVehicleAds />} />
				<Route path="/PostSnap" element={<PostSnap />} />
			</Routes>
		</BrowserRouter>
	);
};

export default AllRoutes;
