import React, { useState } from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
// import "../../Assets/Css/PostVehicleAds.css";
import "../../Assets/Css/Profile.css";

import { FileUploader } from "react-drag-drop-files";
import { Player, BigPlayButton } from "video-react";
import "../../../node_modules/video-react/dist/video-react.css";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";

const AddProduct = () => {
  const fileTypes = ["JPG", "PNG", "GIF"];
  const videdTypes = ["JPG", "PNG", "GIF"];
  const [file, setFile] = useState(null);
  const handleChange = (file) => {
    setFile(file);
  };
  let [radiocheck, setradiocheck] = useState(false);
  let handlecheck = () => {
    setradiocheck(true);
  };
  let handlecheckfalse = () => {
    setradiocheck(false);
  };
  const [name, setName] = useState("");
  const [name2, setName2] = useState("");
  return (
    <>
      <Header />
      {/* PostVehicle starts here */}
      <section className="profile_Sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <Sidebar />
            </div>
            <div className="col-lg-9">
              <div className="right-side">
                <div className="title">
                  <h3>Add Product</h3>
                </div>
                <div className="addproduct_sec">
                  <div className="form-group row">
                    {/* <div className="col-lg-6">
										<input
											type="text"
											placeholder="Dealership"
											className="form-control"
										/>
									</div> */}
                    <div className="col-lg-6">
                      <div className="form-floating mb-3">
                        <input
                          type="text"
                          placeholder="Title"
                          className="form-control"
                        />
                        <label for="floatingInput">Title</label>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <select
                        class="form-select"
                        aria-label="Default select example"
                      >
                        <option selected>Select Category</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </div>
                    <div className="col-lg-6">
                      <div class="form-floating mb-3">
                        <textarea
                          class="form-control"
                          placeholder="Leave a comment here"
                          id="floatingTextarea"
                        ></textarea>
                        <label for="floatingTextarea">Detail</label>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div class="form-floating mb-3">
                        <textarea
                          class="form-control"
                          placeholder="Leave a comment here"
                          id="floatingTextarea"
                          rows="2"
                        ></textarea>
                        <label for="floatingTextarea">Description</label>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-floating mb-3">
                        <input
                          type="text"
                          placeholder="Title"
                          className="form-control"
                        />
                        <label for="floatingInput">Stock Quality</label>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-floating mb-3">
                        <input
                          type="text"
                          placeholder="Title"
                          className="form-control"
                        />
                        <label for="floatingInput">Price</label>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <select
                        class="form-select"
                        aria-label="Default select example"
                      >
                        <option selected>In Stock</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </div>
                    
                    <div className="col-md-6">
                      <label for="uploadBtn" class="form-label">
                      <div class="">
                        <div class="fileUpload  btn--browse">
                          <label for="uploadBtn" class="form-label">
                            <input
                              class="form-control"
                              value={name2}
                              placeholder="Upload Display Images"
                            />
                            <input
                              id="uploadBtn"
                              type="file"
                              class="form-control"
                              value={name2}
                              onChange={(e) => setName2(e.target.value)}
                              
                            />

                            <i class="fa fa-paperclip" aria-hidden="true"></i>
                          </label>
                        </div>
                      </div>
                      </label>
                    </div>
                    <div className="col-md-6">
                      <div class="mb-3">
                        <div class="fileUpload  btn--browse">
                          <label for="uploadBtn" class="form-label">
                            <input
                              class="form-control"
                              value={name}
                              placeholder="Upload Other Images"
                            />
                            <input
                              id="uploadBtn"
                              type="file"
                              class="form-control"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                              multiple
                            />

                            <i class="fa fa-paperclip" aria-hidden="true"></i>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 ">
                      <div className="button-group">
                        <Link to="/myproducts" className="btn ">
                          Save Changes
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default AddProduct;
