import "./App.css";
import "../src/Assets/Css/style.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AllRoutes from "./Pages/AllRoutes/AllRoutes";
import WOW from "wowjs";

function App() {
  new WOW.WOW({
    live: false,
  }).init();
  return (
    <>
      <AllRoutes />
    </>
  );
}

export default App;
