import React from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import "../../Assets/Css/privacy.css";

const Privacy_policies = () => {
	return (
		<>
			<Header />
			<section className="privacy-banner">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="content-wrapper">
								<h2>
									Our Privacy <br /> Policy
								</h2>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="privacy-content-wrapper">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="content-wrapper">
								<h4>ALLEVEHICLES PRIVACY POLICY</h4>
								<p>Last modified: June 11, 2022</p>
							</div>
							<div className="content-wrapper">
								<h4>Introduction</h4>
								<p>
									allEVehicles, LLC d/b/a allEVehicles.com ("Company" or "We")
									respects your privacy and we are committed to protecting it
									through our compliance with this policy.
								</p>
								<p>
									This policy describes the types of information we may collect
									from you or that you may provide when you visit the website
									allEVehicles.com (our "Website") and our practices for
									collecting, using, maintaining, protecting, and disclosing
									that information.
								</p>
								<p>This policy applies to information We collect:</p>
								<p>On the Website.</p>
								<p>
									In email, text, and other electronic messages between you and
									the Website.
								</p>
								<p>
									When you interact with our advertising and applications on
									third-party websites and services, if those applications or
									advertising include links to this policy.
								</p>
								<p>It does not apply to information collected by:</p>
								<p>
									Us offline or through any other means, including on any other
									website operated by Company or any third party (including our
									affiliates and subsidiaries); or
								</p>
								<p>
									Any third party (including our affiliates and subsidiaries),
									including through any application or content (including
									advertising) that may link to or be accessible from or on the
									Website.
								</p>
								<p>
									Please read this policy carefully to understand our policies
									and practices regarding your information and how We will treat
									it. If you do not agree with our policies and practices, your
									choice is not to use our Website. By accessing or using this
									Website, you agree to this privacy policy. This policy may
									change from time to time (see Changes to Our Privacy Policy
									here). Your continued use of this Website after We make
									changes is deemed to be acceptance of those changes, so please
									check the policy periodically for updates.
								</p>
							</div>
							<div className="content-wrapper">
								<h4>Children Under the Age of 18</h4>
								<p>
									Our Website is not intended for persons under 18 years of age.
									No one under age 18 may provide any personal information to or
									on the Website. We do not knowingly collect personal
									information from persons under 18. If you are under 18, do not
									use or provide any information on this Website or on or
									through any of its features, register on the Website, use any
									of the interactive or public comment features of the Website
									or provide any information about yourself to us, including
									your name, address, telephone number, email address, or any
									screen name or user name you may use. If We learn We have
									collected or received personal information from a person under
									18, We will delete that information. If you believe We might
									have any information from or about a person under 18, please
									contact us at support@allEVehicles.com.
								</p>
							</div>
							<div className="content-wrapper">
								<h4>Information We Collect About You and How We Collect It</h4>
								<p>
									We collect several types of information from and about users
									of our Website, including information: <br />
									that is about you but individually does not identify you;
									and/or
								</p>
								<p>
									about your internet connection, the equipment you use to
									access our Website and usage details. <br />
									We collect this information:
								</p>
								<p>
									Directly from you when you provide it to us.
									<br />
									Automatically as you navigate through the site. Information
									collected automatically may include usage details, IP
									addresses, and information collected through cookies, web
									beacons, and other tracking technologies.
								</p>
								<p>
									From third parties, for example, our business partners.
									<br />
									Information You Provide to Us. The information We collect on
									or through our Website may include:
									<br />
									Information that you provide by filling in forms on our
									Website. This includes information provided at the time of
									registering to use our Website, subscribing to our services,
									posting material, or requesting further services. We may also
									ask you for information when you list a vehicle or make a bid
									through Website and when you report a problem with our
									Website.
									<br />
									Records and copies of your correspondence (including email
									addresses), if you contact us.
									<br />
									Your responses to surveys that We might ask you to complete
									for research purposes.
									<br />
									Details of your actions with respect to listed motor vehicles.
									You may be required to provide financial information before
									listing a vehicle or placing a bid through our Website.
									<br></br>
									Your search queries on the Website.
								</p>
								<p>
									You also may provide information to be published or displayed
									(hereinafter, "posted") on public or private areas of the
									Website, or transmitted to other users of the Website or third
									parties (collectively, "User Contributions"). Your User
									Contributions are posted on and transmitted to others at your
									own risk. Although We limit access to certain pages, please be
									aware that no security measures are perfect or impenetrable.
									Additionally, We cannot control the actions of other users of
									the Website with whom you may choose to share your User
									Contributions. Therefore, We cannot and do not guarantee that
									your User Contributions will not be viewed by unauthorized
									persons.
								</p>
								<p>
									Information We Collect Through Automatic Data Collection
									Technologies. As you navigate through and interact with our
									Website, We may use automatic data collection technologies to
									collect certain information about your equipment, browsing
									actions, and patterns, including: Details of your visits to
									our Website, including traffic data, location data, logs, and
									other communication data and the resources that you access and
									use on the Website.
								</p>
								<p>
									Information about your computer and internet connection,
									including your IP address, operating system, and browser type.
									We also may use these technologies to collect information
									about your online activities over time and across third-party
									websites or other online services (behavioral tracking). Our
									website does not respond to “do not track” or similar web
									browser signals.
								</p>
								<p>
									The information We collect automatically is statistical data
									and may include personal information or We may maintain it or
									associate it with personal information We collect in other
									ways or receive from third parties. It helps us to improve our
									Website and to deliver a better and more personalized service,
									including by enabling us to:
								</p>
								<p>Estimate our audience size and usage patterns.</p>
								<p>
									Store information about your preferences, allowing us to
									customize our Website according to your individual interests.{" "}
									<br />
									Speed up your searches.
								</p>
								<p>Recognize you when you return to our Website.</p>
								<p>
									The technologies We use for this automatic data collection may
									include: <br />
									Cookies (or browser cookies). A cookie is a small file placed
									on the hard drive of your computer. You may refuse to accept
									browser cookies by activating the appropriate setting on your
									browser. However, if you select this setting you may be unable
									to access certain parts of our Website. Unless you have
									adjusted your browser setting so that it will refuse cookies,
									our system will issue cookies when you direct your browser to
									our Website.
								</p>
								<p>
									Flash Cookies. Certain features of our Website may use local
									stored objects (or Flash cookies) to collect and store
									information about your preferences and navigation to, from,
									and on our Website. Flash cookies are not managed by the same
									browser settings as are used for browser cookies. For
									information about managing your privacy and security settings
									for Flash cookies, see Choices About How We Use and Disclose
									Your Information. Web Beacons. Pages of our the Website and
									our e-mails may contain small electronic files known as web
									beacons (also referred to as clear gifs, pixel tags, and
									single-pixel gifs) that permit the Company, for example, to
									count users who have visited those pages or opened an email
									and for other related website statistics (for example,
									recording the popularity of certain website content and
									verifying system and server integrity).
								</p>
							</div>
							<div className="content-wrapper">
								<h4>
									Third-Party Use of Cookies and Other Tracking Technologies.
								</h4>
								<p>
									Some content or applications, including advertisements, on the
									Website are served by third-parties, including advertisers, ad
									networks and servers, content providers, and application
									providers. These third parties may use cookies alone or in
									conjunction with web beacons or other tracking technologies to
									collect information about you when you use our Website. The
									information they collect may be associated with your personal
									information or they may collect information, including
									personal information, about your online activities over time
									and across different websites and other online services. They
									may use this information to provide you with interest-based
									(behavioral) advertising or other targeted content. We do not
									control these third parties' tracking technologies or how they
									may be used. If you have any questions about an advertisement
									or other targeted content, you should contact the responsible
									provider directly. For information about how you can opt out
									of receiving targeted advertising from many providers, see
									Choices About How We Use and Disclose Your Information.
								</p>
							</div>
							<div className="content-wrapper">
								<h4>How We Use Your Information</h4>
								<p>
									We use information that We collect about you or that you
									provide to us, including any personal information: To present
									our Website and its contents to you.
								</p>
								<p>
									To provide you with information, products, or services that
									you request from us.
								</p>
								<p>To fulfill any other purpose for which you provide it.</p>
								<p>
									To provide you with notices about your account, including
									expiration and renewal notices.
								</p>
								<p>
									To carry out our obligations and enforce our rights arising
									from any contracts entered into between you and us, including
									for billing and collection. To notify you about changes to our
									Website or any products or services We offer or provide though
									it.
								</p>
								<p>
									To allow you to participate in interactive features on our
									Website, including motor vehicle listings.
								</p>
								<p>
									In any other way We may describe when you provide the
									information.
								</p>
								<p>For any other purpose with your consent.</p>
								<p>
									We may also use your information to contact you about goods
									and services that may be of interest to you. If you do not
									want us to use your information in this way, please check the
									relevant box located on the form on which We collect your data
									and/or adjust your user preferences in your account profile.
								</p>
								<p>
									For more information, see Choices About How We Use and
									Disclose Your Information.
								</p>
								<p>
									We may use the information We have collected from you to
									enable us to display advertisements to our advertisers' target
									audiences. Even though We do not disclose your personal
									information for these purposes without your consent, if you
									click on or otherwise interact with an advertisement, the
									advertiser may assume that you meet its target criteria.
								</p>
							</div>
							<div className="content-wrapper">
								<h4>Disclosure of Your Information</h4>
								<p>
									We may disclose aggregated information about our users, and
									information that does not identify any individual, without
									restriction.
								</p>
								<p>
									We may disclose personal information that We collect or you
									provide as described in this privacy policy:
								</p>
								<p>To our subsidiaries and affiliates.</p>
								<p>
									To contractors, service providers, and other third parties We
									use to support our business.
								</p>
								<p>
									To a buyer or other successor in the event of a merger,
									divestiture, restructuring, reorganization, dissolution, or
									other sale or transfer of some or all of the Company's assets,
									whether as a going concern or as part of bankruptcy,
									liquidation, or similar proceeding, in which personal
									information held by the Company about our Website users is
									among the assets transferred.
								</p>
								<p>
									To third parties to market their products or services to you
									if you have not opted out of these disclosures. For more
									information, see Choices About How We Use and Disclose Your
									Information].
								</p>
								<p>To fulfill the purpose for which you provide it.</p>
								<p>
									For any other purpose disclosed by us when you provide the
									information.
								</p>
								<p>With your consent.</p>
								<p>We may also disclose your personal information:</p>
								<p>
									To comply with any court order, law, or legal process,
									including to respond to any government or regulatory request.
								</p>
								<p>
									To enforce or apply our Terms of Use (link), or Terms of Sale
									Policy (link), or Copyright (DMCA) Policy (link) and other
									agreements, including for billing and collection purposes.
								</p>
								<p>
									If We believe disclosure is necessary or appropriate to
									protect the rights, property, or safety of the Company, our
									customers, or others.
								</p>
							</div>
							<div className="content-wrapper">
								<h4>Choices About How We Use and Disclose Your Information</h4>
								<p>
									We strive to provide you with choices regarding the personal
									information you provide to us. We have created mechanisms to
									provide you with the following control over your information:
								</p>
								<p>
									Tracking Technologies and Advertising. You can set your
									browser to refuse all or some browser cookies, or to alert you
									when cookies are being sent. To learn how you can manage your
									Flash cookie settings, visit the Flash player settings page on
									Adobe's website. If you disable or refuse cookies, please note
									that some parts of this site may then be inaccessible or not
									function properly.
								</p>
								<p>
									We do not control third parties' collection or use of your
									information to serve interest-based advertising. However,
									these third parties may provide you with ways to choose not to
									have your information collected or used in this way.
								</p>
							</div>
							<div className="content-wrapper">
								<h4>Accessing and Correcting Your Information</h4>
								<p>
									You can review and change your personal information by logging
									into the Website and visiting your account profile page. If
									you delete your User Contributions from the Website, copies of
									your User Contributions may remain viewable in cached and
									archived pages, or might have been copied or stored by other
									Website users. Proper access and use of information provided
									on the Website, including User Contributions, is governed by
									our Terms of Use (link).
								</p>
							</div>
							<div className="content-wrapper">
								<h4>Data Security</h4>
								<p>
									We have implemented measures designed to secure your personal
									information from accidental loss and from unauthorized access,
									use, alteration, and disclosure.
								</p>
								<p>
									The safety and security of your information also depends on
									you. Where We have given you (or where you have chosen) a
									password for access to certain parts of our Website, you are
									responsible for keeping this password confidential. We ask you
									not to share your password with anyone. We urge you to be
									careful about giving out information in public areas of the
									Website like message boards. The information you share in
									public areas may be viewed by any user of the Website.
								</p>
								<p>
									Unfortunately, the transmission of information via the
									internet is not completely secure. Although We do our best to
									protect your personal information, We cannot guarantee the
									security of your personal information transmitted to our
									Website. Any transmission of personal information is at your
									own risk. We are not responsible for circumvention of any
									privacy settings or security measures contained on the
									Website.
								</p>
							</div>
							<div className="content-wrapper">
								<h4>Changes to Our Privacy Policy</h4>
								<p>
									It is our policy to post any changes We make to our privacy
									policy on this page with a notice that the privacy policy has
									been updated on the Website home page. If We make material
									changes to how We treat our users' personal information, We
									will notify you by email to the primary email address
									specified in your account. The date the privacy policy was
									last revised is identified at the top of the page. You are
									responsible for ensuring We have an up-to-date active and
									deliverable email address for you, and for periodically
									visiting our Website and this privacy policy to check for any
									changes.
								</p>
								<p>Contact Information</p>
								<p>
									To ask questions or comment about this privacy policy and our
									privacy practices, contact us at: <br />{" "}
									support@allEVehicles.com
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</>
	);
};

export default Privacy_policies;
