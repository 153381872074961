import React, { useState } from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import TopSlider from "../Home/TopSlider";
import Grid from "../../Assets/img/grid.png";
import "../../Assets/Css/listing.css";
import "../../Assets/Css/car-detail.css";
import Slider from "react-slick";

// categories Images
import Cat1 from "../../Assets/img/cat1.png";
import Cat2 from "../../Assets/img/cat2.png";
import Cat3 from "../../Assets/img/cat3.png";
import Cat4 from "../../Assets/img/cat4.png";
import Cat5 from "../../Assets/img/cat5.png";
import Cat6 from "../../Assets/img/cat6.png";
import Cat7 from "../../Assets/img/cat7.png";
import Cat8 from "../../Assets/img/cat9.png";
import Cat9 from "../../Assets/img/cat10.png";
import Cat10 from "../../Assets/img/cat11.png";
import Cat11 from "../../Assets/img/cat12.png";
import Cat12 from "../../Assets/img/cat13.png";
import Cat13 from "../../Assets/img/cat14.png";
import banner from "../../Assets/img/banner.png";
import about1 from "../../Assets/img/about1.png";
import car1 from "../../Assets/img/car1.png";
import car2 from "../../Assets/img/car2.png";
import icon from "../../Assets/img/icon.png";
import service from "../../Assets/img/services1.png";
import services2 from "../../Assets/img/services2.png";
import services3 from "../../Assets/img/services3.png";
import services4 from "../../Assets/img/services4.png";
import feature1 from "../../Assets/img/feature1.png";
import meter from "../../Assets/img/meter.svg";
import meter1 from "../../Assets/img/meter1.svg";
import meter2 from "../../Assets/img/meter2.svg";
import meter3 from "../../Assets/img/meter3.svg";
import add from "../../Assets/img/add.png";
import feature2 from "../../Assets/img/feature2.png";
import feature3 from "../../Assets/img/feature3.png";
import feature4 from "../../Assets/img/feature4.png";
import feature5 from "../../Assets/img/feature5.png";
import feature6 from "../../Assets/img/feature6.png";
import thanks from "../../Assets/img/thankyou.gif";
import { Link, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ClearIcon from "@mui/icons-material/Clear";

function Booking() {
	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());
	const navigate = useNavigate();
	const HandlePageChange = () => {
		navigate("/cardetailpage");
	};
	const categories = [
		{
			catImg: Cat1,
			catName: "New",
		},
		{
			catImg: Cat2,
			catName: "Used",
		},
		{
			catImg: Cat3,
			catName: "Electric",
		},
		{
			catImg: Cat4,
			catName: "Hybrid",
		},
		{
			catImg: Cat5,
			catName: "Price",
		},
		{
			catImg: Cat6,
			catName: "Year",
		},
		{
			catImg: Cat7,
			catName: "Range",
		},
		{
			catImg: Cat8,
			catName: "Mileage",
		},
		{
			catImg: Cat9,
			catName: "Battery",
		},
		{
			catImg: Cat10,
			catName: "color",
		},
		{
			catImg: Cat11,
			catName: "Speed",
		},
		{
			catImg: Cat12,
			catName: "Motor",
		},
		{
			catImg: Cat13,
			catName: "Drivetrain",
		},
	];
	const listingImg = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
	};
	return (
		<>
			<section className="lisiting">
				<Header />
				<div className="container">
					<div className="row">
						<div className="col-lg-3">
							<div className="filter">
								<div className="heading">
									<h4>FILTER BY:</h4>
									<p>
										<i class="fa fa-map-marker" aria-hidden="true"></i> Your
										Location
									</p>
								</div>
								<ul className="categories">
									{categories?.map((cat, index) => {
										return (
											<li key={index}>
												<span>{cat?.catName}</span>
												<img src={cat?.catImg} alt="" className="img-fluid" />
											</li>
										);
									})}
								</ul>
							</div>
						</div>
						<div className="col-lg-9">
							<div className="view">
								<div className="form-group">
									<label>List view</label>
									<img src={Grid} className="img-fluid" />
								</div>
							</div>
							{/* Featured section  */}

							<section className="feature-sec">
								<div className="container">
									<div className="row wow animate__animated animate__slideInLeft">
										<div className="col-md-4">
											<div className="featured-all">
												<div className="f-img">
													<figure>
														<Slider {...listingImg}>
															<img src={feature1}></img>
															<img src={feature1}></img>
															<img src={feature1}></img>
														</Slider>
													</figure>
													{/* <div className="actions">
														<a href="#">
															<i class="fa fa-heart" aria-hidden="true"></i>
														</a>
														<a href="#">
															<i class="fa fa-eye-slash" aria-hidden="true"></i>
														</a>
													</div> */}
												</div>
												<div className="feature-head">
													<h2>
														<span>$</span>8,789
													</h2>
												</div>

												<div className="feature-normal-head">
													<h3>2017 CHEVROLET VOLT LT</h3>
												</div>

												<div className="feature-icon-head">
													<span>
														<img src={meter1}></img> 18,234 miles
													</span>
													<span>
														<img src={meter2}></img> 267 Mi max range
													</span>
												</div>
												<div className="feature-iiimmgg">
													<span>
														<img src={meter3}></img> Longview, TX
													</span>
												</div>

												<div className="checkup">
													<div className="row">
														<div className="col-md-5">
															<button
																className="btn btn22"
																onClick={() => navigate("/bookingdetails")}
															>
																View more
															</button>
														</div>
														<div className="col-md-4">
															<button
																data-toggle="modal"
																data-target="#exampleModalCenter"
																className="btn btn22 mr-4"
															>
																Book now
															</button>
														</div>
													</div>
												</div>

												<div className="new-listing">
													<h5>New listing</h5>
												</div>
											</div>
										</div>
										<div className="col-md-4">
											<div className="featured-all">
												<div className="f-img">
													<figure>
														<Slider {...listingImg}>
															<img src={feature5}></img>
															<img src={feature5}></img>
															<img src={feature5}></img>
														</Slider>
													</figure>
													{/* <div className="actions">
														<a href="#">
															<i class="fa fa-heart" aria-hidden="true"></i>
														</a>
														<a href="#">
															<i class="fa fa-eye-slash" aria-hidden="true"></i>
														</a>
													</div> */}
												</div>
												<div className="feature-head">
													<h2>
														<span>$</span>8,789
													</h2>
													{/* <p>
                            <i class="fa fa-car" aria-hidden="true"></i> For
                            price | $566 below market
                          </p> */}
												</div>

												<div className="feature-normal-head">
													<h3>2017 CHEVROLET VOLT LT</h3>
												</div>

												<div className="feature-icon-head">
													<span>
														<img src={meter1}></img> 18,234 miles
													</span>
													<span>
														<img src={meter2}></img> 267 Mi max range
													</span>
												</div>
												<div className="feature-iiimmgg">
													<span>
														<img src={meter3}></img> Longview, TX
													</span>
												</div>

												<div className="checkup">
													<div className="row">
														<div className="col-md-5">
															<button
																className="btn btn22"
																onClick={() => navigate("/bookingdetails")}
															>
																View more
															</button>
														</div>
														<div className="col-md-4">
															<button
																data-toggle="modal"
																data-target="#exampleModalCenter"
																className="btn btn22 mr-4"
															>
																Book now
															</button>
														</div>
													</div>
												</div>

												<div className="new-listing">
													<h5>New listing</h5>
												</div>
											</div>
										</div>
										<div className="col-md-4">
											<div className="featured-all">
												<div className="f-img">
													<figure>
														<Slider {...listingImg}>
															<img src={feature6}></img>
															<img src={feature6}></img>
															<img src={feature6}></img>
														</Slider>
													</figure>
													{/* <div className="actions">
														<a href="#">
															<i class="fa fa-heart" aria-hidden="true"></i>
														</a>
														<a href="#">
															<i class="fa fa-eye-slash" aria-hidden="true"></i>
														</a>
													</div> */}
												</div>
												<div className="feature-head">
													<h2>
														<span>$</span>8,789
													</h2>
													{/* <p>
                            <i class="fa fa-car" aria-hidden="true"></i> For
                            price | $566 below market
                          </p> */}
												</div>

												<div className="feature-normal-head">
													<h3>2017 CHEVROLET VOLT LT</h3>
												</div>

												<div className="feature-icon-head">
													<span>
														<img src={meter1}></img> 18,234 miles
													</span>
													<span>
														<img src={meter2}></img> 267 Mi max range
													</span>
												</div>
												<div className="feature-iiimmgg">
													<span>
														<img src={meter3}></img> Longview, TX
													</span>
												</div>

												<div className="checkup">
													<div className="row">
														<div className="col-md-5">
															<button
																className="btn btn22"
																onClick={() => navigate("/bookingdetails")}
															>
																View more
															</button>
														</div>
														<div className="col-md-4">
															<button
																data-toggle="modal"
																data-target="#exampleModalCenter"
																className="btn btn22 mr-4"
															>
																Book now
															</button>
														</div>
													</div>
												</div>

												<div className="new-listing">
													<h5>New listing</h5>
												</div>
											</div>
										</div>
										<div className="col-md-4">
											<div className="featured-all">
												<div className="f-img">
													<figure>
														<Slider {...listingImg}>
															<img src={feature2}></img>
															<img src={feature2}></img>
															<img src={feature2}></img>
														</Slider>
													</figure>
													{/* <div className="actions">
														<a href="#">
															<i class="fa fa-heart" aria-hidden="true"></i>
														</a>
														<a href="#">
															<i class="fa fa-eye-slash" aria-hidden="true"></i>
														</a>
													</div> */}
												</div>
												<div className="feature-head">
													<h2>
														<span>$</span>8,789
													</h2>
													{/* <p>
                            <i class="fa fa-car" aria-hidden="true"></i> For
                            price | $566 below market
                          </p> */}
												</div>

												<div className="feature-normal-head">
													<h3>2017 CHEVROLET VOLT LT</h3>
												</div>

												<div className="feature-icon-head">
													<span>
														<img src={meter1}></img> 18,234 miles
													</span>
													<span>
														<img src={meter2}></img> 267 Mi max range
													</span>
												</div>
												<div className="feature-iiimmgg">
													<span>
														<img src={meter3}></img> Longview, TX
													</span>
												</div>

												<div className="checkup">
													<div className="row">
														<div className="col-md-5">
															<button
																className="btn btn22"
																onClick={() => navigate("/bookingdetails")}
															>
																View more
															</button>
														</div>
														<div className="col-md-4">
															<button
																data-toggle="modal"
																data-target="#exampleModalCenter"
																className="btn btn22 mr-4"
															>
																Book now
															</button>
														</div>
													</div>
												</div>

												<div className="new-listing">
													<h5>New listing</h5>
												</div>
											</div>
										</div>
										<div className="col-md-4">
											<div className="featured-all">
												<div className="f-img">
													<figure>
														<Slider {...listingImg}>
															<img src={feature3}></img>
															<img src={feature3}></img>
															<img src={feature3}></img>
														</Slider>
													</figure>
													{/* <div className="actions">
														<a href="#">
															<i class="fa fa-heart" aria-hidden="true"></i>
														</a>
														<a href="#">
															<i class="fa fa-eye-slash" aria-hidden="true"></i>
														</a>
													</div> */}
												</div>
												<div className="feature-head">
													<h2>
														<span>$</span>8,789
													</h2>
													{/* <p>
                            <i class="fa fa-car" aria-hidden="true"></i> For
                            price | $566 below market
                          </p> */}
												</div>

												<div className="feature-normal-head">
													<h3>2017 CHEVROLET VOLT LT</h3>
												</div>

												<div className="feature-icon-head">
													<span>
														<img src={meter1}></img> 18,234 miles
													</span>
													<span>
														<img src={meter2}></img> 267 Mi max range
													</span>
												</div>
												<div className="feature-iiimmgg">
													<span>
														<img src={meter3}></img> Longview, TX
													</span>
												</div>

												<div className="checkup">
													<div className="row">
														<div className="col-md-5">
															<button
																className="btn btn22"
																onClick={() => navigate("/bookingdetails")}
															>
																View more
															</button>
														</div>
														<div className="col-md-4">
															<button
																data-toggle="modal"
																data-target="#exampleModalCenter"
																className="btn btn22 mr-4"
															>
																Book now
															</button>
														</div>
													</div>
												</div>

												<div className="new-listing">
													<h5>New listing</h5>
												</div>
											</div>
										</div>
										<div className="col-md-4">
											<div className="featured-all">
												<div className="f-img">
													<figure>
														<Slider {...listingImg}>
															<img src={feature1}></img>
															<img src={feature1}></img>
															<img src={feature1}></img>
														</Slider>
													</figure>
													{/* <div className="actions">
														<a href="#">
															<i class="fa fa-heart" aria-hidden="true"></i>
														</a>
														<a href="#">
															<i class="fa fa-eye-slash" aria-hidden="true"></i>
														</a>
													</div> */}
												</div>
												<div className="feature-head">
													<h2>
														<span>$</span>8,789
													</h2>
													{/* <p>
                            <i class="fa fa-car" aria-hidden="true"></i> For
                            price | $566 below market
                          </p> */}
												</div>

												<div className="feature-normal-head">
													<h3>2017 CHEVROLET VOLT LT</h3>
												</div>

												<div className="feature-icon-head">
													<span>
														<img src={meter1}></img> 18,234 miles
													</span>
													<span>
														<img src={meter2}></img> 267 Mi max range
													</span>
												</div>
												<div className="feature-iiimmgg">
													<span>
														<img src={meter3}></img> Longview, TX
													</span>
												</div>

												<div className="checkup">
													<div className="row">
														<div className="col-md-5">
															<button
																className="btn btn22"
																onClick={() => navigate("/bookingdetails")}
															>
																View more
															</button>
														</div>
														<div className="col-md-4">
															<button
																data-toggle="modal"
																data-target="#exampleModalCenter"
																className="btn btn22 mr-4"
															>
																Book now
															</button>
														</div>
													</div>
												</div>

												<div className="new-listing">
													<h5>New listing</h5>
												</div>
											</div>
										</div>
										<div className="col-md-4">
											<div className="featured-all">
												<div className="f-img">
													<figure>
														<Slider {...listingImg}>
															<img src={feature4}></img>
															<img src={feature4}></img>
															<img src={feature4}></img>
														</Slider>
													</figure>
													{/* <div className="actions">
														<a href="#">
															<i class="fa fa-heart" aria-hidden="true"></i>
														</a>
														<a href="#">
															<i class="fa fa-eye-slash" aria-hidden="true"></i>
														</a>
													</div> */}
												</div>
												<div className="feature-head">
													<h2>
														<span>$</span>8,789
													</h2>
													{/* <p>
                            <i class="fa fa-car" aria-hidden="true"></i> For
                            price | $566 below market
                          </p> */}
												</div>

												<div className="feature-normal-head">
													<h3>2017 CHEVROLET VOLT LT</h3>
												</div>

												<div className="feature-icon-head">
													<span>
														<img src={meter1}></img> 18,234 miles
													</span>
													<span>
														<img src={meter2}></img> 267 Mi max range
													</span>
												</div>
												<div className="feature-iiimmgg">
													<span>
														<img src={meter3}></img> Longview, TX
													</span>
												</div>

												<div className="checkup">
													<div className="row">
														<div className="col-md-5">
															<button
																className="btn btn22"
																onClick={() => navigate("/bookingdetails")}
															>
																View more
															</button>
														</div>
														<div className="col-md-4">
															<button
																data-toggle="modal"
																data-target="#exampleModalCenter"
																className="btn btn22 mr-4"
															>
																Book now
															</button>
														</div>
													</div>
												</div>

												<div className="new-listing">
													<h5>New listing</h5>
												</div>
											</div>
										</div>
										<div className="col-md-4">
											<div className="featured-all">
												<div className="f-img">
													<figure>
														<Slider {...listingImg}>
															<img src={feature5}></img>
															<img src={feature5}></img>
															<img src={feature5}></img>
														</Slider>
													</figure>
													{/* <div className="actions">
														<a href="#">
															<i class="fa fa-heart" aria-hidden="true"></i>
														</a>
														<a href="#">
															<i class="fa fa-eye-slash" aria-hidden="true"></i>
														</a>
													</div> */}
												</div>
												<div className="feature-head">
													<h2>
														<span>$</span>8,789
													</h2>
													{/* <p>
                            <i class="fa fa-car" aria-hidden="true"></i> For
                            price | $566 below market
                          </p> */}
												</div>

												<div className="feature-normal-head">
													<h3>2017 CHEVROLET VOLT LT</h3>
												</div>

												<div className="feature-icon-head">
													<span>
														<img src={meter1}></img> 18,234 miles
													</span>
													<span>
														<img src={meter2}></img> 267 Mi max range
													</span>
												</div>
												<div className="feature-iiimmgg">
													<span>
														<img src={meter3}></img> Longview, TX
													</span>
												</div>

												<div className="checkup">
													<div className="row">
														<div className="col-md-5">
															<button
																className="btn btn22"
																onClick={() => navigate("/bookingdetails")}
															>
																View more
															</button>
														</div>
														<div className="col-md-4">
															<button
																data-toggle="modal"
																data-target="#exampleModalCenter"
																className="btn btn22 mr-4"
															>
																Book now
															</button>
														</div>
													</div>
												</div>

												<div className="new-listing">
													<h5>New listing</h5>
												</div>
											</div>
										</div>
										<div className="col-md-4">
											<div className="featured-all">
												<div className="f-img">
													<figure>
														<Slider {...listingImg}>
															<img src={feature6}></img>
															<img src={feature6}></img>
															<img src={feature6}></img>
														</Slider>
													</figure>
													{/* <div className="actions">
														<a href="#">
															<i class="fa fa-heart" aria-hidden="true"></i>
														</a>
														<a href="#">
															<i class="fa fa-eye-slash" aria-hidden="true"></i>
														</a>
													</div> */}
												</div>
												<div className="feature-head">
													<h2>
														<span>$</span>8,789
													</h2>
													{/* <p>
                            <i class="fa fa-car" aria-hidden="true"></i> For
                            price | $566 below market
                          </p> */}
												</div>

												<div className="feature-normal-head">
													<h3>2017 CHEVROLET VOLT LT</h3>
												</div>

												<div className="feature-icon-head">
													<span>
														<img src={meter1}></img> 18,234 miles
													</span>
													<span>
														<img src={meter2}></img> 267 Mi max range
													</span>
												</div>
												<div className="feature-iiimmgg">
													<span>
														<img src={meter3}></img> Longview, TX
													</span>
												</div>

												<div className="checkup">
													<div className="row">
														<div className="col-md-5">
															<button
																className="btn btn22"
																onClick={() => navigate("/bookingdetails")}
															>
																View more
															</button>
														</div>
														<div className="col-md-4">
															<button
																data-toggle="modal"
																data-target="#exampleModalCenter"
																className="btn btn22 mr-4"
															>
																Book now
															</button>
														</div>
													</div>
												</div>

												<div className="new-listing">
													<h5>New listing</h5>
												</div>
											</div>
										</div>
										<div className="col-md-4">
											<div className="featured-all">
												<div className="f-img">
													<figure>
														<Slider {...listingImg}>
															<img src={feature2}></img>
															<img src={feature2}></img>
															<img src={feature2}></img>
														</Slider>
													</figure>
													{/* <div className="actions">
														<a href="#">
															<i class="fa fa-heart" aria-hidden="true"></i>
														</a>
														<a href="#">
															<i class="fa fa-eye-slash" aria-hidden="true"></i>
														</a>
													</div> */}
												</div>
												<div className="feature-head">
													<h2>
														<span>$</span>8,789
													</h2>
													{/* <p>
                            <i class="fa fa-car" aria-hidden="true"></i> For
                            price | $566 below market
                          </p> */}
												</div>

												<div className="feature-normal-head">
													<h3>2017 CHEVROLET VOLT LT</h3>
												</div>

												<div className="feature-icon-head">
													<span>
														<img src={meter1}></img> 18,234 miles
													</span>
													<span>
														<img src={meter2}></img> 267 Mi max range
													</span>
												</div>
												<div className="feature-iiimmgg">
													<span>
														<img src={meter3}></img> Longview, TX
													</span>
												</div>

												<div className="checkup">
													<div className="row">
														<div className="col-md-5">
															<button
																className="btn btn22"
																onClick={() => navigate("/bookingdetails")}
															>
																View more
															</button>
														</div>
														<div className="col-md-4">
															<button
																data-toggle="modal"
																data-target="#exampleModalCenter"
																className="btn btn22 mr-4"
															>
																Book now
															</button>
														</div>
													</div>
												</div>

												<div className="new-listing">
													<h5>New listing</h5>
												</div>
											</div>
										</div>
										<div className="col-md-4">
											<div className="featured-all">
												<div className="f-img">
													<figure>
														<Slider {...listingImg}>
															<img src={feature3}></img>
															<img src={feature3}></img>
															<img src={feature3}></img>
														</Slider>
													</figure>
													{/* <div className="actions">
														<a href="#">
															<i class="fa fa-heart" aria-hidden="true"></i>
														</a>
														<a href="#">
															<i class="fa fa-eye-slash" aria-hidden="true"></i>
														</a>
													</div> */}
												</div>
												<div className="feature-head">
													<h2>
														<span>$</span>8,789
													</h2>
													{/* <p>
                            <i class="fa fa-car" aria-hidden="true"></i> For
                            price | $566 below market
                          </p> */}
												</div>

												<div className="feature-normal-head">
													<h3>2017 CHEVROLET VOLT LT</h3>
												</div>

												<div className="feature-icon-head">
													<span>
														<img src={meter1}></img> 18,234 miles
													</span>
													<span>
														<img src={meter2}></img> 267 Mi max range
													</span>
												</div>
												<div className="feature-iiimmgg">
													<span>
														<img src={meter3}></img> Longview, TX
													</span>
												</div>

												<div className="checkup">
													<div className="row">
														<div className="col-md-5">
															<button
																className="btn btn22"
																onClick={() => navigate("/bookingdetails")}
															>
																View more
															</button>
														</div>
														<div className="col-md-4">
															<button
																data-toggle="modal"
																data-target="#exampleModalCenter"
																className="btn btn22 mr-4"
															>
																Book now
															</button>
														</div>
													</div>
												</div>

												<div className="new-listing">
													<h5>New listing</h5>
												</div>
											</div>
										</div>
										<div className="col-md-4">
											<div className="featured-all">
												<div className="f-img">
													<figure>
														<Slider {...listingImg}>
															<img src={feature1}></img>
															<img src={feature1}></img>
															<img src={feature1}></img>
														</Slider>
													</figure>
													{/* <div className="actions">
														<a href="#">
															<i class="fa fa-heart" aria-hidden="true"></i>
														</a>
														<a href="#">
															<i class="fa fa-eye-slash" aria-hidden="true"></i>
														</a>
													</div> */}
												</div>
												<div className="feature-head">
													<h2>
														<span>$</span>8,789
													</h2>
													{/* <p>
                            <i class="fa fa-car" aria-hidden="true"></i> For
                            price | $566 below market
                          </p> */}
												</div>

												<div className="feature-normal-head">
													<h3>2017 CHEVROLET VOLT LT</h3>
												</div>

												<div className="feature-icon-head">
													<span>
														<img src={meter1}></img> 18,234 miles
													</span>
													<span>
														<img src={meter2}></img> 267 Mi max range
													</span>
												</div>
												<div className="feature-iiimmgg">
													<span>
														<img src={meter3}></img> Longview, TX
													</span>
												</div>

												<div className="checkup">
													<div className="row">
														<div className="col-md-5">
															<button
																className="btn btn22"
																onClick={() => navigate("/bookingdetails")}
															>
																View more
															</button>
														</div>
														<div className="col-md-4">
															<button
																data-toggle="modal"
																data-target="#exampleModalCenter"
																className="btn btn22 mr-4"
															>
																Book now
															</button>
														</div>
													</div>
												</div>

												<div className="new-listing">
													<h5>New listing</h5>
												</div>
											</div>
										</div>
									</div>
								</div>
							</section>
							<div className="pagination">
								<div className="pageCount">
									<button className="btn3">1</button>
									<button className="btn1">2</button>
									<button className="btn1">3</button>
									<button className="btn1">4</button>
									<button className="btn1">5</button>
									<button className="btn1">10</button>
									<button className="btn2">
										Next <i class="fa fa-angle-right" aria-hidden="true"></i>
									</button>
									<i class="bi bi-chevron-right"></i>
								</div>
							</div>
							{/* End of Featured section  */}
						</div>
					</div>
				</div>
				<Footer />
			</section>
			<div
				class="modal fade"
				id="exampleModalCenter"
				tabindex="-1"
				role="dialog"
				aria-labelledby="exampleModalCenterTitle"
				aria-hidden="true"
			>
				<div class="modal-dialog modal-dialog-centered" role="document">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title" id="exampleModalLongTitle">
								Booking Form
							</h5>
						</div>
						<div class="modal-body">
							<form action="">
								<div class="row">
									<div class="col-md-6">
										<div class="form-group">
											<label>Full Name*</label>
											<input
												type="text"
												placeholder="Enter your FullName"
												class="form-control"
											/>
										</div>
									</div>
									<div class="col-md-6">
										<div class="form-group">
											<label>Last Name*</label>
											<input
												type="text"
												placeholder="Enter your LastName"
												class="form-control"
											/>
										</div>
									</div>
									<div class="col-md-6">
										<div class="form-group">
											<label>Your Email Address*</label>
											<input
												type="email"
												placeholder="Enter your Email Address"
												class="form-control"
											/>
										</div>
									</div>
									<div class="col-md-6">
										<div class="form-group">
											<label>Phone Number*</label>
											<input
												type="tel"
												placeholder="Enter your Phone Number"
												class="form-control"
											/>
										</div>
									</div>
									<label>Booking Date*</label>
									<div className="col-md-6">
										<div class="form-group">
											<label>Start Date</label>
											<DatePicker
												selected={startDate}
												onChange={(date) => setStartDate(date)}
												selectsStart
												startDate={startDate}
												endDate={endDate}
											/>
										</div>
									</div>
									<div className="col-md-6">
										<div class="form-group">
											<label>End Date</label>
											<DatePicker
												selected={endDate}
												onChange={(date) => setEndDate(date)}
												selectsEnd
												startDate={startDate}
												endDate={endDate}
												minDate={startDate}
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div class="form-group">
											<label>Special Instructions*</label>
											<textarea
												className="form_control_text form-control"
												id="exampleFormControlTextarea3"
												rows="7"
											></textarea>
										</div>
									</div>
								</div>
							</form>
						</div>
						<div class="modal-footer">
							<button type="button" class="btn" data-dismiss="modal">
								Close
							</button>
							<button
								type="button"
								data-toggle="modal"
								data-target="#exampleModalCenter22"
								className="btn "
								data-dismiss="modal"
							>
								Submit
							</button>
						</div>
					</div>
				</div>
			</div>
			<div
				class="modal fade"
				id="exampleModalCenter22"
				tabindex="-1"
				role="dialog"
				aria-labelledby="exampleModalCenterTitle22"
				aria-hidden="true"
			>
				<div
					class="modal-dialog modal-dialog-centered modal_all_container"
					role="document"
				>
					<div class="modal-content">
						<div className="modal_center_success">
							<h5 class="modal-title" id="exampleModalLongTitle22">
								Booking Successfully
							</h5>
							<img src={thanks} alt="" />
						</div>
						<div className="position_set_cancel" data-dismiss="modal">
							<ClearIcon />
						</div>
						<div className="form_content_detail">
							<div>
								<p>
									<span>FirstName:</span> JackMark
								</p>

								<p>
									<span>Email:</span> JackMark@test.com
								</p>
								<p>
									<span>Phone:</span> +1212154545
								</p>
								<p>
									<span>Start Date:</span> 09-05-2022
								</p>
								<p>
									<span>End Date:</span> 12-06-2022
								</p>
							</div>
						</div>
						<div></div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Booking;
