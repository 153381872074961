import React from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import CarBanner from "../../Assets/img/dealer.png";
import listImg from "../../Assets/img/electricity.webp";
import "../../Assets/Css/dealer.css";

const DealerForm = () => {
  return (
    <>
      <Header />

      {/* Inner Banner Sec Start Here */}
      <section className="InnerBanner">
        <div className="container-fluid p-0">
          <figure>
            <img src={CarBanner} className="img-fluid" />
          </figure>
        </div>
      </section>
      {/* Inner Banner Sec Start Here */}

      {/* Dealer Banner Sec Start Here */}
      <section className="dealer">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="heading">
                <h3>Become A Dealer</h3>
              </div>
              <ul className="list">
                <h4>Heading Here</h4>
                <li>
                  <img src={listImg} className="img-fluid" /> Cras ornare
                  tristique elit.
                </li>
                <li>
                  <img src={listImg} className="img-fluid" /> Vivamus vestibulum
                  ntulla nec ante.
                </li>
                <li>
                  <img src={listImg} className="img-fluid" /> Praesent placerat
                  risus quis eros.
                </li>
                <li>
                  <img src={listImg} className="img-fluid" /> Fusce pellentesque
                  suscipit nibh.
                </li>
                <li>
                  <img src={listImg} className="img-fluid" /> Integer vitae
                  libero ac risus egestas placerat.
                </li>
                <li>
                  <img src={listImg} className="img-fluid" /> Vestibulum commodo
                  felis quis tortor.
                </li>
                <li>
                  <img src={listImg} className="img-fluid" /> Ut aliquam
                  sollicitudin leo.
                </li>
                <li>
                  <img src={listImg} className="img-fluid" /> Cras iaculis
                  ultricies nulla.
                </li>
                <li>
                  <img src={listImg} className="img-fluid" /> Donec quis dui at
                  dolor tempor interdum.
                </li>
              </ul>
            </div>
            <div className="col-lg-6">
              <div className="dealer-form">
                <form>
                  <div className="form-group">
                    <input
                      className="form-control"
                      placeholder="Dealership"
                      type="text"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      className="form-control"
                      placeholder="First name"
                      type="text"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      className="form-control"
                      placeholder="Last name"
                      type="text"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      className="form-control"
                      placeholder="Email Address "
                      type="email"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      className="form-control"
                      placeholder="Contact Number"
                      type="text"
                    />
                  </div>
                  {/* <div className="form-group">
                    <input
                      className="form-control"
                      placeholder="Password"
                      type="password"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      className="form-control"
                      placeholder="Confirm Password"
                      type="password"
                    />
                  </div> */}
                  <div className="form-group checkk">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="user"
                    />
                    <label for="user">
                      As a registered user we will email you with relevant
                      information. Untick to opt out.
                    </label>
                  </div>
                  <div className="button-group">
                    <button className="btn" type="submit">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Dealer Banner Sec End Here */}

      <Footer />
    </>
  );
};

export default DealerForm;
