import React from "react";
import FirstImage from "../../Assets/img/AuctionFirstImg.png";
import "../../Assets/Css/Auction.css";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";

function Auction() {
	return (
		<>
			<section>
				<Header />
				<div className="auctionWrappper">
					<div className="container">
						<div className="heading">
							<h4>AUCTION</h4>
						</div>
						<div className="firsthead">
							<div className="row">
								<div className="col-lg-4">
									<div className="firstImg">
										<img src={FirstImage} alt="firstImage" />
									</div>
								</div>
								<div className="col-lg-8 ">
									<div className="wraper">
										<div className="sectionHeading">
											<h5 className="auctionHead">
												MINI S E COUNTRYMAN ALL 4 CLASSIC
											</h5>
											<div className="firstButton">
												<button className="firstbtn">Withdraw auction</button>
											</div>
										</div>
										<div className="secondDiv">
											<p>AUCTION PRICE</p>
											<div className="price">
												<h4 className="priceNumber">$31,004</h4>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="secondhead">
							<div className="table-responsive mt-5">
								<table className="table tablefonts">
									<thead>
										<tr>
											<th>BIDDER NAME</th>
											<th>OFFER</th>
											<th>STATUS</th>
											<th>ACTION</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>Todd James</td>
											<td>$31,500</td>
											<td className="Pending">Pending...</td>
											<td>
												<button className="btn btnColor">Accept</button>
											</td>
										</tr>
										<tr>
											<td>Todd James</td>
											<td>$31,500</td>
											<td className="Pending">Pending...</td>
											<td>
												<button className="btn btnColor">Accept</button>
											</td>
										</tr>
										<tr>
											<td>Todd James</td>
											<td>$32,000</td>
											<td className="Pending">Withdraw...</td>
											<td>
												<button className="btn btnColor">Accept</button>
											</td>
										</tr>
										<tr>
											<td>Todd James</td>
											<td>$31,500</td>
											<td className="Pending">Pending...</td>
											<td>
												<button className="btn btnColor">Accept</button>
											</td>
										</tr>
										<tr>
											<td>Todd James</td>
											<td>$31,500</td>
											<td className="Pending">Pending...</td>
											<td>
												<button className="btn btnColor">Accept</button>
											</td>
										</tr>
										<tr>
											<td>Todd James</td>
											<td>$32,000</td>
											<td className="Pending">Withdraw...</td>
											<td>
												<button className="btn btnColor">Accept</button>
											</td>
										</tr>
										<tr>
											<td>Todd James</td>
											<td>$31,500</td>
											<td className="Pending">Pending...</td>
											<td>
												<button className="btn btnColor">Accept</button>
											</td>
										</tr>
										<tr>
											<td>Todd James</td>
											<td>$31,500</td>
											<td className="Pending">Pending...</td>
											<td>
												<button className="btn btnColor">Accept</button>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<a href="#">See more</a>
						</div>
					</div>
				</div>
				<Footer />
			</section>
		</>
	);
}

export default Auction;
