import React from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import "../../Assets/Css/login.css";

const ChangePass = () => {
	return (
		<>
			<Header />

			{/* Login Sec Start here */}
			<section className="login change-pass verification">
				<div className="container">
					<div className="row">
						<div className="col-lg-3"></div>
						<div className="col-lg-6">
							<div className="login_box">
								<div className="heading pb-5">
									<h2>Change Password</h2>
								</div>
								<form action="/login">
									<div className="form-group">
										<input
											className="form-control"
											type="password"
											placeholder="New Password"
										/>
									</div>
									<div className="form-group">
										<input
											className="form-control"
											type="password"
											placeholder="Confirm Password"
										/>
									</div>
									<div className="button-group">
										<button type="submit">Confirm</button>
									</div>
								</form>
							</div>
						</div>
						<div className="col-lg-3"></div>
					</div>
				</div>
			</section>
			{/* Login Sec End here */}

			<Footer />
		</>
	);
};

export default ChangePass;
