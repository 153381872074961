import React, { useState } from "react";
import StripeForm from "./StripForm";
import "../../Assets/Css/Checkout.css";
import { useNavigate } from "react-router-dom";

const Addetails = () => {
	const navigate = useNavigate();
	return (
		<>
			<div className="add-new-address">
				<div className="form-check py-3">
					<input
						type="checkbox"
						className="form-check-input ana"
						id="exampleCheckana"
					/>
					<label
						className="form-check-label label_ana"
						htmlFor="exampleCheckana"
					>
						Add New Address
					</label>
				</div>

				<div>
					<div className="row">
						<div className="col-md-6">
							<div className="form-group">
								<label htmlFor="fname">First Name</label>
								<input
									type="text"
									id="fname"
									className="form-control"
									value="Mark"
									style={{
										backgroundImage:
											'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABHklEQVQ4EaVTO26DQBD1ohQWaS2lg9JybZ+AK7hNwx2oIoVf4UPQ0Lj1FdKktevIpel8AKNUkDcWMxpgSaIEaTVv3sx7uztiTdu2s/98DywOw3Dued4Who/M2aIx5lZV1aEsy0+qiwHELyi+Ytl0PQ69SxAxkWIA4RMRTdNsKE59juMcuZd6xIAFeZ6fGCdJ8kY4y7KAuTRNGd7jyEBXsdOPE3a0QGPsniOnnYMO67LgSQN9T41F2QGrQRRFCwyzoIF2qyBuKKbcOgPXdVeY9rMWgNsjf9ccYesJhk3f5dYT1HX9gR0LLQR30TnjkUEcx2uIuS4RnI+aj6sJR0AM8AaumPaM/rRehyWhXqbFAA9kh3/8/NvHxAYGAsZ/il8IalkCLBfNVAAAAABJRU5ErkJggg==")',
										backgroundRepeat: "no-repeat",
										backgroundAttachment: "scroll",
										backgroundSize: "16px 18px",
										backgroundPosition: "98% 50%",
									}}
								/>
							</div>
						</div>
						<div className="col-md-6">
							<div className="form-group">
								<label htmlFor="lname">Last Name</label>
								<input
									type="text"
									id="lname"
									className="form-control"
									value="Jack"
								/>
							</div>
						</div>
						<div className="col-md-6">
							<div className="form-group">
								<label htmlFor="phone">Phone</label>
								<input
									type="text"
									id="phone"
									className="form-control"
									value="+212121487"
								/>
							</div>
						</div>
						<div className="col-md-6">
							<div className="form-group">
								<label htmlFor="email">Email</label>
								<input
									type="text"
									id="email"
									className="form-control"
									value="testing@mail.com"
								/>
							</div>
						</div>
						<div className="col-md-12">
							<div className="form-group">
								<label htmlFor="address">Address</label>
								<input
									type="text"
									id="address"
									className="form-control"
									value="dsdsds"
								/>
							</div>
						</div>
						<div className="col-md-12">
							<div className="form-group">
								<label htmlFor="apartmnt">Suite, Apartment, etc </label>
								<input
									type="text"
									id="apartmnt"
									className="form-control"
									value="sddsdsd"
								/>
							</div>
						</div>
						<div className="col-md-12">
							<div className="form-group">
								<label htmlFor="city">City</label>
								<input
									type="text"
									id="city"
									className="form-control"
									value="New York"
								/>
							</div>
						</div>
						<div className="col-md-4">
							<div className="form-group">
								<label htmlFor="region">Country/Region</label>
								<input
									type="text"
									id="region"
									className="form-control"
									value="USA"
								/>
							</div>
						</div>
						<div className="col-md-4">
							<div className="form-group">
								<label htmlFor="state">State</label>
								<input
									type="text"
									id="state"
									className="form-control"
									value="Califonia"
								/>
							</div>
						</div>
						<div className="col-md-4">
							<div className="form-group">
								<label htmlFor="zip-code">Zip Code</label>
								<input
									type="text"
									id="zip-code"
									className="form-control"
									value="50054"
								/>
							</div>
						</div>
						<div className="col-md-12">
							<div className="form-check">
								<input
									type="checkbox"
									className="form-check-input"
									id="exampleCheckssd"
								/>
								<label
									className="form-check-label label_ssd"
									htmlFor="exampleCheckssd"
								>
									Save Shipping Details
								</label>
							</div>
						</div>
						<div className="col-md-12">
							<button
								type="submit"
								style={{
									marginTop: "20px",
									width: "100%",
									padding: "12px 80px",
									cursor: "pointer",
									background: "#43f22c",
									color: "#fff",
									fontSize: "18px",
									textTransform: "uppercase",
									fontWeight: "600",
									border: "0",
									transition: "0.6s",
									border: "2px solid #fff",
								}}
								className="btn"
								onClick={() => navigate("/thankyou")}
							>
								Save Address
							</button>
						</div>
					</div>
				</div>
			</div>
			<div className="payment-details">
				<div className="heading">
					<h3>Payment Details</h3>
				</div>
				<div className="saved-payment-method">
					<h5>Use Saved Payments</h5>

					{<StripeForm />}
				</div>
			</div>
		</>
	);
};

export default Addetails;
