import React from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { author, blog_img2 } from "../../constant";

const DealerPage = () => {
  return (
    <>
      <Header />
      <section className="blog-banner blog-banner2">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">{/* <!-- empty --> */}</div>
          </div>
        </div>
      </section>
      <section className="blog">
        <div className="container">
          {/* <!-- blog title and short-description-row --> */}
          <div className="row py-3">
            <div className="col-sm-12">
              <div className="blog-title">
                <h3 className="blog-title-content">Our Research</h3>
                <p className="blog-description">
                  Orci varius natoque penatibus et magnis dis parturient montes,
                  nascetur ridiculus mus. Etiam placerat tortor commodo lectus
                  laoreet venenatis. Donec ultricies, metus vitae bibendum
                  consequat, tortor neque euismod lectus
                </p>
              </div>
            </div>
          </div>
          {/* <!-- blog title and short-description-row --> */}
          {/* <!-- author and social-icon row --> */}
          <div className="row into-row py-3">
            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="author">
                <div className="author-img">
                  <img src={author} className="img-fluid" alt="" />
                </div>
                <div className="author-nam">
                  <h6 className="author-name">Lance Bogrol</h6>
                  <p className="publish-date">Published on Jan 01, 2020</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 pt-xs-4">
              <div className="social-icons">
                <ul className="icons">
                  <li className="icon-list-tag">
                    {" "}
                    <a href="@">Share:</a>
                  </li>
                  <li>
                    <a href="#" className="ico">
                      <i
                        className="fa fa-twitter-square"
                        aria-hidden="true"
                      ></i>
                    </a>
                  </li>
                  <li>
                    <a href="#" className="ico">
                      <i
                        className="fa fa-linkedin-square"
                        aria-hidden="true"
                      ></i>
                    </a>
                  </li>
                  <li>
                    <a href="#" className="ico">
                      <i
                        className="fa fa-google-plus-square"
                        aria-hidden="true"
                      ></i>
                    </a>
                  </li>
                  <li>
                    <a href="#" className="ico">
                      <i className="fa fa-github-square" aria-hidden="true"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* <!-- author and social-icon row --> */}
          <div className="row py-4">
            <div className="col-sm-12">
              <p className="blog-description pb-5">
                Quisque at odio semper, elementum leo sed, congue tellus. Proin
                nunc mauris, porttitor ut eleifend ut, consectetur ut dolor. In
                hac habitasse platea dictumst. Pellentesque ornare nulla ut quam
                blandit scelerisque. Suspendisse non orci id elit tempor rhoncus
                ac id nunc. Integer scelerisque at turpis sit amet faucibus.
                Etiam non euismod urna. Suspendisse vel ex justo. Vivamus
                posuere porttitor ante eu hendrerit.
              </p>
              <figure>
                <img src={blog_img2} className="blog-img img-fluid" alt="" />
              </figure>
              <p className="blog-description py-3">
                In lacinia sapien a libero accumsan facilisis. Donec vitae lorem
                massa. Aliquam tristique vehicula enim ut luctus. Vivamus
                gravida dignissim ligula, dictum laoreet elit malesuada ac.
                Praesent est justo, posuere a nisl porta, pharetra posuere
                lectus. Nulla velit odio, tincidunt vel metus a, viverra
                placerat ligula.
              </p>
              <p className="blog-description py-3">
                Donec id nisl et risus volutpat tempor a eget mauris. Nullam
                velit eros, porttitor et urna sit amet, ullamcorper vestibulum
                magna. Quisque consequat arcu eros, lobortis faucibus purus
                facilisis vitae. Nulla at nunc non purus vehicula elementum.
              </p>
              <a href="#" className="btn my-3">
                Book Now
              </a>
              <div className="quote-wrapper">
                <blockquote>
                  Suspendisse sagittis id lacus eget vulputate. Ut nec arcu ut
                  sem molestie tincidunt luctus <br /> eget tellus.
                </blockquote>
                <p className="quotaion-name text-center">Parsley Montana</p>
              </div>

              <h3 className="blog-heading">Big heading for a new topic</h3>
              <p className="blog-description py-3">
                Morbi pellentesque finibus libero, in blandit lorem eleifend
                eget. Praesent egestas hendrerit augue a vestibulum. Nullam
                fringilla, eros malesuada eleifend placerat, lacus tellus
                egestas erat, nec varius sem lorem ut mauris. Morbi libero
                felis.
              </p>
              <ul className="mb-5">
                <li>
                  Morbi pellentesque finibus libero, in blandit lorem eleifend
                  eget. Praesent egestas hendrerit augue a vestibulum. Nullam
                  fringilla, eros malesuada eleifend placerat, lacus tellus
                  egestas erat, nec varius sem lorem ut mauris. Morbi libero
                  felis.
                </li>
                <li>
                  Cras eget dolor accumsan, blandit risus vitae, faucibus erat.
                  Aliquam scelerisque, diam ut feugiat scelerisque, diam felis
                  venenatis purus, eget cursus enim turpis at sem. Fusce nec
                  tristique dolor, sit amet tristique purus.
                </li>
              </ul>
              {/* <figure>
                <img src={blog_img2} className="blog-img img-fluid" alt="" />
              </figure> */}
              <p className="blog-description py-3">
                Quisque at odio semper, elementum leo sed, congue tellus. Proin
                nunc mauris, porttitor ut eleifend ut, consectetur ut dolor. In
                hac habitasse platea dictumst. Pellentesque ornare nulla ut quam
                blandit scelerisque. Suspendisse non orci id elit tempor rhoncus
                ac id nunc. Integer scelerisque at turpis sit amet faucibus.
                Etiam non euismod urna.
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default DealerPage;
