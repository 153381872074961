import React from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import "../../Assets/Css/login.css";

const Register = () => {
  return (
    <>
      <Header />

      {/* Login Sec Start here */}
      <section className="login register">
        <div className="container">
          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-10">
              <div className="login_box">
                <div className="heading registertxt">
                  <h2>Register For Free</h2>
                </div>
                <form>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="email"
                          placeholder="First Name"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Last Name"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="User Name"
                        />
                      </div>
                    </div>
                    {/* <div className="col-md-6">
											<div className="form-group">
												<input
													className="form-control"
													type="text"
													placeholder="Username"
												/>
											</div>
										</div> */}
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="email"
                          placeholder="Email Address "
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Contact Number"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="password"
                          placeholder="Password"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="password"
                          placeholder="Confirm Password"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <button type="submit">Submit</button>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group checkk">
                        <input
                          className="form-check-input"
                          id="prvcy"
                          type="checkbox"
                        />
                        <label for="prvcy">
                          As a registered user we will email you with relevant
                          information. Untick to opt out.
                        </label>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-1"></div>
          </div>
        </div>
      </section>
      {/* Login Sec End here */}

      <Footer />
    </>
  );
};

export default Register;
