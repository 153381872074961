import React, { useState } from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import "../../Assets/Css/PostVehicleAds.css";
import { FileUploader } from "react-drag-drop-files";

const PostSnap = () => {
	const fileTypes = ["JPG", "PNG", "GIF"];
	const [file, setFile] = useState(null);
	const handleChange = (file) => {
		setFile(file);
	};
	let [radiocheck, setradiocheck] = useState(false);
	let handlecheck = () => {
		setradiocheck(true);
	};
	let handlecheckfalse = () => {
		setradiocheck(false);
	};
	return (
		<>
			<Header />
			{/* PostVehicle starts here */}
			<section
				className="PostVehicle postSnap"
				style={{ backgroundImage: "none" }}
			>
				<div className="container">
					<div className="row">
						<div className="col-lg-12 text-center">
							<div className="content-wrapper">
								<h4>Post Snapshots</h4>
								<p>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor incididunt ut labore <br /> et dolore magna
									aliqua.
								</p>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-2"></div>
						<div className="col-lg-8">
							<form action="">
								<div className="form-group">
									<div className="form-heading-wrapper">
										<h5>Snapshots</h5>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-lg-3">
										<div className="file-wrapper">
											<FileUploader
												handleChange={handleChange}
												name="file"
												types={fileTypes}
												label=""
											/>
											<label htmlFor="">Image</label>
										</div>
									</div>
									<div className="col-lg-3">
										<div className="file-wrapper">
											<FileUploader
												handleChange={handleChange}
												name="file"
												types={fileTypes}
												label=""
											/>
											<label htmlFor="">Image</label>
										</div>
									</div>
									<div className="col-lg-3">
										<div className="file-wrapper">
											<FileUploader
												handleChange={handleChange}
												name="file"
												types={fileTypes}
												label=""
											/>
											<label htmlFor="">Image</label>
										</div>
									</div>
									<div className="col-lg-3">
										<div className="file-wrapper">
											<FileUploader
												handleChange={handleChange}
												name="file"
												types={fileTypes}
												label=""
											/>
											<label htmlFor="">Image</label>
										</div>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-lg-12">
										<div className="form-heading-wrapper">
											<h5>
												Share your best stories and experiences with this EV:
											</h5>
										</div>
									</div>
									<div className="col-lg-12">
										<textarea
											name=""
											className="form-control"
											id=""
											cols="30"
											rows="5"
											placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
										></textarea>
									</div>
								</div>
								<div className="form-group row mt-3">
									<div className="col-lg-6">
										<h6>Charges : $20.00</h6>
										<div class="form-check">
											<input
												class="form-check-input"
												type="checkbox"
												value=""
												id="defaultCheck1"
											/>
											<label class="form-check-label" for="defaultCheck1">
												Agree with the privacy policy.
											</label>
										</div>
									</div>
									<div className="col-lg-6 text-right">
										<button className="btn">Submit</button>
									</div>
								</div>
							</form>
						</div>
						<div className="col-lg-2"></div>
					</div>
				</div>
			</section>
			{/* PostVehicle ends here */}
			<Footer />
		</>
	);
};

export default PostSnap;
