import React from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { tabsImg } from "../../constant";
import add1 from "../../Assets/img/feature5.png";

import "../../Assets/Css/bannerads.css";
import Sidebar from "./Sidebar";
import { Link, useNavigate } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useState } from "react";
import { useCallback } from "react";

const BannerAd = () => {
  const [value, setValue] = useState("Lorem Ipsum");
  const [copied, setCopied] = useState(false);
  const onChange = useCallback(({ target: { value } }) => {
    setValue(value);
    setCopied(true);
  }, []);
  const onClick = useCallback(({ target: { innerText } }) => {
    console.log(`Clicked on "${innerText}"!`);
  }, []);
  const onCopy = useCallback(() => {
    setCopied(true);
  }, []);
  function MyButton(props) {
    return <button {...props} />;
  }
  const navigate = useNavigate();
  return (
    <>
      <Header />

      <section className="profile_Sec bannerads_sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <Sidebar />
            </div>
            <div className="col-lg-9">
              <div className="right-side">
                <div className="title">
                  <h3>Banner Ads</h3>
                  <div className="snap-chat">
                    <span>(02)</span>
                  </div>
                </div>
                <div className="profile-detail"></div>
                <div className="bannerbg">
                  <div className="row">
                    <div className="col align-self-center">
                      <div className="ads_date_dv">
                        <p>Start Date</p>
                        <p>12_Nov_2023</p>
                      </div>
                    </div>
                    <div className="col align-self-center">
                      <div className="ads_date_dv">
                        <p>End Date</p>
                        <p>22_Nov_2023</p>
                      </div>
                    </div>
                    <div className="col align-self-center">
                      <div className="ads_date_dv">
                        <p>Banner Price</p>
                        <p>$40.00</p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div>
                        <img src={add1} className="img-fluid" alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-8">
                      <div className="adsbannername">
                        <h6>Your Brand Link</h6>
                        <div class="form-group">
                          <input
                            type="email"
                            class="form-control"
                            id="exampleInputEmail1"
                            value={value}
                            placeholder="Enter email"
                            onChange={onChange}
                          />
                          <CopyToClipboard onCopy={onCopy} text={value}>
                            <MyButton className="btn copylink">
                              Copy Link
                            </MyButton>
                          </CopyToClipboard>
                        </div>
                        {copied ? (
                          <span style={{ color: "red" }}>Copied.</span>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-4 align-self-center">
                      <div className="redaleart">
                        <span>Expire Date:</span>
                        <span>10Nov2022</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bannerbg mt-2">
                  <div className="row">
                    <div className="col align-self-center">
                      <div className="ads_date_dv">
                        <p>Start Date</p>
                        <p>12_Nov_2023</p>
                      </div>
                    </div>
                    <div className="col align-self-center">
                      <div className="ads_date_dv">
                        <p>End Date</p>
                        <p>22_Nov_2023</p>
                      </div>
                    </div>
                    <div className="col align-self-center">
                      <div className="ads_date_dv">
                        <p>Banner Price</p>
                        <p>$40.00</p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div>
                        <img src={add1} className="img-fluid" alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-8">
                      <div className="adsbannername">
                        <h6>Your Brand Link</h6>
                        <div class="form-group">
                          <input
                            type="email"
                            class="form-control"
                            id="exampleInputEmail1"
                            value={value}
                            placeholder="Enter email"
                            onChange={onChange}
                          />
                          <CopyToClipboard onCopy={onCopy} text={value}>
                            <MyButton className="btn copylink">
                              Copy Link
                            </MyButton>
                          </CopyToClipboard>
                        </div>
                        {/* {copied ? <span style={{ color: 'red' }}>Copied.</span> : null} */}
                      </div>
                    </div>
                    <div className="col-md-4 align-self-center">
                      <div className="redaleart">
                        <span>Expire Date:</span>
                        <span>10Nov2022</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="button-group">
                  <Link className="btn" to="/advertiseyourbanner">
                    Upload New Banner
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <div className="post-add-modal">
                <div
                    class="modal fade"
                    id="postAdd"
                    tabindex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">
                                    <img src={tabsImg} className="img-fluid" alt="" />
                                    <span>Go Feature Sale Faster</span>
                                    <img src={tabsImg} className="img-fluid" alt="" />
                                </h5>
                            </div>
                            <div class="modal-body">
                                <ul>
                                    <li>
                                        <span>
                                            <label class="price_container">
                                                <input
                                                    type="radio"
                                                    hidden
                                                    checked="checked"
                                                    name="Addradio"
                                                />
                                                <span class="checkmark">
                                                    <span className="dot"></span>
                                                    <ruby>Go Feature 5 Days</ruby>
                                                </span>
                                            </label>
                                        </span>
                                        <span className="price">$20.00</span>
                                    </li>
                                    <li>
                                        <span>
                                            <label class="price_container">
                                                <input type="radio" hidden name="Addradio" />
                                                <span class="checkmark">
                                                    <span className="dot"></span>
                                                    <ruby>Go Feature 10 Days</ruby>
                                                </span>
                                            </label>
                                        </span>
                                        <span className="price">$30.00</span>
                                    </li>
                                    <li>
                                        <span>
                                            <label class="price_container">
                                                <input type="radio" hidden name="Addradio" />
                                                <span class="checkmark">
                                                    <span className="dot"></span>
                                                    <ruby>Go Feature 15 Days</ruby>
                                                </span>
                                            </label>
                                        </span>
                                        <span className="price">$40.00</span>
                                    </li>
                                </ul>
                                <div className="button-group">
                                    <button className="btn">Go Featured</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
      <Footer />
    </>
  );
};

export default BannerAd;
