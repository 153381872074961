import React from "react";
import "../../Assets/Css/Companies.css";
import feature1 from "../../Assets/img/feature1.png";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import feature3 from "../../Assets/img/feature3.png";

function Companies() {
	return (
		<section className="featured-sec">
			<Header />
			<div className="container mt-4 mb-4">
				<div className="row">
					<div className="col-md-3">
						<Link to="/">
							<div className="featured-box">
								<div className="f-img">
									<img className="img-fluid" src={feature1}></img>
								</div>

								<div className="all-text-things">
									<div className="featured-text">
										<h5>renew</h5>
										<p>
											Lorem ipsum dolor sit, amet consectetur adipisicing elit.
											Doloremque at incidunt quasi eius modi laudantium ex ea
											architecto. Expedita, voluptate!
											<Link to="/">Learn more</Link>
										</p>
									</div>
								</div>

								<div className="inner-img firstImages-feture">
									<img className="img-fluid" src={feature3}></img>
								</div>
							</div>
						</Link>
					</div>
					<br />
					<div className="col-md-3">
						<Link to="/">
							<div className="featured-box">
								<div className="f-img">
									<img className="img-fluid" src={feature1}></img>
								</div>

								<div className="all-text-things">
									<div className="featured-text">
										<h5>renew</h5>
										<p>
											Lorem ipsum dolor sit, amet consectetur adipisicing elit.
											Doloremque at incidunt quasi eius modi laudantium ex ea
											architecto. Expedita, voluptate!
											<Link to="/">Learn more</Link>
										</p>
									</div>
								</div>

								<div className="inner-img firstImages-feture">
									<img className="img-fluid" src={feature3}></img>
								</div>
							</div>
						</Link>
					</div>
					<br />
					<div className="col-md-3">
						<Link to="/">
							<div className="featured-box">
								<div className="f-img">
									<img className="img-fluid" src={feature1}></img>
								</div>

								<div className="all-text-things">
									<div className="featured-text">
										<h5>renew</h5>
										<p>
											Lorem ipsum dolor sit, amet consectetur adipisicing elit.
											Doloremque at incidunt quasi eius modi laudantium ex ea
											architecto. Expedita, voluptate!
											<Link to="/">Learn more</Link>
										</p>
									</div>
								</div>

								<div className="inner-img firstImages-feture">
									<img className="img-fluid" src={feature3}></img>
								</div>
							</div>
						</Link>
					</div>
					<br />
					<div className="col-md-3">
						<Link to="/">
							<div className="featured-box">
								<div className="f-img">
									<img className="img-fluid" src={feature1}></img>
								</div>

								<div className="all-text-things">
									<div className="featured-text">
										<h5>renew</h5>
										<p>
											Lorem ipsum dolor sit, amet consectetur adipisicing elit.
											Doloremque at incidunt quasi eius modi laudantium ex ea
											architecto. Expedita, voluptate!
											<Link to="/">Learn more</Link>
										</p>
									</div>
								</div>

								<div className="inner-img firstImages-feture">
									<img className="img-fluid" src={feature3}></img>
								</div>
							</div>
						</Link>
					</div>
					<br />
					<div className="col-md-3">
						<Link to="/">
							<div className="featured-box">
								<div className="f-img">
									<img className="img-fluid" src={feature1}></img>
								</div>

								<div className="all-text-things">
									<div className="featured-text">
										<h5>renew</h5>
										<p>
											Lorem ipsum dolor sit, amet consectetur adipisicing elit.
											Doloremque at incidunt quasi eius modi laudantium ex ea
											architecto. Expedita, voluptate!
											<Link to="/">Learn more</Link>
										</p>
									</div>
								</div>

								<div className="inner-img firstImages-feture">
									<img className="img-fluid" src={feature3}></img>
								</div>
							</div>
						</Link>
					</div>
					<br />
					<div className="col-md-3">
						<Link to="/">
							<div className="featured-box">
								<div className="f-img">
									<img className="img-fluid" src={feature1}></img>
								</div>

								<div className="all-text-things">
									<div className="featured-text">
										<h5>renew</h5>
										<p>
											Lorem ipsum dolor sit, amet consectetur adipisicing elit.
											Doloremque at incidunt quasi eius modi laudantium ex ea
											architecto. Expedita, voluptate!
											<Link to="/">Learn more</Link>
										</p>
									</div>
								</div>

								<div className="inner-img firstImages-feture">
									<img className="img-fluid" src={feature3}></img>
								</div>
							</div>
						</Link>
					</div>
					<br />
					<div className="col-md-3">
						<Link to="/">
							<div className="featured-box">
								<div className="f-img">
									<img className="img-fluid" src={feature1}></img>
								</div>

								<div className="all-text-things">
									<div className="featured-text">
										<h5>renew</h5>
										<p>
											Lorem ipsum dolor sit, amet consectetur adipisicing elit.
											Doloremque at incidunt quasi eius modi laudantium ex ea
											architecto. Expedita, voluptate!
											<Link to="/">Learn more</Link>
										</p>
									</div>
								</div>

								<div className="inner-img firstImages-feture">
									<img className="img-fluid" src={feature3}></img>
								</div>
							</div>
						</Link>
					</div>
					<br />
					<div className="col-md-3">
						<Link to="/">
							<div className="featured-box">
								<div className="f-img">
									<img className="img-fluid" src={feature1}></img>
								</div>

								<div className="all-text-things">
									<div className="featured-text">
										<h5>renew</h5>
										<p>
											Lorem ipsum dolor sit, amet consectetur adipisicing elit.
											Doloremque at incidunt quasi eius modi laudantium ex ea
											architecto. Expedita, voluptate!
											<Link to="/">Learn more</Link>
										</p>
									</div>
								</div>

								<div className="inner-img firstImages-feture">
									<img className="img-fluid" src={feature3}></img>
								</div>
							</div>
						</Link>
					</div>
					<br />
				</div>
			</div>
			<Footer />
		</section>
	);
}

export default Companies;
