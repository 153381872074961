import React from "react";
import "../../Assets/Css/Cart.css";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { Link } from "react-router-dom";
import currency from "currency-formatter";
import feature2 from "../../Assets/img/sapairpart2.webp";
const Cart = () => {
	return (
		<>
			{/* Header Sec */}
			<Header />
			{/* Header Sec */}

			{/* Cart Sec */}
			<section className="cart">
				<div className="container">
					<div className="row pb-5">
						<div className="col-lg-12">
							<div className="cart-table-wrapper">
								<div className="table-responsive">
									<table className="table">
										<thead>
											<tr>
												<th scope="col"></th>
												<th scope="col">Product</th>
												<th scope="col">Product Name</th>
												<th scope="col">Unit Price</th>
												<th scope="col">Quantity</th>
												<th scope="col">Sub Total</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td className="close-row">
													<button className="removeBtn">
														<i className="fa fa-close"></i>
													</button>
												</td>
												<td className="pro-img">
													<div className="img-wrapper">
														<img className="img-fluid" src={feature2} alt="" />
													</div>
												</td>
												<td className="pro-name">COUNTRYMAN</td>
												<td className="total-price">
													{
														currency
															.format(12, {
																code: "GBP",
															})
															.split(".00")[0]
													}
												</td>
												<td>
													<div className="number">
														<button className="plus quantity" type="button">
															+
														</button>
														<input type="text" value={0} />
														<button className="minus quantity" type="button">
															-
														</button>
													</div>
												</td>

												<td className="sub-total">
													{
														currency
															.format(
																12,

																{
																	code: "GBP",
																},
															)
															.split(".00")[0]
													}
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-6"></div>
						<div className="col-lg-6">
							<div className="cart-t-tal-wrapper">
								<div className="cart-header">
									<h5 className="text-center cart-total">Cart Total</h5>
								</div>

								<div className="cart-footer-wrapper">
									<ul>
										<li>
											<span className="property">Total Amount :</span>
											<span className="value">
												{
													currency
														.format(12, {
															code: "GBP",
														})
														.split(".00")[0]
												}
											</span>
										</li>
									</ul>
								</div>
							</div>
							<div className="button-group-total d-flex flex-end pt-4 LoginCartBtn">
								<Link to="/checkout" className="btn pro-shop">
									Proceed To checkout
								</Link>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Cart Sec */}

			{/* Footer Sec */}
			<Footer />
			{/* Footer Sec */}
		</>
	);
};

export default Cart;
