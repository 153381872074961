import React, { useState } from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import "../../Assets/Css/login.css";

const EnterEmail = () => {
	return (
		<>
			<Header />

			{/* Login Sec Start here */}
			<section className="login verification">
				<div className="container">
					<div className="row">
						<div className="col-lg-3"></div>
						<div className="col-lg-6">
							<div className="login_box">
								<div className="heading">
									<h2>Enter Email</h2>
									<p>We will sent you an OTP on your email.</p>
								</div>
								<form action="/verification">
									<label className="title" for="">
										Please enter email
									</label>
									<div className="form-group">
										<input
											className="form-control"
											type="email"
											placeholder=""
										/>
									</div>
									<div className="button-group">
										<button type="submit">Next</button>
									</div>
								</form>
							</div>
						</div>
						<div className="col-lg-3"></div>
					</div>
				</div>
			</section>
			{/* Login Sec End here */}

			<Footer />
		</>
	);
};

export default EnterEmail;
