import React from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import Sidebar from "./Sidebar";
import "../../Assets/Css/bannerads.css";
import {
  checkdouble,
  payment1,
  payment2,
  payment3,
  payment4,
} from "../../constant";
import { Link } from "react-router-dom";

const BannerAdPayment = () => {
  return (
    <>
      <Header />
      <section className="profile_Sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <Sidebar />
            </div>
            <div className="col-lg-9">
              <div className="right-side">
                <div className="title">
                  <h3>Banner Ad Payment</h3>
                </div>
                <div className="banneradpayment_sec">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="">
                        <label for="cardinput" className="form-label">
                          Card Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="cardinput"
                          placeholder="*123456789789"
                        />
                      </div>
                    </div>
                    <div className="col-md-3 align-self-end">
                      <input
                        type="text"
                        className="form-control"
                        id="cardinput"
                        placeholder="MM/YY"
                      />
                    </div>
                    <div className="col-md-3 align-self-end">
                      <input
                        type="text"
                        className="form-control"
                        id="cardinput"
                        placeholder="Cvc"
                      />
                    </div>
                    <div className="col-md-12">
                      {/* <div className="card-type">
                        <label className="container">
                          <input type="radio" checked="checked" name="radio" />
                          <span className="checkmark">
                            <figure>
                              <img src="img/pymnt1.png" className="img-fluid" />
                            </figure>
                          </span>
                        </label>
                        <label className="container">
                          <input type="radio" name="radio" />
                          <span className="checkmark">
                            <figure>
                              <img src="img/pymnt2.png" className="img-fluid" />
                            </figure>
                          </span>
                        </label>
                        <label className="container">
                          <input type="radio" name="radio" />
                          <span className="checkmark">
                            <figure>
                              <img src="img/pymnt3.png" className="img-fluid" />
                            </figure>
                          </span>
                        </label>
                        <label className="container">
                          <input type="radio" name="radio" />
                          <span className="checkmark">
                            <figure>
                              <img src="img/pymnt4.png" className="img-fluid" />
                            </figure>
                          </span>
                        </label>
                        <label className="container">
                          <input type="radio" name="radio" />
                          <span className="checkmark">
                            <figure>
                              <img src="img/pymnt5.png" className="img-fluid" />
                            </figure>
                          </span>
                        </label>
                      </div> */}
                      <div className="payment_sec ">
                        <p>Card Name</p>
                        <img src={payment1} className="img-fluid"></img>
                        <img src={payment2} className="img-fluid"></img>
                        <img src={payment3} className="img-fluid"></img>
                        <img src={payment4} className="img-fluid"></img>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="adbannermain">
                        <div className="adbannerdetail bord">
                          <h6>Ads Banner Details</h6>
                          <div className="button-group">
                            <button
                              className="btn"
                              data-bs-toggle="modal"
                              href="#exampleModalToggle"
                              role="button"
                            >
                              Edit Banner
                            </button>
                            <div
                              class="modal fade"
                              id="exampleModalToggle"
                              aria-hidden="true"
                              aria-labelledby="editbannerid"
                              tabindex="-1"
                            >
                              <div class="modal-dialog modal-dialog-centered">
                                <div class="modal-content">
                                  <div class="modal-header">
                                    <h5
                                      class="modal-title"
                                      id="editbannerid"
                                    ></h5>
                                    <button
                                      type="button"
                                      class="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                  <div class="modal-body">
                                    <img
                                      src={checkdouble}
                                      className="img-fluid"
                                    ></img>
                                    <h4>
                                    your Ads Banner Payment Was Successful 
                                    </h4>
                                    <p>
                                    Your Banner Successful to Upload For This Website.
                                    </p>
                                    <Link to="/" className="btn">
                                      Go To Home Page
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="adbannerdetail">
                          <span>Advertise Banner Duration : </span>
                          <span>10 Days </span>
                        </div>
                        <div className="adbannerdetail">
                          <span>Total Ads Price</span>
                          <span>10 Days </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="button-group">
                        <button
                          className="btn"
                          data-bs-toggle="modal"
                          href="#exampleModalToggle"
                          role="button"
                        >
                          Pay Now
                        </button>
                        <div
                          class="modal fade"
                          id="exampleModalToggle"
                          aria-hidden="true"
                          aria-labelledby="exampleModalToggleLabel"
                          tabindex="-1"
                        >
                          <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content">
                              <div class="modal-header">
                                <h5
                                  class="modal-title"
                                  id="exampleModalToggleLabel"
                                ></h5>
                                <button
                                  type="button"
                                  class="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                ></button>
                              </div>
                              <div class="modal-body">
                                <img
                                  src={checkdouble}
                                  className="img-fluid"
                                ></img>
                                <h4>
                                  Your Subscription Payment Was Successful Paid
                                </h4>
                                <p>
                                  your subscription payment has been successful
                                  done
                                </p>
                                <Link to="/" className="btn">
                                  Go To Home Page
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default BannerAdPayment;
