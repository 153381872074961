import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";

import "../../Assets/Css/bannerads.css";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import Sidebar from "./Sidebar";

const AdvertiseYourBanner = () => {
  const [name, setName] = useState("");
  return (
    <>
      <Header />
      <section className="profile_Sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <Sidebar />
            </div>
            <div className="col-lg-9">
              <div className="right-side">
                <div className="title">
                  <h3>Banner Ads</h3>
                </div>
                <div className="advertbanner_sec">
                  <h6>Advertise Your Banner</h6>
                  <div className="perdaycrgssec">
                    <span>Per Day Charges Your Brand Promotion : </span>
                    <spnan>
                      <b>$5.00</b>
                    </spnan>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                    <label for="exampleInputEmail1" class="form-label">
                          Select Slot For Your Banner
                        </label>
                      <select class="form-select mb-3" aria-label="Default select example">
                        <option selected>Slot 1</option>
                        <option value="1">Slot 2</option>
                        <option value="2">Slot 3</option>
                        <option value="3">Slot 4</option>
                        <option value="3">Slot 5</option>
                      </select>
                    </div>
                    <div className="col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Start Date
                        </label>
                        <input
                          type="date"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          End Date
                        </label>
                        <input
                          type="date"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div class="mb-3">
                        <label>Upload Banner Image</label>
                        <div class="fileUpload  btn--browse">
                          <label for="uploadBtn" class="form-label">
                            <input
                              class="form-control"
                              value={name}
                              placeholder="Attach File"
                            />
                            <input
                              id="uploadBtn"
                              type="file"
                              class="form-control"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                            />

                            <i class="fa fa-paperclip" aria-hidden="true"></i>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div class="mb-3">
                        <label for="linkinput" class="form-label">
                          Your Brand Link
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="linkinput"
                          placeholder="Paste Here"
                        />
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="perdaycrgssecs">
                        <div className="bannerdur">
                          <span>Advertise Banner Duration : </span>
                          <spnan>
                            <b>10 Days</b>
                          </spnan>
                        </div>
                        <div className="bannerdur">
                          <span>Total Ads Price </span>
                          <spnan>
                            <b>$ 40.00</b>
                          </spnan>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="button-group">
                        <Link className="btn" to="/banneradpayment">
                          Upload New Banner
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default AdvertiseYourBanner;
