import React from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { Chat1, Chat2, Chat3, Chat4, Chat5 } from "../../constant";
import Sidebar from "./Sidebar";

const Chat = () => {
	return (
		<>
			<Header />

			<section className="profile_Sec">
				<div className="container">
					<div className="row">
						<div className="col-lg-3">
							<Sidebar />
						</div>
						<div className="col-lg-9">
							<div className="right-side">
								<div className="title">
									<h3>Messages</h3>
								</div>
								<div className="profile-detail">
									<div className="row">
										<div className="col-md-4">
											<div className="peoples">
												<div className="header">
													<h3>Peoples</h3>
													<i class="fa fa-search" aria-hidden="true"></i>
												</div>
												<div className="all-chats">
													<div className="chat-box active">
														<figure>
															<img className="img-fluid" src={Chat1} />
														</figure>
														<div className="message">
															<h4 className="name">Abigail Alexandra</h4>
															<p>Lorem ipsum.</p>
														</div>
													</div>
													<div className="chat-box">
														<figure>
															<img className="img-fluid" src={Chat2} />
														</figure>
														<div className="message">
															<h4 className="name">Emily Willies</h4>
															<p>Lorem ipsum.</p>
														</div>
													</div>
													<div className="chat-box">
														<figure>
															<img className="img-fluid" src={Chat3} />
														</figure>
														<div className="message">
															<h4 className="name">Tracey Alan</h4>
															<p>Lorem ipsum.</p>
														</div>
													</div>
													<div className="chat-box">
														<figure>
															<img className="img-fluid" src={Chat4} />
														</figure>
														<div className="message">
															<h4 className="name">Brandon Brian</h4>
															<p>Lorem ipsum.</p>
														</div>
													</div>
													<div className="chat-box">
														<figure>
															<img className="img-fluid" src={Chat5} />
														</figure>
														<div className="message">
															<h4 className="name">Jacob Matt</h4>
															<p>Lorem ipsum.</p>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="col-lg-8">
											<div className="chatting-box">
												<div className="header">
													<figure>
														<img src={Chat1} className="img-fluid" />
													</figure>
													<div className="name">
														<h4>Abigail Alexandra</h4>
														<p>Lorem ipsum.</p>
													</div>
												</div>
												<div className="messages">
													<div className="messenger frst-side">
														<figure>
															<img src={Chat1} className="img-fluid" />
														</figure>
														<div className="meesage">
															<p>
																Lorem ipsum dolor sit amet, consectetur
																adipiscing elit sed do eiusmod tempor incididunt
																ut labore et dolore magna aliqua.
															</p>
														</div>
														<div className="time">
															<p>3:15 am</p>
														</div>
													</div>
													<div className="messenger scnd-side">
														<div className="time">
															<p>3:15 am</p>
														</div>
														<div className="meesage">
															<p>
																Lorem ipsum dolor sit amet, consectetur
																adipiscing elit.
															</p>
														</div>
														<figure>
															<img src={Chat1} className="img-fluid" />
														</figure>
													</div>
													<div className="messenger frst-side">
														<figure>
															<img src={Chat1} className="img-fluid" />
														</figure>
														<div className="meesage">
															<p>
																Lorem ipsum dolor sit amet, consectetur
																adipiscing elit.
															</p>
														</div>
														<div className="time">
															<p>3:15 am</p>
														</div>
													</div>
													<div className="messenger frst-side">
														<figure>
															<img src={Chat1} className="img-fluid" />
														</figure>
														<div className="meesage">
															<p>
																Lorem ipsum dolor sit amet, consectetur
																adipiscing elit.
															</p>
														</div>
														<div className="time">
															<p>3:15 am</p>
														</div>
													</div>
													<div className="messenger scnd-side">
														<div className="time">
															<p>3:15 am</p>
														</div>
														<div className="meesage">
															<p>
																Lorem ipsum dolor sit amet, consectetur
																adipiscing elit.
															</p>
														</div>
														<figure>
															<img src={Chat1} className="img-fluid" />
														</figure>
													</div>
												</div>
												<div className="send-box">
													<input
														type="text"
														placeholder="Type message"
														className="form-control"
													/>
													<button className="btn">Send</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<Footer />
		</>
	);
};

export default Chat;
